import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core'
import { Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { DataModelConnection } from '../_models/DataModelController/DataModelConnexion';
import { ReponseAPI } from '../_models/ReponseAPI';
import { environment } from '../../environments/environment';
import { GlobalService } from './GlobalService';
import { DataModelPerteMDP } from '../_models/DataModelController/DataModelPerteMdp';
import { DataModelPassword } from '../_models/DataModelController/DataModelPassword';
import { DataModelCreateAccount } from '../_models/DataModelController/DataModelCreateAccount';
import { DataModelLinkTOTP } from '../_models/DataModelController/DataModelLinkTOTP';
import { PasswordUpdateModel } from '../_models/DataModelController/PasswordUpdateModel';
@Injectable({
    providedIn: 'root'
})
export class UtilisateurService {
    public constructor(private http: HttpClient, private router: Router, private _GlobalService: GlobalService) { }

    public async Login(data: DataModelConnection): Promise<ReponseAPI> {
        return await this._GlobalService.SendPOSTRequest("Utilisateur/Login", data)
    }

    public async MdpOublie(data: DataModelPerteMDP): Promise<ReponseAPI> {
        return await this._GlobalService.SendPOSTRequest("Utilisateur/PerteDeMotDePasse", data)
    }

    public async UpdatePassword(data: DataModelPassword) {
        return await this._GlobalService.SendPUTRequest("Utilisateur/Password", data)
    }

    public async GetInfosUser() {
        return await this._GlobalService.SendGETRequest("Utilisateur")
    }

    public async GetAllUsers() {
        return await this._GlobalService.SendGETRequest("Utilisateur/Utilisateurs")
    }

    public async GetAllRoles() {

        return await this._GlobalService.SendGETRequest("Utilisateur/Roles")
    }

    public async AjouterUtilisteur(data: DataModelCreateAccount): Promise<ReponseAPI> {
        return await this._GlobalService.SendPOSTRequest("Utilisateur/Register", data)
    }

    public async ModifierUtilisateur(data: DataModelCreateAccount, idUtilisateur: string) {
        return await this._GlobalService.SendPUTRequest("Utilisateur/Utilisateurs/" + idUtilisateur, data)
    }

    public async GetLinkTOTP(data: DataModelLinkTOTP) {
        return await this._GlobalService.SendPOSTRequest("Utilisateur/ObtenirLienTOTP", data)
    }

    public async ModifieMDP(data: PasswordUpdateModel) {
        return await this._GlobalService.SendPUTRequest("Utilisateur/ChangementMDPAdmin", data)
    }
    public async ModifieDoubleAuthentificationAdmin(isactivate: boolean) {
        return await this._GlobalService.SendPUTRequest("Utilisateur/DoubleAuthentification?isActivate=" + isactivate, null)
    }

    public async ValideCode(code: string) {
        return await this._GlobalService.SendPOSTRequest("Utilisateur/ValiderTOTP?code=" + code, null)
    }

    public Deconnexion() {
        this._GlobalService.utilisateur = undefined
        localStorage.clear();
        this.router.navigate(['/Connexion']);
    }
    public DeconnexionAdmin() {
        this._GlobalService.utilisateur = undefined
        localStorage.clear();
        this.router.navigate(['/ConnexionAdmin']);
    }
}