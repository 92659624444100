<app-loader *ngIf="loading"></app-loader>
<div *ngIf="this.messageErreurInformation != ''" class="alert alert-danger">
    {{messageErreurInformation}}
</div>
<div id="card" (click)="OuvreFicheAnimal($event,commande)" *ngFor="let commande of Commandes">
    <div [ngClass]="isRetire(commande) ? ' PremiereColonneRetire' : 'PremiereColonneEnAttente'">
        <img id="LogoAnimal" src="../../../assets/TechnicienChauffeur/chat.png" alt="logoChat"
            *ngIf="commande.animal.raceAnimal.categorieAnimal.nom == 'Chat'">
        <img id="LogoAnimal" src="../../../assets/TechnicienChauffeur/chien.png" alt="logoChien"
            *ngIf="commande.animal.raceAnimal.categorieAnimal.nom == 'Chien'">
        <div id="DivRetire" *ngIf="isRetire(commande)">
            <img src="../../../../assets/TechnicienChauffeur/Check.svg" alt="Check" id="Check">
            <p id="Retire">Retiré</p>
        </div>
        <div id="DivEnAttente" *ngIf="!isRetire(commande)">
            <img src="../../../../assets/TechnicienChauffeur/Attente.svg" alt="Attente" id="Check">
            <p id="Retire">En attente</p>
        </div>
        <div id="DivPoids">
            <img src="../../../assets/TechnicienChauffeur/kilo.png" alt="kilo" id="Kilo">
            <b id="PoidsAnimal">{{commande.animal.poids}}</b>
        </div>
    </div>
    <div id="SecondeColonne">
        <p id="NumAnimal">
            N° {{commande.detailCremation.sacId}}
            <br>
            Date décès : <b>{{commande.animal.dateDeces |date : "dd/MM/yyyy"}}</b>
        </p>
        <ng-container [ngSwitch]="commande.detailCremation.typeCremationFinal.id">
            <p id="TypeCremationCollective" *ngSwitchCase="1">Collective</p>
            <p id="TypeCremationIndividuelle" *ngSwitchCase="2">Individuelle</p>
            <p id="TypeCremationIndividuelleCeremonie" *ngSwitchCase="3">Individuelle ++</p>
        </ng-container>
    </div>
    <div id="TroisiemeColonne" *ngIf="this.commande.asso_StatutCommande_Commande.length >1">
        <img (click)="RetireEtatCommande(Commandes.indexOf(commande))"
            src="../../../../assets/TechnicienChauffeur/Close.svg" alt="SuppressionEtatRetire">
    </div>
</div>
<ng-template #dialogActions>
    <button kendoButton (click)="CloseView()">Fermer</button>
    <button kendoButton themeColor="primary" (click)="SaveView()">Enregistrer</button>
</ng-template>
<ng-container #detailViewContainer></ng-container>
<ng-container #detailConfirmationContainer></ng-container>