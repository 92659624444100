import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommandeService } from '../../../_services/CommandeService';
import { Commande } from '../../../_models/Commande';
import { EnumStatutCommande } from '../../../_models/Filter/FilterCommande';

@Component({
  selector: 'app-technien-accueil',
  templateUrl: './technien-accueil.component.html',
  styleUrls: ['./technien-accueil.component.scss']
})
export class TechnienAccueilComponent implements OnInit {

  Commandes: Commande[] = new Array<Commande>()

  public constructor(private router: Router, private _CommandeService: CommandeService) {

  }
  async ngOnInit(): Promise<void> {
    this.Commandes = (await this._CommandeService.GetCommandeByStatutId(3)).objet

    this.Commandes.forEach(x => {
      x.animal.dateDeces = new Date(x.animal.dateDeces)
      x.nbJourDeces = Math.round((new Date().getTime() - x.animal.dateDeces.getTime()) / (1000 * 3600 * 24));
      x.animal.dateArrivee = new Date(x.asso_StatutCommande_Commande.filter(x => x.statutCommandeId == 3)[0].date)
    })

  }

  public CreationFournee() {
    this.router.navigate(['/Technicien/Fournee'])
  }
  OuvrirQrCodeMulti() {
    this.router.navigateByUrl("QrCodeSimple")
  }
}
