<kendo-appbar position="top">

  <div id="logo">
    <img src="../../assets/Connexion/LogoPhoenix-Petit.png" id="LogoCrypto" />
  </div>

  <kendo-appbar-section>
    <h1 class="title">Administration</h1>
  </kendo-appbar-section>

  <kendo-appbar-spacer width="32px"></kendo-appbar-spacer>

  <kendo-appbar-spacer></kendo-appbar-spacer>
  <div class="dropdown">

    <button class="dropbtn">{{this._GlobalService.utilisateur!.userName}} <span
        class="fa fa-angle-down"></span></button>
    <div class="dropdown-content">
      <a [href]="GoToMonCompte()">Mon compte</a>
      <a (click)="deconnexion()">Déconnexion</a>
    </div>
  </div>

</kendo-appbar>