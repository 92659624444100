import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './Client/login/login.component';
import { AuthGuardAdmin } from './_guards/admin.guard';
import { ComptesAdminComponent } from './Admin/comptes-admin/comptes-admin.component';
import { PasswordComponent } from './Global/password/password.component';
import { LoginAdminComponent } from './Admin/login-admin/login-admin.component';
import { ClientCodeAuthentificationComponent } from './Client/client-code-authentification/client-code-authentification.component';
import { AuthGuard } from './_guards/auth.guard';
import { SocieteAdminComponent } from './Admin/societe-admin/societe-admin.component';
import { CommandesComponent } from './Admin/commandes/commandes.component';
import { ClientsAdminComponent } from './Admin/clients-admin/clients-admin.component';
import { SacsAdminComponent } from './Admin/sacs-admin/sacs-admin.component';
import { AuthGuardTechnicienChauffeur } from './_guards/TechnicienChaffeur.guard';
import { ChoixRolesComponent } from './TechnicienChauffeur/choix-roles/choix-roles.component';
import { TechnienAccueilComponent } from './TechnicienChauffeur/Technicien/technien-accueil/technien-accueil.component';
import { ChauffeurAccueilComponent } from './TechnicienChauffeur/Chauffeur/chauffeur-accueil/chauffeur-accueil.component';
import { TechnicienFourneeComponent } from './TechnicienChauffeur/Technicien/technicien-fournee/technicien-fournee.component';
import { TourneeAdminComponent } from './Admin/tournee-admin/tournee-admin.component';
import { DetailCabinetTourneeComponent } from './TechnicienChauffeur/Chauffeur/detail-cabinet-tournee/detail-cabinet-tournee.component';
import { ParcAdminComponent } from './Admin/parc-admin/parc-admin.component';
import { QrCodeSeulComponent } from './TechnicienChauffeur/_QrCode/qr-code-seul/qr-code-seul.component';
import { QrCodeMultiComponent } from './TechnicienChauffeur/_QrCode/qr-code-multi/qr-code-multi.component';
import { ParcPhoenixAdminComponent } from './Admin/parc-phoenix-admin/parc-phoenix-admin.component';
import { FactureAdminComponent } from './Admin/facture-admin/facture-admin.component';
import { MonCompteAdminComponent } from './Admin/mon-compte-admin/mon-compte-admin.component';
import { AuthGuardVeterinaire } from './_guards/veterinaire.guard';
import { AccueilClientComponent } from './Client/accueil-client/accueil-client.component';
import { EtiquetteAdminComponent } from './Admin/etiquette-admin/etiquette-admin.component';
import { BoutiqueClientComponent } from './Client/boutique-client/boutique-client.component';
import { FicheProduitClientComponent } from './Client/fiche-produit-client/fiche-produit-client.component';
import { PanierClientComponent } from './Client/panier-client/panier-client.component';
import { MonCompteMemorialComponent } from './Client/Memorial/mon-compte-memorial/mon-compte-memorial.component';
import { FicheAnimalProprietaireComponent } from './Client/Memorial/fiche-animal-proprietaire/fiche-animal-proprietaire.component';
import { BoutiqueClientCategorieComponent } from './Client/boutique-client-categorie/boutique-client-categorie.component';

const routes: Routes = [
  { path: '', redirectTo: 'ConnexionAdmin', pathMatch: 'full' },

  //Partie Admin
  { path: 'ConnexionAdmin', component: LoginAdminComponent },
  { path: 'admin/comptes', canActivate: [AuthGuardAdmin], component: ComptesAdminComponent },
  { path: 'admin/societes', canActivate: [AuthGuardAdmin], component: SocieteAdminComponent },
  { path: 'admin/commandes', canActivate: [AuthGuardAdmin], component: CommandesComponent },
  { path: 'admin/clients', canActivate: [AuthGuardAdmin], component: ClientsAdminComponent },
  { path: 'admin/sacs', canActivate: [AuthGuardAdmin], component: SacsAdminComponent },
  { path: 'admin/etiquettes', canActivate: [AuthGuardAdmin], component: EtiquetteAdminComponent },
  { path: 'admin/tournees', canActivate: [AuthGuardAdmin], component: TourneeAdminComponent },
  { path: 'admin/inventaireCabinet', canActivate: [AuthGuardAdmin], component: ParcAdminComponent },
  { path: 'admin/inventairePhoenix', canActivate: [AuthGuardAdmin], component: ParcPhoenixAdminComponent },
  { path: 'admin/facturation', canActivate: [AuthGuardAdmin], component: FactureAdminComponent },
  { path: 'admin/moncompte', canActivate: [AuthGuardAdmin], component: MonCompteAdminComponent },

  //Partie Vétérinaire
  { path: 'commandes', canActivate: [AuthGuardVeterinaire], component: CommandesComponent },
  { path: 'inventaireCabinet', canActivate: [AuthGuardVeterinaire], component: ParcAdminComponent },
  { path: 'moncompte', canActivate: [AuthGuardVeterinaire], component: MonCompteAdminComponent },
  

  //partie chauffeur techincien
  { path: 'QrCodeSimple', canActivate: [AuthGuardTechnicienChauffeur], component: QrCodeSeulComponent },
  { path: 'QrCodeMulti', canActivate: [AuthGuardTechnicienChauffeur], component: QrCodeMultiComponent },

  //Partie technicien 
  { path: 'ChoixRoles', canActivate: [AuthGuardTechnicienChauffeur], component: ChoixRolesComponent },
  { path: 'Technicien/Accueil', canActivate: [AuthGuardTechnicienChauffeur], component: TechnienAccueilComponent },
  { path: 'Technicien/Fournee', canActivate: [AuthGuardTechnicienChauffeur], component: TechnicienFourneeComponent },

  //Partie Chauffeur
  { path: 'Chauffeur/Accueil', canActivate: [AuthGuardTechnicienChauffeur], component: ChauffeurAccueilComponent },
  { path: 'Chauffeur/Tournee/Etablissement/:Id', canActivate: [AuthGuardTechnicienChauffeur], component: DetailCabinetTourneeComponent },

  //Partie utilisaateur
  { path: 'Connexion', component: LoginComponent },
  { path: 'Authentification/:CodeClient/Code/:Code', component: ClientCodeAuthentificationComponent },
  { path: 'Password/:idLien', component: PasswordComponent },
  { path: 'client/accueil', canActivate: [AuthGuard], component: AccueilClientComponent },
  { path: 'client/boutique', canActivate: [AuthGuard], component: BoutiqueClientComponent },
  { path: 'client/boutique/produits', canActivate: [AuthGuard], component: BoutiqueClientCategorieComponent },
  { path: 'client/boutique/produit/:Id', canActivate: [AuthGuard], component: FicheProduitClientComponent },
  { path: 'client/panier', canActivate: [AuthGuard], component: PanierClientComponent},
  
  
  { path: 'client/moncompte', canActivate: [AuthGuard], component: MonCompteMemorialComponent },
  { path: 'client/moncompte/animal/:Id', canActivate: [AuthGuard], component: FicheAnimalProprietaireComponent },


  //Redirection
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }