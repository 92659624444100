export class ReponseAPI{
 
    statusCode!: number;
    message!: string;
    messageException!: any;
    user!: string;
    stackTrace?: any;
    objet: any;
    isPasswordTemp!: boolean;
    isUser!: boolean;

}
