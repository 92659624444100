import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
    providedIn: 'root'
})
export class TokenService {

    constructor(private jwtHelperService: JwtHelperService) { }

    getTokenStorage() {
        return localStorage.getItem("token");
    }

    getDecodedTokenStorage() {
        var token = this.getTokenStorage();
        if (token) {
            return this.jwtHelperService.decodeToken(token.toString());
        }
        else {
            return null;
        }
    }

    setTokenStorage(token: string) {
        localStorage.setItem("token", token);
    }

    clearTokenStorage() {
        localStorage.removeItem("token");
    }

    isValidTokenStorage() {
        var token = this.getTokenStorage();
        if (token) {
            return !this.jwtHelperService.isTokenExpired(token);
        }
        else {
            return false;
        }
    }

    isValidToken(token: string) {
        return !this.jwtHelperService.isTokenExpired(token);
    }

    getEmailInToken() {
        var token = this.getDecodedTokenStorage();
        if (token) {
            return token.Sub;//['sub'];
        }
        return null;
    }

    getUserIdInToken() {
        var token = this.getDecodedTokenStorage();
        if (token) {
            return token.UserId;//['UserId'];
        }
        return null;
    }
    getRoleInToken() {
        var token = this.getDecodedTokenStorage();
        if (token) {
          return token['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
        }
        return null;
    }
}