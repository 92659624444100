import { Role } from "../Role";
import { SiteCabinetVeterinaire } from "../SiteCabinetVeterinaire";

export class DataModelCreateAccount {
    id: string = ""
    nom: string = "";
    prenom: string = "";
    email: string = "";
    telephone: string = "";
    dateNaissance!: Date;
    adresse: string = "";
    ville: string = "";
    codePostal: string = "";
    archiver: boolean = false;
    cabinetId!: number | null;
    role: Role = new Role()
    dateEmbauche!: Date;
    securiteSociale: string = "";
    commentaire: string = "";
    sitesCabinetVeterinaire: SiteCabinetVeterinaire[] = []
}