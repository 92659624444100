<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <form #infoForm="ngForm" name="Form">
                <div *ngIf="this.messageErreurInformation != ''" class="alert alert-danger">
                    {{messageErreurInformation}}
                </div>
                <kendo-tabstrip (tabSelect)="onSelect($event)" #tabstrip>
                    <kendo-tabstrip-tab title="Client" [selected]="true" #tabInformation>
                        <ng-template kendoTabContent>
                            <div class="row" *ngIf=" this.nouveau">
                                <div class="col-md-6">
                                    <kendo-label class="k-checkbox-label" [for]="notification"
                                        text="Client déjà existant"></kendo-label>
                                    <input type="checkbox" (click)="UpdateIsNewClient()" name="isNewClient"
                                        [checked]="!this.commande.isNewClient" #notification kendoCheckBox />
                                </div>
                            </div>
                            <br>
                            <!-- Si c'est un nouveau client -->
                            <div *ngIf="!this.commande.isNewClient && this.nouveau">
                                <h2>Choix du client</h2>
                                <br>
                                <div class="row">
                                    <div class="col-md-6">
                                        <kendo-label class="k-form" text="Code, prénom et nom du client *">
                                            <kendo-dropdownlist required valueField="PrenomNom" textField="PrenomNom"
                                                name="PrenomNom" [data]="Clients" [(ngModel)]="this.commande.client"
                                                name="PrenomNom" [filterable]="true"
                                                (filterChange)="handleFilter($event)">

                                            </kendo-dropdownlist>
                                        </kendo-label>
                                    </div>
                                </div>
                            </div>
                            <!-- Si c'est pas un nouveau client -->
                            <div *ngIf="(this.commande.isNewClient && this.nouveau) || !this.nouveau ">
                                <div *ngIf="!this.nouveau && this._GlobalService.isAdmin()"
                                    class="ContainerArchiveCommande">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <kendo-label class="k-checkbox-label" [for]="archiver"
                                                text="Commande archivée"></kendo-label>
                                            <input type="checkbox" name="archiver" [(ngModel)]="this.commande.archiver"
                                                #archiver kendoCheckBox />
                                        </div>
                                    </div>
                                </div>
                                <br>
                                <h2>Informations du client</h2>
                                <br>
                                <div class="row">
                                    <div class="col-md-6">
                                        <kendo-label class="k-form" text="Nom *">
                                            <kendo-textbox placeholder="Nom" name="nom"
                                                [(ngModel)]="this.commande.client.nom" [clearButton]="false" required>
                                                <ng-template kendoTextBoxSuffixTemplate>
                                                    <kendo-icon name="pencil"></kendo-icon>
                                                </ng-template>
                                            </kendo-textbox>
                                        </kendo-label>
                                    </div>
                                    <div class="col-md-6">
                                        <kendo-label class="k-form" text="Prénom *">
                                            <kendo-textbox placeholder="Prénom" name="prenom"
                                                [(ngModel)]="this.commande.client.prenom" [clearButton]="false"
                                                required>
                                                <ng-template kendoTextBoxSuffixTemplate>
                                                    <kendo-icon name="pencil"></kendo-icon>
                                                </ng-template>
                                            </kendo-textbox>
                                        </kendo-label>
                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-md-6">
                                        <kendo-label class="k-form" text="Email">
                                            <kendo-textbox placeholder="Email" name="email"
                                                [(ngModel)]="this.commande.client.email" [clearButton]="false" required>
                                                <ng-template kendoTextBoxSuffixTemplate>
                                                    <kendo-icon name="pencil"></kendo-icon>
                                                </ng-template>
                                            </kendo-textbox>
                                        </kendo-label>
                                    </div>
                                    <div class="col-md-6">
                                        <kendo-label class="k-form" text="Téléphone *">
                                            <kendo-textbox placeholder="Téléphone" name="telephone"
                                                [(ngModel)]="this.commande.client.telephone" [clearButton]="false"
                                                required>
                                                <ng-template kendoTextBoxSuffixTemplate>
                                                    <kendo-icon name="pencil"></kendo-icon>
                                                </ng-template>
                                            </kendo-textbox>
                                        </kendo-label>
                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-md-4">
                                        <kendo-label class="k-form" text="Adresse *">
                                            <kendo-textbox placeholder="Adresse" name="Adresse"
                                                [(ngModel)]="this.commande.client.adresse" [clearButton]="false"
                                                required>
                                                <ng-template kendoTextBoxSuffixTemplate>
                                                    <kendo-icon name="pencil"></kendo-icon>
                                                </ng-template>
                                            </kendo-textbox>
                                        </kendo-label>
                                    </div>
                                    <div class="col-md-4">
                                        <kendo-label class="k-form" text="Code postal *">
                                            <kendo-textbox placeholder="Code postal" name="codePostal"
                                                [(ngModel)]="this.commande.client.codePostal" [clearButton]="false"
                                                required>
                                                <ng-template kendoTextBoxSuffixTemplate>
                                                    <kendo-icon name="pencil"></kendo-icon>
                                                </ng-template>
                                            </kendo-textbox>
                                        </kendo-label>
                                    </div>
                                    <div class="col-md-4">
                                        <kendo-label class="k-form" text="Ville *">
                                            <kendo-textbox placeholder="Ville" name="Ville"
                                                [(ngModel)]="this.commande.client.ville" [clearButton]="false" required>
                                                <ng-template kendoTextBoxSuffixTemplate>
                                                    <kendo-icon name="pencil"></kendo-icon>
                                                </ng-template>
                                            </kendo-textbox>
                                        </kendo-label>
                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-md-12">
                                        <kendo-label class="k-form" text="Commentaire interne :">
                                            <kendo-textarea placeholder="Commentaire"
                                                [(ngModel)]="this.commande.client.commentaire" [rows]="5"
                                                name="commentaire">
                                            </kendo-textarea>
                                        </kendo-label>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </kendo-tabstrip-tab>
                    <kendo-tabstrip-tab title="Animal" [disabled]="!IsSuivantValide()" [selected]="false"
                        #tabInformation>
                        <ng-template kendoTabContent>
                            <br>
                            <h2>Informations de l'animal</h2>
                            <br>
                            <br>
                            <div class="BodureBas">

                                <div class="row">
                                    <div class="col-md-4">
                                        <kendo-label class="k-form" text="Nom *">
                                            <kendo-textbox placeholder="Nom" name="nom"
                                                [(ngModel)]="this.commande.animal.nom" [clearButton]="false" required>
                                                <ng-template kendoTextBoxSuffixTemplate>
                                                    <kendo-icon name="pencil"></kendo-icon>
                                                </ng-template>
                                            </kendo-textbox>
                                        </kendo-label>
                                    </div>
                                    <div class="col-md-4">
                                        <kendo-label class="k-form" text="Date de naissance *">
                                            <kendo-datepicker required [(ngModel)]="this.commande.animal.dateNaissance"
                                                name="dateNaissance"></kendo-datepicker>
                                        </kendo-label>
                                    </div>
                                    <div class="col-md-4">
                                        <kendo-label class="k-form" text="Date de décès *">
                                            <kendo-datepicker required [(ngModel)]="this.commande.animal.dateDeces"
                                                name="dateDeces"></kendo-datepicker>
                                        </kendo-label>
                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-md-4">
                                        <kendo-label class="k-form" text="Catégorie *">
                                            <kendo-dropdownlist required valueField="nom" textField="nom"
                                                name="categorieAnimal" [data]="CategoriesAnimal"
                                                [(ngModel)]="this.commande.animal.raceAnimal.categorieAnimal" required
                                                [filterable]="true"
                                                (selectionChange)="handleSelectionCategorieAnimal($event)"
                                                (filterChange)="handleFilterCategorieAnimal($event)">

                                            </kendo-dropdownlist>
                                        </kendo-label>
                                    </div>
                                    <div class="col-md-4">
                                        <kendo-label class="k-form" text="Race *">
                                            <kendo-dropdownlist required valueField="nom" textField="nom"
                                                name="raceAnimal" [data]="RacesAnimal"
                                                [(ngModel)]="this.commande.animal.raceAnimal" required
                                                [filterable]="true" (filterChange)="handleFilterRaceAnimal($event)">
                                            </kendo-dropdownlist>
                                        </kendo-label>
                                    </div>
                                    <div class="col-md-4">
                                        <kendo-label class="k-form" text="Sexe *">
                                            <kendo-dropdownlist required name="sexe" [data]="Sexes"
                                                [(ngModel)]="this.commande.animal.sexe" required>
                                            </kendo-dropdownlist>
                                        </kendo-label>
                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-md-6">
                                        <kendo-label class="k-form" text="Poids (Kg)*"></kendo-label>
                                        <kendo-numerictextbox [format]="'n2'" name="poids" [min]="0" [step]="0.1"
                                            required [(ngModel)]="this.commande.animal.poids">
                                        </kendo-numerictextbox>
                                    </div>

                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-md-12">
                                        <kendo-label class="k-form" text="Commentaire interne :">
                                            <kendo-textarea [(ngModel)]="this.commande.animal.commentaire" [rows]="5"
                                                name="commentaire">
                                            </kendo-textarea>
                                        </kendo-label>
                                    </div>
                                </div>
                                <br>
                                <div class="row" *ngIf="this._GlobalService.isAdmin()">
                                    <div class="col-md-6">
                                        <kendo-label class="k-checkbox-label" [for]="isAnimalApporteDirectement"
                                            text="L'animal à été directement apporté par le client"></kendo-label>
                                        <input type="checkbox" name="isAnimalApporteDirectement"
                                            [(ngModel)]="this.commande.isAnimalApporteDirectement"
                                            #isAnimalApporteDirectement kendoCheckBox />
                                    </div>
                                </div>
                                <br>
                            </div>
                            <br>
                            <div>
                                <br>
                                <h2>Informations du cabinet</h2>
                                <br>
                                <div class="row" *ngIf="this._GlobalService.isAdmin()">
                                    <div class="col-md-6">
                                        <kendo-label class="k-checkbox-label" [for]="isAnimalsuiviCabinet"
                                            text="L'animal est suivi par un cabinet"></kendo-label>
                                        <input type="checkbox" name="isAnimalsuiviCabinet"
                                            [(ngModel)]="this.commande.isAnimalsuiviCabinet" #isAnimalsuiviCabinet
                                            kendoCheckBox />
                                    </div>
                                </div>
                                <br>
                                <div class="row" *ngIf="this.commande.isAnimalsuiviCabinet">
                                    <div class="col-md-6">
                                        <kendo-label class="k-form" text="Etablissement vétérinaires *">
                                            <kendo-dropdownlist name="siteCabinetVeterinaire" textField="CabinetVille"
                                                valueField="CabinetVille" [data]="EtablissementVeterinaires"
                                                [(ngModel)]="this.commande.siteCabinet" required [filterable]="true"
                                                (filterChange)="handleFilterEtablissementveterinaire($event)"
                                                (selectionChange)="UpdateListeSac($event)">
                                            </kendo-dropdownlist>
                                        </kendo-label>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </kendo-tabstrip-tab>
                    <kendo-tabstrip-tab title="Crémation" [disabled]="!IsSuivantValideCremation()" [selected]="false"
                        #tabInformation>
                        <ng-template kendoTabContent>
                            <br>
                            <h2>Informations sur la crémation</h2>
                            <br>
                            <div class="row">
                                <div class="ContainerDropDownTypeCremation">


                                </div>
                                <div class="col-md-3">
                                    <kendo-label class="k-form" [text]="lblTypeCremation">

                                        <kendo-textbox [readonly]="true" required *ngIf="!this.nouveau"
                                            [(ngModel)]="this.commande.detailCremation.typeCremationInitial.nom"
                                            name="typeCremationInitial"></kendo-textbox>

                                        <kendo-dropdownlist *ngIf="this.nouveau" required valueField="nom"
                                            textField="nom" [data]="TypesCremation"
                                            [(ngModel)]="this.commande.detailCremation.typeCremationInitial"
                                            name="typeCremationInitial">
                                        </kendo-dropdownlist>
                                    </kendo-label>
                                </div>
                                <div class="col-md-3" *ngIf="!this.nouveau">
                                    <div>
                                        <kendo-label class="k-form" text="Type de crémation final*">
                                            <kendo-dropdownlist *ngIf="isCommandeModifiable()" required valueField="nom"
                                                textField="nom" [data]="TypesCremation"
                                                [(ngModel)]="this.commande.detailCremation.typeCremationFinal"
                                                name="typeCremationFina">
                                            </kendo-dropdownlist>
                                        </kendo-label>
                                    </div>
                                    <div>
                                        <kendo-textbox *ngIf="!isCommandeModifiable()" [readonly]="true" required
                                            [(ngModel)]="this.commande.detailCremation.typeCremationFinal.nom"
                                            name="typeCremationFinal"></kendo-textbox>
                                    </div>
                                </div>
                                <div *ngIf="!this.nouveau && this.commande.detailCremation.sac.typeSacCremation != null" class="col-md-3">
                                    <kendo-label class="k-form" text="Taille du sac">
                                        <kendo-textbox placeholder="Taille du sac" name="TailleSac"
                                            [(ngModel)]="this.commande.detailCremation.sac.typeSacCremation.nom" [clearButton]="false" required [readonly]="true">
                                        </kendo-textbox>
                                    </kendo-label>
                                </div>
                                <div class="col-md-3">
                                    <kendo-label class="k-form" text="Numéro du sac *">
                                        <kendo-dropdownlist name="id" textField="id" valueField="id" [data]="Sacs"
                                            [(ngModel)]="this.commande.detailCremation.sac" required
                                            [filterable]="true">
                                        </kendo-dropdownlist>
                                    </kendo-label>
                                </div>
                                
                            </div>
                            <br>
                            <br>
                            <div *ngIf="this.commande.asso_StatutCommande_Commande.length > 0 && !this.nouveau">
                                <h2>Suivi de la commande</h2>
                                <kendo-grid [data]="gridData" (dataStateChange)="dataStateChange($event)"
                                    [sortable]="true" [sort]="gridState.sort" [columnMenu]="true"
                                    [pageSize]="gridState.take" [skip]="gridState.skip" [height]="400"
                                    [pageable]="true">
                                    <!-- (edit)="RetirerEtatCommande($event.dataItem)" -->
                                    <kendo-grid-column field="date" title="Date">
                                        <ng-template kendoGridCellTemplate let-dataItem>
                                            {{dataItem.date | date:'dd/MM/yyyy HH:mm'}}
                                        </ng-template>
                                    </kendo-grid-column>
                                    <kendo-grid-column field="statutCommande.nom" title="Statut"></kendo-grid-column>
                                    <!-- <kendo-grid-column title="Action">
                                        <ng-template kendoGridCellTemplate>
                                            <button themeColor="primary" *ngIf="this.isAdministrateur()"
                                                kendoGridEditCommand>Supprimer</button>
                                        </ng-template>
                                    </kendo-grid-column> -->
                                </kendo-grid>
                            </div>
                        </ng-template>
                    </kendo-tabstrip-tab>
                </kendo-tabstrip>
            </form>
        </div>
    </div>
</div>
<app-loader *ngIf="loading"></app-loader>