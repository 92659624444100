import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { TokenService } from "../_services/token.service";
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable({ providedIn: 'root' })
export class AuthGuardTechnicienChauffeur implements CanActivate {
    constructor(private tokenService: TokenService,
        private router: Router,
        private deviceService: DeviceDetectorService
    ) { }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        var isAuthorized = false;
        var decodeToken = this.tokenService.getDecodedTokenStorage();
        //Contrôler que le token existe
        if (decodeToken) {
            //Si la date d'expiration du token n'est pas dépassé, on accorde l'accès
            if (this.tokenService.isValidTokenStorage()) {
                let role: string = decodeToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];

                //const isMobile = this.deviceService.isMobile();

                if (role.toLowerCase() == "technicienchauffeur") {
                    isAuthorized = true;
                }
            }
        }

        if (!isAuthorized) {
            this.router.navigate(['ConnexionAdmin']);
        }
        return isAuthorized;
    }
}