import { RaceAnimal } from "./RaceAimal";
import { Client } from "./Client";
import { TypeCremation } from "./TypeCremation";

export class Animal {
    id: number = 0;
    nom: string = "";
    dateNaissance: Date = new Date();
    dateDeces: Date = new Date();
    poids: number = 0;
    sexe: string = "";
    commentaire: string = "";
    raceAnimalId: number = 0;
    raceAnimal: RaceAnimal = new RaceAnimal();
    dateArrivee: Date = new Date();
    typeCremation: TypeCremation = new TypeCremation();
    description: string = ""
    isOkMemorial :boolean |null= false;
    photo1: string = ""
}