import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { GlobalService } from '../../_services/GlobalService';
import { UtilisateurService } from '../../_services/UtilisateurService';
import { TokenService } from '../../_services/token.service';
import { DataModelPassword } from '../../_models/DataModelController/DataModelPassword';

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss']
})
export class PasswordComponent {

  constructor(private UtilisateurService: UtilisateurService, private actRoute: ActivatedRoute) {
    this.data.id = this.actRoute.snapshot.params['idLien'];
  }
  public data: DataModelPassword = new DataModelPassword();
  MessageErreur: string = ""
  MessageSucces: string = ""


  public async onSubmit() {
    this.MessageErreur = "";
    if (this.data.password != this.data.confirmationPassword || this.data.password == undefined || this.data.password == '') {
      this.MessageErreur = "La confirmation du mot de passe ne correspond pas au mot de passe.";
      this.MessageSucces = "";
    }
    else {
      //enregistre le nouveau mot de passe
      let reponse = await this.UtilisateurService.UpdatePassword(this.data);
      if(reponse.statusCode == 200){
        this.MessageErreur = "";
        this.MessageSucces = reponse.message;
      }else{
        this.MessageErreur = reponse.messageException
      }
    }
  }
  GoToConnexion(){
    window.location.href = "/ConnexionAdmin";
  }
}
