<div class="Fond">
    <div class="container">
        <div id="header">
            Bonjour,
            <b>{{this._GlobalService.utilisateur?.prenom}}
                {{this._GlobalService.utilisateur?.nom}}</b>
        </div>
        <br>
        <div id="TxtBienvenue">
            <p>Bienvenue sur<br> Mon Phoenix</p>
        </div>
        <br>
        <div id="TxtQuiEtesVous"><b>Qui</b> êtes-vous ?</div>
        <br>
        <div class="Container">
            <div class="card" (click)="this.ChoixRole('Chauffeur')">
                <p class="TextJeSuis">Je suis<br> <b>chauffeur</b></p>
                <br>
                <p class="Acceder">Accéder &#10132;</p>
            </div>
            <div class="card"(click)="this.ChoixRole('Technicien')">
                <p class="TextJeSuis">Je suis<br> <b>technicien</b></p>
                <br>
                <p class="Acceder">Accéder &#10132;</p>
            </div>
        </div>

    </div>
    <div id="footer">
        <img id="logoFooter" src="../../../assets/TechnicienChauffeur/logo footer.svg" alt="logo footer">
    </div>

</div>