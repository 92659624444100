import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { GlobalService } from '../../_services/GlobalService'
import { UtilisateurService } from '../../_services/UtilisateurService';
import { TokenService } from '../../_services/token.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public openRefreshToken: boolean = false;
  public compteARebours: number = 0;

  constructor(
    public _GlobalService: GlobalService,
    public _UtilisateurService: UtilisateurService,
    public _TokenService: TokenService) { }

  async ngOnInit(): Promise<void> {
    this._GlobalService.utilisateur = (await this._UtilisateurService.GetInfosUser()).objet;
  }

  public OnGoTo(action: any) {
    switch (action) {
      case "Deconnexion":
        this._UtilisateurService.Deconnexion()
        break;

    }
  }
  //déconnexion de l'utilisateur
  public deconnexion() {
    this._UtilisateurService.Deconnexion();
  }

  //redemande un nouveau token

}
