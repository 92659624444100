import { Component, OnInit } from '@angular/core';
import { Produit } from '../../_models/Produit';
import { ProduitService } from '../../_services/ProduitService';

@Component({
  selector: 'app-boutique-client',
  templateUrl: './boutique-client.component.html',
  styleUrls: ['./boutique-client.component.scss']
})
export class BoutiqueClientComponent implements OnInit {


  Allproduits: Produit[] = []
  produitsChien: Produit[] = []
  produitsChat: Produit[] = []
  produitsNAC: Produit[] = []
  produitsCadresPhotos: Produit[] = []
  produitsCoffret: Produit[] = []
  nomCategories: string[] = []
  nomCategoriesSelect: string[] = []

  constructor(private _produitService: ProduitService) { }

  async ngOnInit(): Promise<void> {
    //Venir charger la liste des produits
    this.Allproduits = (await this._produitService.GetAllProduits()).objet

    //Faire les affectations en fonction des catégories
    this.produitsChien = this.Allproduits.filter(p => p.nomCategorie == "Urnes Chien").slice(0, 3)
    this.produitsChat = this.Allproduits.filter(p => p.nomCategorie == "Urnes Chat").slice(0, 3)
    this.produitsNAC = this.Allproduits.filter(p => p.nomCategorie == "Urnes NAC").slice(0, 3)
    this.produitsCadresPhotos = this.Allproduits.filter(p => p.nomCategorie == "Cadre Photos").slice(0, 3)
    this.produitsCoffret = this.Allproduits.filter(p => p.nomCategorie == "Coffret").slice(0, 3)

    this.nomCategories = this.Allproduits.map(p => p.nomCategorie).filter((value, index, self) => self.indexOf(value) === index).sort()

  }


  GoToProduits(nomCategorie: string) {
    if (nomCategorie == '') {
      location.href = "/client/boutique/produits?categorie=" + nomCategorie
    } else {
      location.href = "/client/boutique/produits"
    }
  }

}
