import { Component, ViewChild } from '@angular/core';
import { FacturationService } from '../../_services/FacturationService';
import { SiteCabinetVeterinaire } from '../../_models/SiteCabinetVeterinaire';
import { DataModelGetFactures } from '../../_models/DataModelController/DataModelFacture';
import { ReponseAPI } from '../../_models/ReponseAPI';
import { Commande } from '../../_models/Commande';
import { GridComponent, GridDataResult } from '@progress/kendo-angular-grid';
import { State, process } from '@progress/kendo-data-query';
import { delay } from 'rxjs';

@Component({
  selector: 'app-facture-admin',
  templateUrl: './facture-admin.component.html',
  styleUrls: ['./facture-admin.component.scss']
})

export class FactureAdminComponent {

  loading: boolean = false;
  //FacturesProfessionnel : Facture
  EtablissementsCommandesProfessionnels: SiteCabinetVeterinaire[] = []
  DataModelFacture: DataModelGetFactures = new DataModelGetFactures()
  messageErreurInformation = ""
  public types: string[] = ["Particulier", "Professionnel"]
  public ChoixFacture: string = "Particulier"
  Commandes: Commande[] = []

  public constructor(private _FacturationService: FacturationService) {

  }

  public async ExporterFacture(): Promise<void> {
    this.loading = true
    let reponse: ReponseAPI = new ReponseAPI()



    switch (this.ChoixFacture) {
      case "Professionnel":
        reponse = await this._FacturationService.GetFacturesProfessionnels(this.DataModelFacture)
        break
      case "Particulier":
        reponse = await this._FacturationService.GetFacturesParticuliers(this.DataModelFacture)
        break
    }

    if (reponse.statusCode == 200) {

      if (this.ChoixFacture == 'Particulier') {
        this.downloadBase64File(reponse.objet, 'FacturesParticuliers.xlsx')
      } else {
        this.downloadBase64File(reponse.objet, 'FacturesProfessionnels.xlsx')
      }
    } else {
      this.messageErreurInformation = reponse.messageException
    }
    this.loading = false
  }

  private downloadBase64File(base64String: string, filename: string) {
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    link.click();
  }
}