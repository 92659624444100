<app-header-client-boutique></app-header-client-boutique>
<br><br>
<div id="Container">
    <div id="ContainerCategoriePensee">
        <div id="ContainerCategorieSelect" *ngIf="this.ProduitService.GetNomCategorie() == 'Urnes Chien'">
            <img src="../../../assets/Utilisateur/Logo-Chien.png" alt="ImageCategorie" id="ImageCategorie">
            <div id="DetailProduitCategorie">
                <p class="TxtUrnes">URNES</p>
                <p class="LogoCategorie">CHIENS</p>
            </div>
        </div>
        <div id="ContainerCategorieSelect" *ngIf="this.ProduitService.GetNomCategorie() == 'Urnes Chat'">
            <img src="../../../assets/Utilisateur/Logo-Chat.png" alt="ImageCategorie" id="ImageCategorie">
            <div id="DetailProduitCategorie">
                <p class="TxtUrnes">URNES</p>
                <p class="LogoCategorie">CHATS</p>
            </div>
        </div>
        <div id="ContainerCategorieSelect" *ngIf="this.ProduitService.GetNomCategorie() == 'Urnes NAC'">
            <img src="../../../assets/Utilisateur/Logo-Lapin.png" alt="ImageCategorie" id="ImageCategorie">
            <div id="DetailProduitCategorie">
                <p class="TxtUrnes">URNES</p>
                <p class="LogoCategorie">NAC</p>
            </div>
        </div>
        <div id="ContainerCategorieSelect" *ngIf="this.ProduitService.GetNomCategorie() == 'Coffret'">
            <img src="../../../assets/Utilisateur/Logo-Coffret.png" alt="ImageCategorie" id="ImageCategorie">
            <div id="DetailProduitCategorie">
                <p class="TxtUrnes">COFFRETS</p>
            </div>
        </div>
        <div id="ContainerCategorieSelect" *ngIf="this.ProduitService.GetNomCategorie() == 'Cadre Photos'">
            <img src="../../../assets/Utilisateur/Logo-Cadre.png" alt="ImageCategorie" id="ImageCategorie">
            <div id="DetailProduitCategorie">
                <p class="TxtUrnes">CADRES</p>
                <p class="LogoCategorie">Photos</p>
            </div>
        </div>

        <div id="ContainerCategorieSelect" *ngIf="this.ProduitService.GetNomCategorie() == 'Crémation'">
            <img width="93px" height="102px" src="../../../assets/Utilisateur/Memorial/PictoCremation.svg" alt="ImageCategorie" id="ImageCategorie">
            <div id="DetailProduitCategorie">
                <p class="TxtUrnes">CHANGEMENT D'OFFRE DE CRÉMATION</p>
                <p class="LogoCategorie">CRÉMATION</p>
            </div>
        </div>
        <p id="TxtPensee">Une pensée pour ...</p>
    </div>
    <div id="ContainerProduits">
        <p id="TxtOffreCremation" *ngIf="this.ProduitService.produits.length ==0"> Vous avez souscrit à l'offre de crémation individuelle avec cérémonie, vous ne pouvez changer d'offre</p>
        <app-fiche-produit-template [Produits]="this.ProduitService.produits"></app-fiche-produit-template>
    </div>
    <button id="BtnRetourAccueil" (click)="GoToAccueil()">RETOURNER À L'ACCUEIL &#10142;</button>
</div>