import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalService } from '../../_services/GlobalService';
import { SocieteService } from '../../_services/SocieteService';
import { ProduitService } from '../../_services/ProduitService';
import { Produit } from '../../_models/Produit';
import { PanierModel } from '../../_models/PanierModel';
import { PanierService } from '../../_services/PanierService';

@Component({
  selector: 'app-fiche-produit-client',
  templateUrl: './fiche-produit-client.component.html',
  styleUrls: ['./fiche-produit-client.component.scss']
})
export class FicheProduitClientComponent implements OnInit {

  IdProduit: number;
  produits: Produit[] = [new Produit()];
  produit: Produit = new Produit();
  TaillesProduit: string[] = ["S", "M", "L"];
  TaillesProduitSelect: string = "S"
  Quantite: number[] = [1, 2, 3,];
  QuantiteSelect: number = 1
  messageErreurInformation: string = ""
  loading: boolean = false

  constructor(public _GlobalService: GlobalService, private actRoute: ActivatedRoute, private _ProduitService: ProduitService, private _PanierService: PanierService) {
    this.IdProduit = this.actRoute.snapshot.params['Id'];
  }

  async ngOnInit(): Promise<void> {
    this.loading = true

    //Aller chercher les produits
    this.produits = (await this._ProduitService.GetAllProduits()).objet;

    //Affecter le produit
    this.produit = this.produits.find(x => x.id == this.IdProduit)!

    //Supprimer le produit de la liste
    this.produits = this.produits.filter(x => x.id != this.IdProduit)

    //Limiter à 4 produits
    this.produits = this.produits.slice(0, 4)

    this.loading = false

  }

  AjouterPanier() {
    const done = document.querySelector(".done") as HTMLElement;

    if (this.QuantiteSelect > 0) {
      //Ajouter l'id produit, la taille et la quantité au panier
      let Panier: PanierModel[] = [];
      Panier.push({ idProduit: this.IdProduit, taille: this.TaillesProduitSelect, quantite: this.QuantiteSelect })

      //Récupérer le panier existant
      let PanierExistant: PanierModel[] = JSON.parse(localStorage.getItem("Panier")!)

      //Si le panier existe
      if (PanierExistant != null && PanierExistant.length > 0) {

        //Si le pdouit existe déjà, ajouter la quantité
        let produitExistant = PanierExistant.find(x => x.idProduit == this.IdProduit && x.taille == this.TaillesProduitSelect)

        if (produitExistant) {
          //ajouter la quantité
          produitExistant.quantite += this.QuantiteSelect

          //Supprimer le produit dans le panier
          PanierExistant = PanierExistant.filter(x => x.idProduit != this.IdProduit)

          //le remettre dans le panier
          PanierExistant.push(produitExistant)

          //Sauvegarder le panier
          localStorage.setItem("Panier", JSON.stringify(PanierExistant));
        } else {
          //Ajouter le produit au panier
          PanierExistant.push({ idProduit: this.IdProduit, taille: this.TaillesProduitSelect, quantite: this.QuantiteSelect })

          //Sauvegarder le panier
          localStorage.setItem("Panier", JSON.stringify(PanierExistant));
        }

      } else {
        //Créer le panier
        localStorage.setItem("Panier", JSON.stringify(Panier));
      }

      this._PanierService.RefreshPanier()

      //Faire la transition sur le boutton
      done.style.transform = "translate(0px)";
    } else {
      this.messageErreurInformation = "Veuillez saisir une quantité"
    }

  }

  GoToAccueil() {
    location.href = "/client/accueil";
  }

  GoToCategorie(nomcategorie: string) {
    location.href = "/client/boutique/produits?categorie=" + nomcategorie;
  }

}