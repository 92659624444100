<kendo-appbar position="top">
    
  <div id="logo">
    <img src="../../assets/Connexion/LogoPhoenix.png" id="LogoCrypto" />
  </div>

  <kendo-appbar-section>
    <h1 class="title">Administration</h1>
  </kendo-appbar-section>

  <kendo-appbar-spacer width="32px"></kendo-appbar-spacer>

  <kendo-appbar-spacer></kendo-appbar-spacer>

  <!--<kendo-appbar-section class="actions">
    <kendo-badge-container>
      <button kendoButton fillMode="flat">
        <kendo-icon name="bell"></kendo-icon>
      </button>
      <kendo-badge
        shape="dot"
        themeColor="warning"
        size="small"
        position="inside"
      ></kendo-badge>
    </kendo-badge-container>
    <span class="k-appbar-separator"></span>
  </kendo-appbar-section>-->


     <div class="dropdown">
     
      <button class="dropbtn">{{this._GlobalService.utilisateur!.userName}} <span class="fa fa-angle-down"></span></button>
      <div class="dropdown-content">
        <a href="/admin/moncompte">Mon compte</a>
        <a (click)="deconnexion()">Déconnexion</a>
      </div>
    </div>

</kendo-appbar>
