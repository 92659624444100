import { Commande } from "./Commande";

export class Client {
    id: number = 0;
    nom: string = "";
    prenom: string = "";
    email: string = "";
    telephone: string = "";
    adresse: string = "";
    codePostal: string = "";
    ville: string = "";
    commentaire: string = "";
    lienMDP: string = "";
    PrenomNom: string = "";
    commandes: Commande[] = []
    role: string = "";
}