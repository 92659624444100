import { Component, Input, OnInit } from '@angular/core';
import { DataModelCreateAccount } from '../../../_models/DataModelController/DataModelCreateAccount';
import { SocieteService } from '../../../_services/SocieteService';
import { SiteCabinetVeterinaire } from '../../../_models/SiteCabinetVeterinaire';
import { GlobalService } from '../../../_services/GlobalService';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { State, process } from '@progress/kendo-data-query';
import { DataModelGetSites } from '../../../_models/DataModelController/DataModelGetSites';

@Component({
  selector: 'app-rattacher-utilisateur-site',
  templateUrl: './rattacher-utilisateur-site.component.html',
  styleUrls: ['./rattacher-utilisateur-site.component.scss']
})
export class RattacherUtilisateurSiteComponent implements OnInit {

  @Input() public utilisateur!: DataModelCreateAccount;
  @Input() public nouveau!: boolean;
  SitesVeterinaires: SiteCabinetVeterinaire[] = []

  MessageSucces: string = ""
  MessageError: string = ""

  //#region Grid
  public gridData!: GridDataResult;
  public gridState: State = {
    skip: 0,
    take: 25,
  };
  public async dataStateChange(state: State) {
    this.gridState = state;
    await this.ChargerTableau();
  }
  ///charge les utilisateurs dans le tableau
  public async ChargerTableau() {
    // Init des données pour la grille
    this.gridData = process(this.SitesVeterinaires, this.gridState);
  }

  //#endregion

  public constructor(private CabinetVeterinaireService: SocieteService, public _GlobalService: GlobalService) { }

  async ngOnInit(): Promise<void> {

    let data: DataModelGetSites = new DataModelGetSites();
    data.raccourci = false;
    data.utilisateurId = "";

    //Aller chercher la liste des sites
    this.SitesVeterinaires = (await this.CabinetVeterinaireService.GetAllSites(data)).objet;

    //Supprimer Phoenix de la liste
    this.SitesVeterinaires = this.SitesVeterinaires.filter(x => x.cabinetVeterinaire.nom != "Phoenix");

    //Supprimer les sites déjà rattachés  
    this.utilisateur.sitesCabinetVeterinaire.forEach(site => {
      this.SitesVeterinaires = this.SitesVeterinaires.filter(x => x.id != site.id);
    });
    
    this.dataStateChange(this.gridState);
  }
  async RattacherUtilisateur({ dataItem }: any) {
    this.MessageSucces = ""
    this.MessageError = ""
    let reponse = await this.CabinetVeterinaireService.RattacherUtilisateurSite(this.utilisateur.id, dataItem.id);
    if (reponse.statusCode == 200) {
      this.MessageSucces = `L'utilisateur ${this.utilisateur.prenom} ${this.utilisateur.nom} a bien été rattaché au site ${dataItem.cabinetVeterinaire.nom}-${dataItem.ville}`;
      this.utilisateur.sitesCabinetVeterinaire.push(dataItem);
      this.SitesVeterinaires.splice(this.SitesVeterinaires.indexOf(dataItem), 1);
      this.dataStateChange(this.gridState);
    } else {
      this.MessageError = reponse.messageException
    }
  }
}
