import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UtilisateurService } from '../../_services/UtilisateurService';
import { GlobalService } from '../../_services/GlobalService';
import { PanelBarItemModel, PanelBarStateChangeEvent } from '@progress/kendo-angular-layout';

@Component({
  selector: 'app-menu-admin',
  templateUrl: './menu-admin.component.html',
  styleUrls: ['./menu-admin.component.scss']
})
export class MenuAdminComponent {

  public menu: any[] = [
    { label: "Comptes", route: "/admin/comptes" },
    { label: "Sociétés", route: "/admin/societes" },
    { label: "Conventions d'incinérations", route: "/admin/commandes" },
    { label: "Clients", route: "/admin/clients" },
    { label: "Sacs", route: "/admin/sacs" },
    { label: "Étiquettes", route: "/admin/etiquettes" },
    { label: "Inventaire Cabinets", route: "/admin/inventaireCabinet" },
    { label: "Inventaire Phoenix", route: "/admin/inventairePhoenix" },
    { label: "Tournées", route: "/admin/tournees" },
    { label: "Facturation", route: "/admin/facturation" },
    { label: "Mon compte", route: "/admin/moncompte" },
  ];

  public menuVeterinaire: any[] = [
    { label: "Commandes", route: "/commandes" },
    { label: "Inventaire Cabinet", route: "/inventaireCabinet" },
    { label: "Mon compte", route: "/moncompte" },
  ];

  public selectedMenu = "";
  public router: Router;

  constructor(
    router: Router,
    public _GlobalService: GlobalService,
    public _UtilisateurService: UtilisateurService
  ) {
    this.router = router
  }
  async ngOnInit() {
    this._GlobalService.utilisateur = (await this._UtilisateurService.GetInfosUser()).objet;

    this.selectedMenu = this.router.url;
  }
  //choix menu
  public stateChange(data: PanelBarStateChangeEvent) {
    const focusedEvent: PanelBarItemModel = data.items.filter(
      (item) => item.focused === true
    )[0];

    let route = this.menu.filter(
      (item) => item.label === focusedEvent.title
    )

    if (!this._GlobalService.isAdmin()) {
      route = this.menuVeterinaire.filter(
        (item) => item.label === focusedEvent.title
      )
    }
    this.router.navigate([route[0].route]);
  }

}
