import { CabinetVeterinaire } from "./CabinetVeterinaire";
import { UtilisateurSiteCabinet } from "./UtilisateurSiteCabinet";
import { SiteCabinetVeterinaire } from "./SiteCabinetVeterinaire";

export class Utilisateur {
    email: string = "";
    id: string = "";
    dateNaissance: Date = new Date();
    dateEmbauche: Date = new Date();
    nom: string = "";
    prenom: string = "";
    userName: string = "";
    phoneNumber: string = "";
    adresse: string = "";
    ville: string = "";
    codePostal: string = "";
    code2A: string = "";
    commentaire: string = "";
    securiteSociale: string = "";
    activer!: boolean | null;
    cabinetId: number = 0;
    cabinet: CabinetVeterinaire = new CabinetVeterinaire();
    idLienMdp: string = "";
    temp!: boolean;
    dateFinVieLienMdp: string = "";
    utilisateurSiteCabinets!: UtilisateurSiteCabinet[];
    role: string = "";
    siteCabinetVeterinaires!: SiteCabinetVeterinaire[];
    twoFactorEnabled: boolean = false;
}