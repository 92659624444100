<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <form #infoForm="ngForm" name="Form">
                <kendo-tabstrip (tabSelect)="onSelect($event)" #tabstrip>
                    <kendo-tabstrip-tab title="Informations personnelles" [selected]="true" #tabInformation>
                        <ng-template kendoTabContent>
                            <h2>Informations personnelles</h2>
                            <br><br>
                            <div *ngIf="!this.verifFormInformation" class="alert alert-danger">
                                {{messageErreurInformation}}
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <kendo-label class="k-form" text="Nom *">
                                        <kendo-textbox placeholder="Nom" name="nom" [(ngModel)]="this.utilisateur.nom"
                                            [clearButton]="true" required>
                                            <ng-template kendoTextBoxSuffixTemplate>
                                                <kendo-icon name="pencil"></kendo-icon>
                                            </ng-template>
                                        </kendo-textbox>
                                    </kendo-label>
                                </div>
                                <div class="col-md-6">
                                    <kendo-label class="k-form" text="Prénom *">
                                        <kendo-textbox placeholder="Prénom" name="prenom"
                                            [(ngModel)]="this.utilisateur.prenom" [clearButton]="true" required>
                                            <ng-template kendoTextBoxSuffixTemplate>
                                                <kendo-icon name="pencil"></kendo-icon>
                                            </ng-template>
                                        </kendo-textbox>
                                    </kendo-label>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-md-6">
                                    <kendo-label class="k-form" text="E-mail *">
                                        <kendo-textbox placeholder="E-mail" name="mail"
                                            [(ngModel)]="this.utilisateur.email" [clearButton]="true" required>
                                            <ng-template kendoTextBoxSuffixTemplate>
                                                <kendo-icon name="pencil"></kendo-icon>
                                            </ng-template>
                                        </kendo-textbox>
                                    </kendo-label>
                                </div>

                                <div class="col-md-6">
                                    <kendo-label class="k-form" text="Téléphone *">
                                        <kendo-textbox placeholder="Téléphone" name="telephone"
                                            [(ngModel)]="this.utilisateur.telephone" [clearButton]="true" required>
                                            <ng-template kendoTextBoxSuffixTemplate>
                                                <kendo-icon name="pencil"></kendo-icon>
                                            </ng-template>
                                        </kendo-textbox>
                                    </kendo-label>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-md-6">
                                    <kendo-label class="k-form" text="Date de naissance *">
                                        <kendo-datepicker required [(ngModel)]="this.utilisateur.dateNaissance"
                                            name="dateNaissance"></kendo-datepicker>
                                    </kendo-label>
                                </div>

                                <div class="col-md-6">
                                    <kendo-label class="k-form" text="Role *">
                                        <kendo-dropdownlist required valueField="name" textField="name" name="Role"
                                            [data]="Roles" [(ngModel)]="this.utilisateur.role" name="Role" required>
                                        </kendo-dropdownlist>
                                    </kendo-label>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-md-6">
                                    <kendo-label class="k-form" text="Date d'embauche *">
                                        <kendo-datepicker [(ngModel)]="this.utilisateur.dateEmbauche"
                                            name="dateEmbauche"></kendo-datepicker>
                                    </kendo-label>
                                </div>

                                <div class="col-md-6">
                                    <kendo-label class="k-form" text="N° sécurité sociale *">
                                        <kendo-textbox placeholder="N°  sécurité sociale" name="SecuSociale"
                                            [(ngModel)]="this.utilisateur.securiteSociale" [clearButton]="true"
                                            required>
                                            <ng-template kendoTextBoxSuffixTemplate>
                                                <kendo-icon name="pencil"></kendo-icon>
                                            </ng-template>
                                        </kendo-textbox>
                                    </kendo-label>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-md-4">
                                    <kendo-label class="k-form" text="Adresse *">
                                        <kendo-textbox required placeholder="Adresse" name="adresse"
                                            [(ngModel)]="this.utilisateur.adresse" [clearButton]="true">
                                            <ng-template kendoTextBoxSuffixTemplate>
                                                <kendo-icon name="pencil"></kendo-icon>
                                            </ng-template>
                                        </kendo-textbox>
                                    </kendo-label>
                                </div>
                                <div class="col-md-4">
                                    <kendo-label class="k-form" text="Code postal *">
                                        <kendo-textbox required placeholder="Code postal" name="codePostal"
                                            [(ngModel)]="this.utilisateur.codePostal" [clearButton]="true">
                                            <ng-template kendoTextBoxSuffixTemplate>
                                                <kendo-icon name="pencil"></kendo-icon>
                                            </ng-template>
                                        </kendo-textbox>
                                    </kendo-label>
                                </div>
                                <div class="col-md-4">
                                    <kendo-label class="k-form" text="Ville *">
                                        <kendo-textbox required placeholder="Ville" name="ville"
                                            [(ngModel)]="this.utilisateur.ville" [clearButton]="true">
                                            <ng-template kendoTextBoxSuffixTemplate>
                                                <kendo-icon name="pencil"></kendo-icon>
                                            </ng-template>
                                        </kendo-textbox>
                                    </kendo-label>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-md-12">
                                    <kendo-label class="k-form" text="Commentaire interne :">
                                        <kendo-textarea placeholder="Commentaire"
                                            [(ngModel)]="this.utilisateur.commentaire" [rows]="5" name="commentaire">
                                        </kendo-textarea>
                                    </kendo-label>
                                </div>
                            </div>



                        </ng-template>

                    </kendo-tabstrip-tab>
                    <kendo-tabstrip-tab title="Sites vétérinires" [selected]="false" [disabled]="!this.isFormValide()"
                        *ngIf="this.utilisateur.role.id == '677464ae-f927-4a8c-8595-ac1b2c015cd9' && !this.nouveau" #tabInformation>
                        <ng-template kendoTabContent>
                            <h2>Sites vétérinaires</h2>
                            <br><br>
                            <p *ngIf="this.MessageSuccesDetacherUtilisateur !=''" class="alert alert-sucess"
                                style="background-color: green; color: white;">
                                {{MessageSuccesDetacherUtilisateur}}</p>
                            <p *ngIf="this.MessageErreurDetacherUtilisateur !=''" class="alert alert-danger"
                                style="background-color: red; color: white;">
                                {{MessageErreurDetacherUtilisateur}}</p>
                            <div style="display: flex; width: 22vw; justify-content: space-between;">
                                <button kendoButton (click)="RattacherSiteVeterinaire()" themeColor="primary"
                                    style=" width: 10vw;">Rattacher à un site</button>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12">
                                    <div class="grid-wrapper">
                                        <kendo-grid [data]="this.gridData"
                                            (dataStateChange)="this.dataStateChange($event)" [sortable]="true"
                                            [sort]="this.gridState.sort" [columnMenu]="true"
                                            [pageSize]="this.gridState.take" [skip]="this.gridState.skip" [height]="500"
                                            [pageable]="true" [filterable]="false"
                                            (remove)="DetacherUtilisateurSite($event.dataItem)">
                                            <kendo-grid-column title="Nom du Cabinet">
                                                <ng-template kendoGridCellTemplate let-dataItem>
                                                    {{dataItem.cabinetVeterinaire.nom}}
                                                </ng-template>
                                            </kendo-grid-column>
                                            <kendo-grid-column field="ville" title="Ville"> </kendo-grid-column>
                                            <kendo-grid-column title="Action">
                                                <ng-template kendoGridCellTemplate>
                                                    <button themeColor="primary"
                                                        kendoGridRemoveCommand>Détacher</button>
                                                </ng-template>
                                            </kendo-grid-column>
                                        </kendo-grid>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </kendo-tabstrip-tab>
                </kendo-tabstrip>
            </form>
        </div>
    </div>
</div>
<app-loader *ngIf="loading"></app-loader>

<ng-template #dialogActions>
    <button kendoButton (click)="closeCabinet()">Fermer</button>
    <button kendoButton themeColor="primary" (click)="enregistreUtilisateur()">Enregistrer</button>
</ng-template>
<ng-container #detailCompteContainer></ng-container>
<div kendoDialogContainer></div>