<app-header-client></app-header-client>
<app-loader *ngIf="loading"></app-loader>
<div id="LogoChien">
    <img src="../../../assets/Utilisateur/ChienCalin.png" alt="Chien" id="ChienCalin">
</div>
<div id="TexteAnimal">
    <p id="TxtPrenomAnimal">{{commande.animal.nom}}</p>
    <br>
    <p id="TxtAnimal">Dans ces moments difficiles, vous souhaitez opter pour la <span> Crémation
            {{commande.detailCremation.typeCremationFinal.nom}}</span> du
        corps de <span>{{commande.animal.nom}}</span>.</p>
    <br>


    <p id="TxtAnimal" *ngIf="commande.detailCremation.typeCremationFinal.id == 1">Ce choix vous permettra de rendre le
        plus bel hommage à {{commande.animal.nom}}.</p>
    <p id="TxtAnimal"
        *ngIf="commande.detailCremation.typeCremationFinal.id == 2 || commande.detailCremation.typeCremationFinal.id == 3">
        Ce choix vous permettra de rendre le
        plus bel hommage à {{commande.animal.nom}}, et de, si vous le
        souhaitez, garder
        ses cendres près
        de vous, ou les disperser dans un lieu significatif pour vous.</p>
</div>
<div id="ChangementOffre">
    <p id="TxtChangementOffre">VOUS POUVEZ CHANGER D'OFFRE ICI</p>
    <div id="ContainerOffre">
        <div id="Individuelle">
            <div id="ContenuOffre">

                <div id="HeaderContainer">
                    <p id="TxtCremationIndividuelle">LA CRÉMATION <span
                            id="TxtTypeCremationIndividuelle"><br>INDIVIDUELLE</span></p>
                </div>
                <div id="Description">
                    <p id="TxtDescriptionOffre">Pour ceux qui souhaitent une crémation individuelle et exclusive pour
                        leur
                        animal de compagnie, avec restitution des cendres</p>
                </div>
                <div id="FooterContainer">
                    <button kendoButton id="BtnEnSavoirPlus"
                        (click)="GoToSite('https://www.monphoenix.fr/la-cremation-individuelle/')">EN SAVOIR PLUS
                        SUR LE SITE WEB</button>
                    <button kendoButton id="BtnChangement" (click)="AjouterProduit(844)">CHANGER AVEC CETTE
                        OFFRE</button>
                </div>
            </div>
        </div>
        <div id="IndividuelleCeremonie">
            <div id="ContenuOffre">

                <div id="HeaderContainer">
                    <p id="TxtCremationIndividuelle">LA CRÉMATION <span
                            id="TxtTypeCremationIndividuelle"><br>INDIVIDUELLE AVEC CÉRÉMONIE</span></p>
                </div>
                <div id="Description">
                    <p id="TxtDescriptionOffre">Ajoute une touche spéciale à votre adieu.<br>Nous vous proposons des
                        options personnalisées</p>
                </div>
                <div id="FooterContainer">
                    <button kendoButton id="BtnEnSavoirPlus"
                        (click)="GoToSite('https://www.monphoenix.fr/la-cremation-commemorative/')">EN
                        SAVOIR PLUS SUR LE SITE WEB</button>
                    <button kendoButton id="BtnChangement" (click)="AjouterProduit(845)">CHANGER AVEC CETTE
                        OFFRE</button>
                </div>
            </div>
        </div>
        <div id="Colombarium">
            <div id="ContenuOffre">

                <div id="HeaderContainer">
                    <p id="TxtCremationIndividuelle">LE <span id="TxtTypeCremationIndividuelle"><br>COLOMBARIUM</span>
                    </p>
                </div>
                <div id="Description">
                    <p id="TxtDescriptionOffre">S'il vous est trop difficile de garder son urne chez vous, nous vous
                        proposons de la conserver dans notre colombarium.</p>
                </div>
                <div id="FooterContainer">
                    <button kendoButton id="BtnEnSavoirPlus"
                        (click)="GoToSite('https://www.monphoenix.fr/le-columbarium/')">EN SAVOIR PLUS SUR LE SITE
                        WEB</button>
                    <button kendoButton id="BtnChangement" (click)="AjouterProduit(873)">AJOUTER CETTE OFFRE</button>
                </div>
            </div>
        </div>
    </div>
</div>
<br><br>
<div id="ContainerSuivi">
    <p id="TxtSuivi">LE SUIVI DE {{commande.animal.nom}}</p>
    <div id="ContainerSuiviCremation">
        <img src="../../../assets/Utilisateur/SuiviEtapePhoenix.png" alt="Suivi" id="Suivi">
        <div id="ContainerDetailSuiviCremation">
            <div id="ContainerIncactif" #ContainerSuivi1>
                <p id="TxtSuiviTitre">PRISE EN CHARGE PAR LE VÉTÉRINAIRE</p>
                <img src="../../../assets/Utilisateur/SeparateurSuiviCremationIncactif.svg" alt="Suivi" id="Separateur">
                <p>Votre animal est chez le vétérinaire. Il sera pris en charge rapidement.</p>
            </div>
            <br>
            <div id="ContainerIncactif" #ContainerSuivi2>
                <p id="TxtSuiviTitre">PRISE EN CHARGE PAR LE TRANSPORTEUR</p>
                <img src="../../../assets/Utilisateur/SeparateurSuiviCremationIncactif.svg" alt="Suivi" id="Separateur">
                <p>Votre animal est pris en charge par le transporteur.</p>
            </div>
            <br>
            <div id="ContainerIncactif" #ContainerSuivi3>
                <p id="TxtSuiviTitre">CHAMBRE FROIDE</p>
                <img src="../../../assets/Utilisateur/SeparateurSuiviCremationIncactif.svg" alt="Suivi" id="Separateur">
                <p>Votre animal est arrivé dans la chambre froide du cématorium.</p>
            </div>
            <br>
            <div id="ContainerIncactif" #ContainerSuivi4>
                <p id="TxtSuiviTitre">CRÉMATION DE VOTRE ANIMAL</p>
                <img src="../../../assets/Utilisateur/SeparateurSuiviCremationIncactif.svg" alt="Suivi" id="Separateur">
                <p>Votre animal est en cours de crémation. Une fois la crémation terminée, vous receverez un email</p>
            </div>
            <!-- <div id="ContainerIncactif" #ContainerSuivi5>
                <p id="TxtSuiviTitre">INCINÉRATION TERMINÉE</p>
                <img src="../../../assets/Utilisateur/SeparateurSuiviCremationIncactif.svg" alt="Suivi" id="Separateur">
                <p>L'incinération de votre animal estterminée. Vous pouvez vous rapprocher de votre vétérinaire afin de récupérer les cendres</p>
            </div> -->
        </div>
    </div>
</div>
<br><br>
<div id="ContainerBoutique">

    <div id="ContainerTitreBoutique">
        <img src="../../../assets/Utilisateur/LogoBoutiquePhoenix.png" alt="LogoBoutique" id="LogoBoutique">
        <p id="TxtTitreBoutique">LA BOUTIQUE<br><b>DÉCOUVREZ NOS PRODUITS FUNÉRAIRES</b></p>
    </div>
    <br><br>
    <div id="ContenuBoutique">
        <p>Vous pouvez aussi opter pour une autre <span id="TxtBoutique">urne décorative</span>, les choix étant
            visibles dans notre catalogue.
            <br>Vous y trouverez aussi des <b>bijoux cinéraires </b>contenant quelques cendres de
            {{commande.animal.nom}}, pour pouvoir
            toujours les garder contre vous.

            <br><br>D’autres objets de souvenirs peuvent vous être proposés si vous n’y avez pas pensé lors de cette
            terrible épreuve : <br><span id="TxtBoutique">cadre photo</span> contenant ses poils, <span
                id="TxtBoutique">empreinte de sa patte</span>, …
            <br><br><span id="TxtBoutique">Ses cendres vous
                seront
                remises chez votre vétérinaire, ou sur place, au centre de crémation si vous souhaitez assister à
                celle-ci.</span>
            Auquel cas, contactez-nous par téléphone au <span id="TxtBoutiqueRose">02.00.00.00.00</span>. Une cérémonie
            vous permettra alors de revoir {{commande.animal.nom}}
            une dernière fois, et de repartir directement avec ses cendres si vous le souhaitez.
        </p>
    </div>
    <div id="ContenuProduitsBoutique">
        <!-- Aller chercher la liste des produits via l'api -->
        <div id="CarteProduit" *ngFor="let produit of produits">
            <img src="{{produit.imageUrl}}" alt="PhotoProduit" id="PhotoProduit">
            <br>
            <p id="TxtCategorieProduit">{{produit.nomCategorie}}</p>
            <p id="TxtNomProduit">{{produit.nom}}</p>
            <p id="TxtPrixProduit">à partir de <br>{{produit.prix}} €</p>
        </div>
        <br>
    </div>
    <br><br>
    <div class="ContainerCentrer">
        <button kendoButton id="BtnProduitsBoutique" (click)="GoToBoutique()">Découvrez l'ensemble de nos
            produits</button>
    </div>

</div>

<br><br>
<div id="ContainerHommage">
    <div id="ContenuHommage">
        <img id="LogoBougie" src="../../../assets/Utilisateur/LogoBougie.png">
        <br>
        <p id="TxtMemorial">LE MÉMORIAL</p>
        <p id="TxtHommage">Laissez votre message dans votre espace dédié pour rendre hommage à votre animal de compagnie
            bien-aimé dans la dignité et la sincérité. <br><br> Partager des souvenirs, des photos, des anecdotes qui
            célèbrent la vie et la personnalité unique de votre compagnon. <br><br>Que vous ayez perdu un chien fidèle,
            un chat affectueux, un petit animal de compagnie ou tout autre compagnon bien-aimé, notre cimetière virtuel
            est là pour vous accompagner dans votre processus de deuil et vous aider à préserver la mémoire de votre
            animal pour toujours.</p>
        <button kendoButton id="BtnHommage" (click)="GoToSite('https://www.monphoenix.fr/le-memorial/')">Consulter le
            mémorial</button>
    </div>

</div>
<app-footer-client></app-footer-client>