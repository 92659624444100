export class PanierModel {
    idProduit: number = 0
    taille: string = ""
    quantite: number = 0

    constructor(idProduit: number, quantite: number, taille: string,) {
        this.idProduit = idProduit
        this.taille = taille
        this.quantite = quantite
    }
}