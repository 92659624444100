import { Injectable } from "@angular/core"
import { PanierModel } from "../_models/PanierModel"
import { Produit } from "../_models/Produit"
import { ProduitService } from "./ProduitService"

@Injectable({
  providedIn: 'root'
})
export class PanierService {

  constructor(private _ProduitService: ProduitService) { }

  //#region Panier
  prixPanier: number = 0
  Panier: PanierModel[] = []
  Produits: Produit[] = []

  async RefreshPanier() {
    //Aller chercher le panier dans le local storage
    this.Panier = JSON.parse(localStorage.getItem("Panier")!)

    if (this.Panier != null) {
      //Aller chercher les produits du panier
      this.Produits = (await this._ProduitService.GetProduitByIds(this.Panier.map(x => x.idProduit))).objet

      //affecter la taile aux produits
      this.Produits.forEach(x => {
        x.Taille = this.Panier.find(y => y.idProduit == x.id)!.taille
        x.Quantite = this.Panier.find(y => y.idProduit == x.id)!.quantite
      })

      this.prixPanier = this.GetTotalPanier()
    }
  }

  GetTotalPanier() {
    return this.Produits.map(x => x.prix * x.Quantite).reduce((a, b) => a + b, 0)
  }

  getTotalProduit(IdProduit: number) {
    return this.Produits.find(x => x.id == IdProduit)!.prix * this.Panier.find(x => x.idProduit == IdProduit)!.quantite
  }

  changeQuantite(event: any, IdProduit: number) {
    //Si la quantité est à zéro, on supprime le produit du panier
    if (event == 0) {
      this.Panier = this.Panier.filter(x => x.idProduit != IdProduit)
      localStorage.setItem("Panier", JSON.stringify(this.Panier))
    } else {
      this.Panier.find(x => x.idProduit == IdProduit)!.quantite = event
      localStorage.setItem("Panier", JSON.stringify(this.Panier))
    }

    this.RefreshPanier()
  }

  GetTotalQuantite() {
    if (this.Panier == null) {
      return 0
    } else {
      return this.Panier.map(x => x.quantite).reduce((a, b) => a + b, 0)
    }
  }

  calculerTVA() {
    return this.GetTotalPanier() * 0.20
  }

  ClearPanier() {
    this.Panier = []
    localStorage.removeItem("Panier")
  }

  AjouterProduitPanier(produit: Produit) {

    let panierString = localStorage.getItem("Panier")

    if (panierString) {
      //Récupérer le panier actuel
      this.Panier = JSON.parse(localStorage.getItem("Panier")!)
    } else {
      this.Panier = []
    }

    //Si le produit est déjà dans le panier, on incrémente la quantité
    if (this.Panier.find(x => x.idProduit == produit.id)) {
      this.Panier.find(x => x.idProduit == produit.id)!.quantite++
    } else {
      this.Panier.push(new PanierModel(produit.id, 1, produit.Taille))
    }

    localStorage.setItem("Panier", JSON.stringify(this.Panier))

    this.RefreshPanier()
  }

  //#endregion

}