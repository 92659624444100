import { Component, Input } from '@angular/core';
import { Produit } from '../../../_models/Produit';

@Component({
  selector: 'app-fiche-produit-template',
  templateUrl: './fiche-produit-template.component.html',
  styleUrls: ['./fiche-produit-template.component.scss']
})
export class FicheProduitTemplateComponent {

  @Input() Produits: Produit[]= [];

  GoToFicheProduit(produit: Produit) {
    location.href = "/client/boutique/produit/" + produit.id
  }

}
