import { Component, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { TabStripComponent } from '@progress/kendo-angular-layout';
import { State, process } from '@progress/kendo-data-query';
import { SacService } from '../../_services/SacService';
import { GlobalService } from '../../_services/GlobalService';
import { Sac } from '../../_models/Sac';
import { SacViewComponent } from '../_Views/sac-view/sac-view.component';
import { DemandeSac } from '../../_models/DemandeSac';
import { DataModelGetSites } from '../../_models/DataModelController/DataModelGetSites';
import { SiteCabinetVeterinaire } from '../../_models/SiteCabinetVeterinaire';
import { SocieteService } from '../../_services/SocieteService';
import { DataModelGetSacAffecter } from '../../_models/DataModelController/DataModelGetSacAffecter';
import { ReponseSacTypeSac } from '../../_models/DataModelController/ReponseSacTypeSac';

@Component({
  selector: 'app-sacs-admin',
  templateUrl: './sacs-admin.component.html',
  styleUrls: ['./sacs-admin.component.scss']
})
export class SacsAdminComponent implements OnInit {

  ReponseSacTypeSac: ReponseSacTypeSac[] = []
  loading: boolean = false
  messageErreurInformation: string = ""
  data: DataModelGetSacAffecter = new DataModelGetSacAffecter()

  public gridData!: GridDataResult;
  public gridState: State = {
    skip: 0,
    take: 25,
  };


  constructor(private _SacService: SacService, private _CabinetVeterinaireService: SocieteService) {

  }

  async ngOnInit() {
    //La date de début est égal à la date actuelle -1 mois
    let date = new Date()
    date.setDate(date.getDate() - 7);
    this.data.dateDebut = date

  }

  async getSac() {

    //Aller chercher les sacs
    this.loading = true

    let reponse = (await this._SacService.GetSacAffecter(this.data))

    if (reponse.statusCode == 200) {
      this.ReponseSacTypeSac = reponse.objet

      //Renommer les cabinets
      this.ReponseSacTypeSac.forEach(element => { element.site.CabinetVille = element.site.cabinetVeterinaire.nom + " - " + element.site.ville })


      //Mettre à jour la grille
      this.dataStateChange(this.gridState)
    } else {
      this.messageErreurInformation = reponse.messageException
    }

    this.loading = false
  }


  public async dataStateChange(state: State) {
    this.gridState = state;
    await this.ChargerTableau();
  }
  ///charge les utilisateurs dans le tableau
  public async ChargerTableau() {
    // Init des données pour la grille
    this.gridData = process(this.ReponseSacTypeSac, this.gridState);
  }






}
