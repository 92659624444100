<app-header-client></app-header-client>
<app-loader *ngIf="loading"></app-loader>
<div id="Container" *ngIf="!this.isClearPanier ">
    <p id="TxtCommander">COMMANDER</p>
    <p id="TxtPanier">VOTRE PANIER</p>
    <div id="ContainerTableRecap">
        <table>
            <thead>
                <tr>
                    <th [width]="500" scope="col">Articles</th>
                    <th class="Center" scope="col">Prix unitaire</th>
                    <th class="Center" scope="col">Taille</th>
                    <th class="Center" scope="col">Quantité</th>
                    <th class="Center" scope="col">Prix Total</th>
                    <th class="Center" scope="col"></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let article of this._PanierService.Produits">
                    <td>
                        <p id="TxtNomArticle"> <img id="ImageProduit" [src]="article.imageUrl"> {{ article.nom }}</p>
                    </td>
                    <td class="Center">
                        <p id="TxtPrixUnitaire">{{ article.prix }} €</p>
                    </td>
                    <td class="Center">
                        <kendo-dropdownlist
                            *ngIf="!this.article.nom.includes('Crémation') && !this.article.nom.includes('Colombarium')"
                            rounded="full" [data]="TaillesProduit" [(ngModel)]="article.Taille"></kendo-dropdownlist>
                    </td>
                    <td class="Center"> <kendo-dropdownlist
                            *ngIf="!this.article.nom.includes('Crémation') && !this.article.nom.includes('Colombarium')"
                            rounded="full" (selectionChange)="this._PanierService.changeQuantite($event,article.id)"
                            [data]="Quantite" [(ngModel)]="article.Quantite"></kendo-dropdownlist></td>
                    <td class="Center">
                        <p id="TxtPrixTotal">{{ article.prix * article.Quantite }} €</p>
                    </td>
                    <td class="Center"> <img style="cursor: pointer;"
                            (click)="this._PanierService.changeQuantite(0,article.id)"
                            src="../../../assets/Utilisateur/poubelle.png" alt="poubelle"></td>
                </tr>
                <tr>
                    <td colspan="3"></td>
                    <td colspan="3">
                        <div>
                            <input [(ngModel)]="isCGVOK" type="checkbox" id="CGV" name="CGV" value="CGV" required>
                            <label id="CGV" for="CGV">J'accepte les <a id="CGVTexte" (click)="OpenCGV()">conditions
                                    générales de vente</a></label>
                        </div>
                        <button id="BtnContinuerAchat" [disabled]="!isOkPaiement()" (click)="ConfirmerPaiement()">PASSER
                            LA
                            COMMANDE <span>➞</span></button>
                    </td>
                </tr>
            </tbody>
        </table>
        <div id="Recapitulatif">
            <p id="TxtRecapitulatif">Récapitulatif</p>
            <p id="TxtRecapitulatifQuantite">Il y a {{this._PanierService.GetTotalQuantite()}} article(s) dans votre
                panier</p>
            <p id="TxtTotal">Total TTC <span id="TxtTotalPanier">{{this.GetPrixPanier()}} € (dont
                    {{this._PanierService.calculerTVA()}} € TVA)</span></p>
        </div>
    </div>
    <br>

</div>
<div id="Container" *ngIf="this.isClearPanier ">
    <p id="TxtCommander">COMMANDER</p>
    <p id="TxtPanier">MERCI POUR VOTRE COMMANDE</p>

    <p>Mon Phoenix vous remerci pour votre commande, vous recevrez prochainement un mail de confirmation sur votre
        adresse mail</p>

</div>