import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { GlobalService } from '../../../_services/GlobalService';
import { Commande } from '../../../_models/Commande';
import { ClientService } from '../../../_services/ClientService';
import { Client } from '../../../_models/Client';
import { NgForm } from '@angular/forms';
import { CategorieAnimalService } from '../../../_services/CategorieAnimalService';
import { CategorieAnimal } from '../../../_models/CategorieAnimal';
import { RaceAnimal } from '../../../_models/RaceAimal';
import { RaceAnimalService } from '../../../_services/RaceAnimalService';
import { SocieteService } from '../../../_services/SocieteService';
import { SiteCabinetVeterinaire } from '../../../_models/SiteCabinetVeterinaire';
import { CommandeService } from '../../../_services/CommandeService';
import { TabStripComponent } from '@progress/kendo-angular-layout';
import { TypeCremationService } from '../../../_services/TypeCremationService';
import { TypeCremation } from '../../../_models/TypeCremation';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { State, process } from '@progress/kendo-data-query';
import { ReponseAPI } from '../../../_models/ReponseAPI';
import { TokenService } from '../../../_services/token.service';
import { StatutCommandeService } from '../../../_services/StatutCommandeService';
import { DataModelGetSites } from '../../../_models/DataModelController/DataModelGetSites';
import { Sac } from '../../../_models/Sac';
import { SacService } from '../../../_services/SacService';


@Component({
  selector: 'app-commande-view',
  templateUrl: './commande-view.component.html',
  styleUrls: ['./commande-view.component.scss']
})
export class CommandeViewComponent implements OnDestroy, OnInit {


  @ViewChild('tabstrip') tabstrip!: TabStripComponent;
  @Input() public commande!: Commande;
  @Input() public nouveau!: Boolean;
  public selectedTab: number = 0;
  public Clients: Client[] = new Array()
  public CategoriesAnimal: CategorieAnimal[] = new Array()
  public RacesAnimal: RaceAnimal[] = new Array()
  @ViewChild('infoForm') infoFormSubmit!: NgForm;
  public verifFormInformation: boolean = true;
  messageErreurInformation: string = "";
  loading: boolean = false;
  public AllClients: Array<Client> = new Array();
  public AllCategoriesAnimal: CategorieAnimal[] = new Array()
  public AllRacesAnimal: RaceAnimal[] = new Array()
  public Sacs: Sac[] = new Array()
  public EtablissementVeterinaires: SiteCabinetVeterinaire[] = new Array()
  public AllEtablissementVeterinaires: SiteCabinetVeterinaire[] = new Array()
  public Sexes: string[] = ["Mâle", "Femelle"]
  public IndexTabStrip = 0;
  TypesCremation: TypeCremation[] = new Array()

  public gridData!: GridDataResult;
  public gridState: State = {
    skip: 0,
    take: 25,
  };

  lblTypeCremation: string = "Type de crémation initial *";

  public constructor(public _GlobalService: GlobalService,
    private _ClientService: ClientService,
    private _CategorieAnimalService: CategorieAnimalService,
    private _RaceAnimalService: RaceAnimalService,
    private _CabinetVeterinaireService: SocieteService,
    private _CommandeService: CommandeService,
    private _TypeCremationService: TypeCremationService,
    private _TokenService: TokenService,
    private _SacService: SacService,

  ) { }

  async ngOnInit(): Promise<void> {
    this.loading = true;

    let data: DataModelGetSites = new DataModelGetSites();
    data.raccourci = false;
    data.utilisateurId = "";

    if (this._GlobalService.isAdmin()) {
      this.EtablissementVeterinaires = (await this._CabinetVeterinaireService.GetAllSites(data)).objet
    } else {
      data.utilisateurId = this._GlobalService.utilisateur?.id!
      this.EtablissementVeterinaires = (await this._CabinetVeterinaireService.GetAllSites(data)).objet
    }

    this.CategoriesAnimal = (await this._CategorieAnimalService.getAllCategories()).objet
    this.RacesAnimal = (await this._RaceAnimalService.getAllRaces()).objet
    this.Clients = (await this._ClientService.GetAllClients()).objet
    this.TypesCremation = (await this._TypeCremationService.getAllTypes()).objet

    this.Clients = this.Clients.map(client => ({
      ...client,
      PrenomNom: `${client.prenom}-${client.nom}`
    }));


    this.EtablissementVeterinaires = this.EtablissementVeterinaires.map(etablissement => ({
      ...etablissement,
      CabinetVille: `${etablissement.cabinetVeterinaire.nom} ${etablissement.ville}`
    }));

    this.commande.asso_StatutCommande_Commande = this.commande.asso_StatutCommande_Commande.map(statutCommande => ({
      ...statutCommande,
      date: new Date(statutCommande.date)
    })).sort((a, b) => { return a.date > b.date ? 1 : -1 });

    this.EtablissementVeterinaires = this.EtablissementVeterinaires.filter(x => x.cabinetVeterinaire.nom != 'Phoenix')

    this.AllClients = this.Clients
    this.AllCategoriesAnimal = this.CategoriesAnimal
    this.AllRacesAnimal = this.RacesAnimal
    this.AllEtablissementVeterinaires = this.EtablissementVeterinaires
    this.loading = false;

    if (!this.nouveau) {
      this.Sacs = (await this._SacService.GetAllSacsByEtablissementId(this.commande.siteCabinet.id)).objet
      this.commande.siteCabinet.CabinetVille = `${this.commande.siteCabinet.cabinetVeterinaire.nom} ${this.commande.siteCabinet.ville}`
      this.commande.animal.dateDeces = new Date(this.commande.animal.dateDeces)
      this.commande.animal.dateNaissance = new Date(this.commande.animal.dateNaissance)
    } else {
      this.Sacs = (await this._SacService.GetAllSacsByEtablissementId(this.AllEtablissementVeterinaires[0].id)).objet
    }

    if (!this._GlobalService.isAdmin()) {
      this.lblTypeCremation = "Type de crémation *"
    }

    this.commande.siteCabinet = this.AllEtablissementVeterinaires[0]

    this.ChargerTableau()
  }

  async UpdateListeSac(event: SiteCabinetVeterinaire) {

    this.loading = true

    //Aller chercher la liste des sacs de cet établissement
    this.Sacs = (await this._SacService.GetAllSacsByEtablissementId(event.id)).objet

    this.loading = false
  }

  ngOnDestroy(): void {
    this._GlobalService.IndexTabCommande = 0
  }

  isCommandeModifiable() {
    return this.commande.asso_StatutCommande_Commande.length == 3
  }


  public async dataStateChange(state: State) {
    this.gridState = state;
    await this.ChargerTableau();
  }
  public async ChargerTableau() {
    // Init des données pour la grille
    this.gridData = process(this.commande.asso_StatutCommande_Commande, this.gridState);
  }

  SuivantCommande() {
    // Naviguer vers l'onglet "Animal"
    if (this.tabstrip) {
      this.IndexTabStrip += 1;
      this.tabstrip.selectTab(this.IndexTabStrip);
    }
  }

  onSelect($event: any) {
    this.tabstrip.selectTab($event.index);
    this.IndexTabStrip = $event.index;
  }

  public isFormValide() {
    this.infoFormSubmit.ngSubmit.emit();

    if (!this.infoFormSubmit.invalid) {
      return true;
    } else {
      return false;
    }
  }

  IsSuivantValide(): boolean {
    let retour = false;

    if (this.commande.isNewClient) {
      //Si c'est un nouveau client, vérifier tous les champs du client
      if (this.commande.client.nom == "" ||
        this.commande.client.prenom == "" ||
        this.commande.client.telephone == "" ||
        this.commande.client.adresse == "" ||
        this.commande.client.ville == "" ||
        this.commande.client.codePostal == "") {
        return false
      } else {
        return true
      }

    } else {
      //Si ce n'est pas un nouveau client, vérifier qu'un client est bien sélectionné
      if (this.commande.client.id == 0) {
        retour = false;
      } else {
        return true;
      }
    }

    return retour;
  }
  IsSuivantValideCremation(): boolean {
    let retour = false;

    if (this.commande.animal.nom == "" ||
      this.commande.animal.raceAnimal.id == 0 ||
      this.commande.animal.poids == 0 ||
      this.commande.animal.sexe == "" ||
      (this.commande.isAnimalsuiviCabinet && this.commande.siteCabinet.id == 0)) {
      retour = false;
    }
    else {
      retour = true;
    }
    return retour;
  }

  public validate(): boolean {
    //Faire la logique de validation du type de crémation
    let retour = false;

    if (this.commande.detailCremation.typeCremationInitial.id == 0 || this.commande.detailCremation.sac.id == 0) {
      retour = false;
    }
    else {
      retour = true;
    }
    return retour;
  }

  async EnregistrerCommande() {
    this.loading = true
    let retour = true;
    let reponse: ReponseAPI = new ReponseAPI()

    this.commande.siteCabinet.utilisateurSiteCabinets = null
    this.commande.siteCabinet.commandes = []
    this.commande.siteCabinetId = this.commande.siteCabinet.id
    this.commande.client.role = ""

    if (this.nouveau) {
      this.commande.detailCremation.sac.demandeSac.siteCabinetVeterinaire = new SiteCabinetVeterinaire()
    }



    if (this.nouveau) {
      reponse = await this._CommandeService.AddCommande(this.commande);
    }
    else {
      reponse = await this._CommandeService.UpdateCommande(this.commande);
    }

    if (reponse.statusCode == 200) {
      this.verifFormInformation = true;
      retour = true;
    } else {
      this.verifFormInformation = false;
      this.messageErreurInformation = reponse.messageException;
      retour = false;
    }
    this.loading = false
    return retour
  }

  //#region  Selection
  handleSelectionCategorieAnimal(value: CategorieAnimal) {
    this.RacesAnimal = this.AllRacesAnimal.filter(
      race =>
        race.categorieAnimalId == value.id
    );
    this.commande.animal.raceAnimal = this.RacesAnimal[0]
  }

  //#endregion

  //#region Filter
  handleFilter(value: any) {
    this.Clients = this.AllClients.filter(
      (client) =>
        (client.PrenomNom)
          .toLowerCase()
          .indexOf(value.toLowerCase()) !== -1
    );
  }
  handleFilterCategorieAnimal(value: any) {
    this.CategoriesAnimal = this.AllCategoriesAnimal.filter(
      categorie =>
        categorie.nom
          .toLowerCase()
          .indexOf(value.toLowerCase()) !== -1
    );
  }
  handleFilterRaceAnimal(value: any) {
    this.RacesAnimal = this.AllRacesAnimal.filter(
      race =>
        race.nom
          .toLowerCase()
          .indexOf(value.toLowerCase()) !== -1
    );
  }
  handleFilterEtablissementveterinaire(value: any) {
    this.EtablissementVeterinaires = this.AllEtablissementVeterinaires.filter(
      etablissement =>
        etablissement.CabinetVille
          .toLowerCase()
          .indexOf(value.toLowerCase()) !== -1
    );
  }
  //#endregion


  UpdateIsNewClient() {
    this.commande.client = new Client();
    this.commande.isNewClient = !this.commande.isNewClient
  }

  isAdministrateur() {
    return this._TokenService.getRoleInToken().toLowerCase() == 'administrateur'
  }

}