import { Component, OnInit } from '@angular/core';
import { ProduitService } from '../../_services/ProduitService';
import { GlobalService } from '../../_services/GlobalService';
import { Produit } from '../../_models/Produit';
import { PanierService } from '../../_services/PanierService';

@Component({
  selector: 'app-header-client-boutique',
  templateUrl: './header-client-boutique.component.html',
  styleUrls: ['./header-client-boutique.component.scss']
})
export class HeaderClientBoutiqueComponent implements OnInit {
  loading: boolean = false;
  nomCategories: string[] = []
  produits: Produit[] = []

  constructor(private _produitService: ProduitService, private _ProduitService: ProduitService) { }

  async ngOnInit(): Promise<void> {
    this.loading = true

    this.nomCategories = (await this._ProduitService.GetProduitCategories()).objet.sort()

    this.loading = false
  }

  async SetCategorie(nomCategorie: string, isRedirection: boolean) {
    this._ProduitService.SetNomCategorie(nomCategorie)

    //Aller chercher l'url actuelle
    let url = window.location.href
    
    if(!url.includes("/client/boutique/produits")){
      window.location.href = "/client/boutique/produits?categorie=" + nomCategorie
    }

  }
}
