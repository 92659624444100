<div class="ContainerAll">
    <div class="LogoHautChat">
        <img src="../../assets/Connexion/chat.png" alt="logoChat">
    </div>
    <div class="LogoPhoenixMilieu">
        <img src="../../assets/Connexion/LogoPhoenix.png" alt="logoChat" id="LogoPhoenix">
        <img src="../../assets/Connexion/LogoTexte.svg" alt="logoChat" id="LogoPhoenixTexte">
    </div>
    <form id="form" class="login-form">

        <div class="row">
            <p class="blackp txt">Saisissez votre mot de passe afin de le réinitialiser</p>

        </div>
        <div class="row">
            <kendo-label class="lblTxt" text="Mot de passe" for="mdp"></kendo-label>
            <kendo-textbox id="mdp" [(ngModel)]="data.password" name="mdp" type="password" required></kendo-textbox>
        </div>
        <br>
        <div class="row">
            <kendo-label class="lblTxt" text="Confirmation du mot de passe" for="mdpConfirmation"></kendo-label>
            <kendo-textbox [ariaAutoComplete]="false" id="mdpConfirmation" [(ngModel)]="data.confirmationPassword" name="mdpConfirmation" type="password" required></kendo-textbox>
        </div>
        <br>
        <p *ngIf="this.MessageErreur !=''" class="alert alert-danger" style="background-color: red; color: black;">
            {{MessageErreur}}</p>
        <p *ngIf="this.MessageSucces !=''" class="alert alert-sucess" style="background-color: green; color: white;">
            {{MessageSucces}}</p>
        <div class="row">
            <kendo-button type="submit" id="btnConnexion" (click)="onSubmit()">Enregistrer</kendo-button>
        </div>
        <p *ngIf="this.MessageSucces !=''" id="UrlConnexion" (click)="GoToConnexion()">Veuillez cliquer sur ce lien pour vous connecter</p>
        <br>


    </form>


    <div class="LogoBasChien">
        <img src="../../assets/Connexion/chien.png" alt="LogoChien">
    </div>
</div>

<ngx-spinner bdColor="rgba(197, 191, 194, 0.8)" size="default" color="red" type="ball-clip-rotate"></ngx-spinner>