import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../../_services/GlobalService';
import { UtilisateurService } from '../../_services/UtilisateurService';
import { PanierModel } from '../../_models/PanierModel';
import { Produit } from '../../_models/Produit';
import { ProduitService } from '../../_services/ProduitService';
import { PanierService } from '../../_services/PanierService';

@Component({
  selector: 'app-header-client',
  templateUrl: './header-client.component.html',
  styleUrls: ['./header-client.component.scss']
})
export class HeaderClientComponent implements OnInit {


  constructor(public _GlobalService: GlobalService, private _UtilisateurService: UtilisateurService, private _ProduitService: ProduitService, public _PanierService: PanierService) { }

  async ngOnInit(): Promise<void> {
    this.chargerUtilisateur()

    this._PanierService.prixPanier = await this.CalculerPrixPanier()
  }

  public deconnexion() {
    this._UtilisateurService.Deconnexion()
  }

  private async chargerUtilisateur() {
    this._GlobalService.client = (await this._UtilisateurService.GetInfosUser()).objet;
    this._GlobalService.client!.PrenomNom = this._GlobalService.client?.prenom + " " + this._GlobalService.client?.nom
  }

  async CalculerPrixPanier(): Promise<number> {

    //Aller chercher le panier dans le local storage
    let Panier: PanierModel[] = JSON.parse(localStorage.getItem("Panier")!)

    if (Panier == null || Panier.length == 0) {
      return 0
    } else {
      //Aller chercher les produits du panier
      let Produits: Produit[] = (await this._ProduitService.GetProduitByIds(Panier.map(x => x.idProduit))).objet

      //affecter la taile aux produits
      Produits.forEach(x => {
        x.Taille = Panier.find(y => y.idProduit == x.id)!.taille
        x.Quantite = Panier.find(y => y.idProduit == x.id)!.quantite
      })
      return Produits.map(x => x.prix * x.Quantite).reduce((a, b) => a + b, 0)
    }

  }

  GoToMonCompte() {
    location.href = "/client/moncompte"
  }

  GoToPanier() {
    location.href = "/client/panier"
  }
  GoToMemorial() {
    location.href = "/client/memorial"
  }
  GoToAccueil() {
    location.href = "/client/accueil"
  }


}
