import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { GlobalService } from "./GlobalService";
import { ReponseAPI } from "../_models/ReponseAPI";
import { DataModelAddCabinet } from "../_models/DataModelController/DataModelAddCabinet";
import { DataModelUpdateCabinet } from "../_models/DataModelController/DataModelUpdateCabinet";
import { DataModelGetSites } from "../_models/DataModelController/DataModelGetSites";

@Injectable({
    providedIn: 'root'
})

export class SocieteService {

    public constructor(private _GlobalService: GlobalService) { }

    public async getAllCabinets() {
        return await this._GlobalService.SendGETRequest("Societe/Societes")
    }
    public async GetAllSiteByCabinetId(id: number) {
        return await this._GlobalService.SendGETRequest("Societe/" + id.toString() + "/Sites")
    }
    public async AddCabinet(data: DataModelAddCabinet): Promise<ReponseAPI> {
        return await this._GlobalService.SendPOSTRequest("Societe", data)
    }
    public async UpdateCabinet(data: DataModelUpdateCabinet): Promise<ReponseAPI> {
        return await this._GlobalService.SendPUTRequest("Societe", data)
    }
    public async GetAllSites(data: DataModelGetSites): Promise<ReponseAPI> {
        return await this._GlobalService.SendPOSTRequest("Societe/Sites", data)
    }
    public async RattacherUtilisateurSite(UtilisateurId: string, SiteId: number): Promise<ReponseAPI> {
        return await this._GlobalService.SendGETRequest(`Societe/Affecter/${UtilisateurId}/${SiteId}`)
    }
    public async DetacherutilisateurSite(UtilisateurId: string, SiteId: number): Promise<ReponseAPI> {
        return await this._GlobalService.SendGETRequest(`Societe/Detacher/${UtilisateurId}/${SiteId}`)
    }
    public async GetEtablissementById(SiteId: number): Promise<ReponseAPI> {
        return await this._GlobalService.SendGETRequest(`Societe/Etablissement/${SiteId}`)
    }

}