import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { GlobalService } from "./GlobalService";
import { ReponseAPI } from "../_models/ReponseAPI";
import { DataModelAddCabinet } from "../_models/DataModelController/DataModelAddCabinet";
import { DataModelUpdateCabinet } from "../_models/DataModelController/DataModelUpdateCabinet";

@Injectable({
    providedIn: 'root'
})

export class TypeCremationService {

    public constructor(private http: HttpClient, private router: Router, private _GlobalService: GlobalService) { }

    public async getAllTypes() {
        return await this._GlobalService.SendGETRequest("TypeCremation/Types")
    }

}