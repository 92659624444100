import { Component, OnInit } from '@angular/core';
import { DataModelConnection } from '../../_models/DataModelController/DataModelConnexion';
import { UtilisateurService } from '../../_services/UtilisateurService';
import { NgxSpinnerService } from "ngx-spinner";

import { GlobalService } from '../../_services/GlobalService';
import { Utilisateur } from '../../_models/Utilisateur';
import { TokenService } from '../../_services/token.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DataModelPerteMDP } from '../../_models/DataModelController/DataModelPerteMdp';
import { ClientService } from '../../_services/ClientService';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  EmailClient: string = ""
  MessageSucces: string = "";
  MessageErreur: string = "";

  public constructor(private router: ActivatedRoute, private ClientSerice: ClientService, private spinner: NgxSpinnerService, private _GlobalService: GlobalService, private tokenService: TokenService,) { }

  ngOnInit(): void {

    this.router.queryParamMap.subscribe(param => {
      this.EmailClient = param.get('email')!;
    })

    // Get the input field
    document.addEventListener('keydown', function (event) {

      if (event.key === "Enter") {
        // Cancel the default action, if needed
        event.preventDefault();
        // Trigger the button element with a click
        document.getElementById("btnConnexion")!.click();
      }
    })
  }

  public async onSubmit() {

    const RegExEmail: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    this.spinner.show()
    this.MessageErreur = "";

    if (RegExEmail.test(this.EmailClient)) {

      let reponse = await this.ClientSerice.ObtenirLienAuth(this.EmailClient);

      if (reponse.statusCode == 200) {
        this.MessageSucces = reponse.message
      } else {
        this.MessageErreur = reponse.messageException
      }

    } else {
      this.MessageErreur = "Veuillez saisir une adresse mail valide."
    }
    this.spinner.hide()
  }
}