import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { GlobalService } from "./GlobalService";
import { ReponseAPI } from "../_models/ReponseAPI";


@Injectable({
    providedIn: 'root'
})
export class RaceAnimalService {
    public constructor(private http: HttpClient, private router: Router, private _GlobalService: GlobalService) { }
    public async getAllRaces(): Promise<ReponseAPI> {
        return await this._GlobalService.SendGETRequest("RaceAnimal/Races")
    }
}