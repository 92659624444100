import { Component, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { TabStripComponent } from '@progress/kendo-angular-layout';
import { State, process } from '@progress/kendo-data-query';
import { Utilisateur } from '../../_models/Utilisateur';
import { GlobalService } from '../../_services/GlobalService';
import { UtilisateurService } from '../../_services/UtilisateurService';
import { ClientService } from '../../_services/ClientService';
import { Client } from '../../_models/Client';
import { ClientViewComponent } from '../_Views/client-view/client-view.component';

@Component({
  selector: 'app-clients-admin',
  templateUrl: './clients-admin.component.html',
  styleUrls: ['./clients-admin.component.scss']
})
export class ClientsAdminComponent {
  @ViewChild('dialogActions') dialogActions!: TemplateRef<unknown>;
  private dialog !: DialogRef;

  @ViewChild("detailCompteContainer", { read: ViewContainerRef })
  public detailCompteContainer!: ViewContainerRef;
  @ViewChild('tabstrip') ts!: TabStripComponent;
  public gridData!: GridDataResult;
  public gridState: State = {
    skip: 0,
    take: 25,
  };
  public selectedTabClient: number = 0;
  public Clients: Utilisateur[] = []
  loading: boolean = false;

  public constructor(private _ClientService: ClientService,
    public _GlobalService: GlobalService,
    private _dialogService: DialogService) { }

  ngOnInit(): void {
    //charge le tableau des utilisateurs
    this.listeClients();
  }

  public async listeClients() {
    this.loading = true;

    let reponse = await this._ClientService.GetAllClients();

    this.Clients = reponse.objet;
    this.dataStateChange(this.gridState);
    this.loading = false;
  }

  public async dataStateChange(state: State) {
    this.gridState = state;
    await this.ChargerTableau();
  }

  public async ChargerTableau() {
    // Init des données pour la grille
    this.gridData = process(this.Clients, this.gridState);
  }

  public ouvreDetailClient(client: Client, nouveau: boolean) {
    this.dialog = this._dialogService.open({
      title: "Fiche client",
      width: '90%',
      height: '90%',
      //actions: [cancelAction, saveAction],
      actions: this.dialogActions,

      appendTo: this.detailCompteContainer,
      closeTitle: "Fermer",
      content: ClientViewComponent,
    });

    let DetailClient = this.dialog.content.instance;
    DetailClient.client = client;
    DetailClient.nouveau = nouveau;
  }
  public ajoutClient() {
    this.ouvreDetailClient(new Client(), true);
  }

  //ferme la boite de dialogue
  public closeClient(): void {
    if (this.dialog) {
      this.dialog.close();
    }
  }
  modifieCompte({ dataItem }: any) {
    this.ouvreDetailClient(dataItem as Client, false);
  }

  public async enregistreClient() {

    let DetailClient = this.dialog.content.instance as ClientViewComponent;

    if (DetailClient.validate() && await DetailClient.EnregistrerClient()) {

      this.loading = true;

      await this.listeClients();

      this.closeClient();

      this.loading = false;
    }

  }

}
