import { Injectable } from "@angular/core";
import { Animal } from "../_models/Animal";
import { GlobalService } from "./GlobalService";
import { ReponseAPI } from "../_models/ReponseAPI";

@Injectable({
    providedIn: 'root'
})
export class MemorialService {

    constructor(private _GlobalService: GlobalService) { }

    public async UpdateFicheAnimalProprietaire(animal: Animal): Promise<ReponseAPI> {
        return await this._GlobalService.SendPUTRequest("Memorial/Animal/", animal)
    }

}