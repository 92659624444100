import { TypeCremation } from "./TypeCremation";
import { Sac } from "./Sac";

export class DetailCremation {
    id: number = 0;
    dateInitialCremation: string = "";
    dateFinalCremation: string = "";
    typeCremationInitialId: number = 0;
    typeCremationInitial: TypeCremation = new TypeCremation();
    typeCremationFinalId: number = 0;
    typeCremationFinal: TypeCremation = new TypeCremation();
    sacId: number = 0;
    sac: Sac = new Sac();
    CremationTerminee: boolean = false;
}