import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Observable } from "rxjs";
import { environment } from "../../../src/environments/environment";
import { TokenService } from "../_services/token.service";
import { GlobalService } from "../_services/GlobalService";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private tokenService: TokenService,private _GlobalService:GlobalService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add auth header with jwt if user is logged in and request is to the api url
        const token = this.tokenService.getTokenStorage();
        const isLoggedIn = token && this.tokenService.isValidToken(token);
        const isApiUrl = request.url.startsWith(environment.apiUrl);
        
        // Vérifier si l'en-tête Authorization existe déjà
        const hasAuthorizationHeader = request.headers.has('Authorization');

        // Ajouter l'en-tête d'autorisation seulement si l'utilisateur est connecté et s'il n'existe pas déjà
        if (isLoggedIn && isApiUrl && !hasAuthorizationHeader) {
            this._GlobalService.HeadersHTTP = this._GlobalService.HeadersHTTP.set('Authorization', `Bearer ${token}`);
        }

        // Cloner la requête et ajouter les en-têtes
        request = request.clone({ headers: this._GlobalService.HeadersHTTP });
    
        return next.handle(request);
    }
    
}
