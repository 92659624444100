import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { GlobalService } from "./GlobalService";
import { ReponseAPI } from "../_models/ReponseAPI";
import { DataModelGetFactures } from "../_models/DataModelController/DataModelFacture";

@Injectable({
    providedIn: 'root'
})

export class FacturationService {

    public constructor(private http: HttpClient, private router: Router, private _GlobalService: GlobalService) { }

    public async GetFacturesProfessionnels(data: DataModelGetFactures): Promise<ReponseAPI> {
        return await this._GlobalService.SendPOSTRequest("Facturation/FacturesProfessionnels", data)
    }
    public async GetFacturesParticuliers(data: DataModelGetFactures): Promise<ReponseAPI> {
        return await this._GlobalService.SendPOSTRequest("Facturation/FacturesParticuliers", data)
    }

}