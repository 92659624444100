import { Time } from "@angular/common";
import { CabinetVeterinaire } from "./CabinetVeterinaire";
import { TourneeEtablissement } from "./TourneeEtablissement";
import { Commande } from "./Commande";
import { UtilisateurSiteCabinet } from "./UtilisateurSiteCabinet";
import { UtilisateurService } from "../_services/UtilisateurService";

export class SiteCabinetVeterinaire {
    id: number = 0;
    CabinetVille: string = "";
    adresse: string = "";
    ville: string = "";
    codePostal: string = "";
    telephone: string = "";
    commentaire: string = "";
    email: string = "";
    siret: string = "";
    cabinetVeterinaireId: number = 0;
    totalPoids: number = 0;
    nbCorps: number = 0;

    horraireDebutMatin: Date = new Date();
    horraireFinMatin: Date = new Date();
    horraireDebutApresMidi: Date = new Date();
    horraireFinApresMidi: Date = new Date();

    cabinetVeterinaire: CabinetVeterinaire = new CabinetVeterinaire();
    tourneeEtablissements: TourneeEtablissement[] = new Array<TourneeEtablissement>();

    commandes: Commande[] = new Array<Commande>();

    utilisateurSiteCabinets: UtilisateurSiteCabinet[]|null = new Array<UtilisateurSiteCabinet>()

    nbCommandeIndividuelle: number = 0;
    nbCommandeIndividuelleAvecCeremonie: number = 0;
    nbCommandeCollective: number = 0;
    nbAnimauxARecuperes: number = 0;
}