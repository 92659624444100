<app-header-admin></app-header-admin>
<app-loader *ngIf="loading"></app-loader>
<div id="habilitations">

  <div class="container">
    <div class="row">
      <div class="col-md-2">
        <app-menu-admin></app-menu-admin>
      </div>
      <div class="col-md-10 p-4">
        <h1>Inventaire de la chambre froide de Phoenix</h1>
        <br><br>
        <div class="row">
          <div class="col-12">
            <div class="grid-wrapper">
              <kendo-grid [data]="gridData" [sortable]="true" (dataStateChange)="dataStateChange($event)"
                [sort]="gridState.sort" [columnMenu]="true" [pageSize]="gridState.take" [skip]="gridState.skip"
                [height]="500" [pageable]="true" [filter]="gridState.filter" [filterable]="true">
                <kendo-grid-column field="detailCremation.sac.id" title="Numéro de commande"> </kendo-grid-column>
                <kendo-grid-column field="dateArrive" title="Date d'arrivé">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    {{dataItem.dateArrive | date:'dd/MM/yyyy HH:mm'}}
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="nbJourDeces" title="Nombre de jour depuis le décès">
                
                </kendo-grid-column>
                <kendo-grid-column field="detailCremation.typeCremationFinal.nom" title="Type de crémation"> </kendo-grid-column>
                <kendo-grid-column field="animal.poids" title="Poids (KG)"> </kendo-grid-column>
              </kendo-grid>
            </div>
          </div>
        </div>
        <br>
      </div>
    </div>
  </div>
</div>