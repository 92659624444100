<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <form #infoForm="ngForm" name="Form">

                <h2>Informations personnelles</h2>
                <br><br>
                <div *ngIf="!this.verifFormInformation" class="alert alert-danger">
                    {{messageErreurInformation}}
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <kendo-label class="k-form" text="Nom *">
                            <kendo-textbox placeholder="Nom" name="nom" [(ngModel)]="this.client.nom"
                                [clearButton]="true" required>
                                <ng-template kendoTextBoxSuffixTemplate>
                                      <kendo-icon name="pencil"></kendo-icon>
                                </ng-template>
                            </kendo-textbox>
                        </kendo-label>
                    </div>
                    <div class="col-md-6">
                        <kendo-label class="k-form" text="Prénom *">
                            <kendo-textbox placeholder="Prénom" name="prenom" [(ngModel)]="this.client.prenom"
                                [clearButton]="true" required>
                                <ng-template kendoTextBoxSuffixTemplate>
                                      <kendo-icon name="pencil"></kendo-icon>
                                </ng-template>
                            </kendo-textbox>
                        </kendo-label>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-md-6">
                        <kendo-label class="k-form" text="E-mail *">
                            <kendo-textbox placeholder="E-mail" name="mail" [(ngModel)]="this.client.email"
                                [clearButton]="true" required>
                                <ng-template kendoTextBoxSuffixTemplate>
                                      <kendo-icon name="pencil"></kendo-icon>
                                </ng-template>
                            </kendo-textbox>
                        </kendo-label>
                    </div>

                    <div class="col-md-6">
                        <kendo-label class="k-form" text="Téléphone *">
                            <kendo-textbox placeholder="Téléphone" name="telephone" [(ngModel)]="this.client.telephone"
                                [clearButton]="true" required>
                                <ng-template kendoTextBoxSuffixTemplate>
                                      <kendo-icon name="pencil"></kendo-icon>
                                </ng-template>
                            </kendo-textbox>
                        </kendo-label>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-md-4">
                        <kendo-label class="k-form" text="Adresse *">
                            <kendo-textbox required placeholder="Adresse" name="adresse"
                                [(ngModel)]="this.client.adresse" [clearButton]="true">
                                <ng-template kendoTextBoxSuffixTemplate>
                                      <kendo-icon name="pencil"></kendo-icon>
                                </ng-template>
                            </kendo-textbox>
                        </kendo-label>
                    </div>
                    <div class="col-md-4">
                        <kendo-label class="k-form" text="Code postal *">
                            <kendo-textbox required placeholder="Code postal" name="codePostal"
                                [(ngModel)]="this.client.codePostal" [clearButton]="true">
                                <ng-template kendoTextBoxSuffixTemplate>
                                      <kendo-icon name="pencil"></kendo-icon>
                                </ng-template>
                            </kendo-textbox>
                        </kendo-label>
                    </div>
                    <div class="col-md-4">
                        <kendo-label class="k-form" text="Ville *">
                            <kendo-textbox required placeholder="Ville" name="ville" [(ngModel)]="this.client.ville"
                                [clearButton]="true">
                                <ng-template kendoTextBoxSuffixTemplate>
                                      <kendo-icon name="pencil"></kendo-icon>
                                </ng-template>
                            </kendo-textbox>
                        </kendo-label>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-md-12">
                        <kendo-label class="k-form" text="Commentaire interne :">
                            <kendo-textarea placeholder="Commentaire" [(ngModel)]="this.client.commentaire" [rows]="5"
                                name="commentaire">
                            </kendo-textarea>
                        </kendo-label>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<app-loader *ngIf="loading"></app-loader>