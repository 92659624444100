import { Component, OnInit } from '@angular/core';
import { ProduitService } from '../../_services/ProduitService';
import { ActivatedRoute } from '@angular/router';
import { ClientService } from '../../_services/ClientService';
import { Client } from '../../_models/Client';

@Component({
  selector: 'app-boutique-client-categorie',
  templateUrl: './boutique-client-categorie.component.html',
  styleUrls: ['./boutique-client-categorie.component.scss']
})
export class BoutiqueClientCategorieComponent implements OnInit {

  nomCategorie: string = ""

  constructor(public ProduitService: ProduitService, private _ClientService: ClientService, private _router: ActivatedRoute) {
  }

  ngOnInit(): void {
    this._router.queryParams.subscribe(async params => {
      this.nomCategorie = params['categorie'];
      await this.ProduitService.SetNomCategorie(this.nomCategorie)

      if (this.nomCategorie == "Crémation") {
        //Aller chercher la créamation choisi par le client
        let client: Client = (await this._ClientService.GetInformations()).objet

        let typeCremation = client.commandes[0].detailCremation.typeCremationFinalId

        let NombreDebutSliceProduit = 0

        switch (typeCremation) {
          case 1:
            NombreDebutSliceProduit = 1
            break;
          case 2:
            NombreDebutSliceProduit = 2
            break;
          case 3:
            NombreDebutSliceProduit = 3
            break;
        }

        this.ProduitService.produits = this.ProduitService.produits.slice(NombreDebutSliceProduit, this.ProduitService.produits.length)

      }
    });
  }

  GoToAccueil() {
    window.location.href = "/client/accueil"
  }

}
