<app-header-admin></app-header-admin>
<app-loader *ngIf="loading"></app-loader>
<div id="habilitations">

  <div class="container">
    <div class="row">
      <div class="col-md-2">
        <app-menu-admin></app-menu-admin>
      </div>
      <div class="col-md-10 p-4">

        <h1>Clients</h1>
        <br>
        <div>
          <button kendoButton (click)="ajoutClient()" themeColor="primary">Ajouter un nouveau
            compte client</button>
        </div>
        <br>
        <div class="row">
          <div class="col-12">
            <div class="grid-wrapper">
              <kendo-grid [data]="gridData" (dataStateChange)="dataStateChange($event)" [sortable]="true"
                [sort]="gridState.sort" [columnMenu]="true" [pageSize]="gridState.take" [skip]="gridState.skip"
                [height]="500" [pageable]="true" [filter]="gridState.filter" [filterable]="true"
                (edit)="modifieCompte($event)">
                <kendo-grid-column field="prenom" title="Prénom"></kendo-grid-column>
                <kendo-grid-column field="nom" title="Nom"></kendo-grid-column>
                <kendo-grid-column field="email" title="Email"></kendo-grid-column>
                <kendo-grid-column field="telephone" title="Téléphone"></kendo-grid-column>
                <kendo-grid-column title="Action">
                  <ng-template kendoGridCellTemplate>
                    <button themeColor="primary" kendoGridEditCommand>Modifier</button>
                  </ng-template>
                </kendo-grid-column>
              </kendo-grid>

            </div>
          </div>
        </div>
        <br>
      </div>
    </div>
  </div>
</div>


<ng-template #dialogActions>
  <button kendoButton (click)="closeClient()">Fermer</button>
  <button kendoButton themeColor="primary" (click)="enregistreClient()">Enregistrer</button>
</ng-template>
<ng-container #detailCompteContainer></ng-container>