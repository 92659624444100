<div class="ContainerAll">
    <div class="LogoHautChat">
        <img src="../../assets/Connexion/chat.png" alt="logoChat">
    </div>
    <div class="LogoPhoenixMilieu">
        <img src="../../assets/Connexion/LogoPhoenix.png" alt="logoChat" id="LogoPhoenix">
        <img src="../../assets/Connexion/LogoTexte.svg" alt="logoChat" id="LogoPhoenixTexte">
    </div>
    <form id="form" class="login-form">

        <div class="row">
            <p>Bienvenue, merci de saisir votre adresse mail afin de recevoir le mail d'authentification</p>

        </div>
        <div class="row">
            <kendo-label class="lblTxt" text="Email" for="email"></kendo-label>
            <kendo-textbox id="email" type="text" [(ngModel)]="EmailClient" name="email" required></kendo-textbox>
        </div>
        <br>
        <p *ngIf="this.MessageErreur !=''" class="alert alert-danger" style="background-color: red; color: black;">
            {{MessageErreur}}</p>
        <p *ngIf="this.MessageSucces !=''" class="alert alert-sucess" style="background-color: green; color: white;">
            {{MessageSucces}}</p>

        <div class="row">
            <kendo-button type="submit" id="btnConnexion" (click)="onSubmit()">Obtenir le lien de
                connexion</kendo-button>
        </div>
        <br>


    </form>
    <div class="LogoBasChien">
        <img src="../../assets/Connexion/chien.png" alt="LogoChien">
    </div>
</div>

<ngx-spinner bdColor="rgba(197, 191, 194, 0.8)" size="default" color="red" type="ball-clip-rotate"></ngx-spinner>