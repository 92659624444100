import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { GlobalService } from '../../_services/GlobalService'
import { UtilisateurService } from '../../_services/UtilisateurService';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header-admin',
  templateUrl: './header-admin.component.html',
  styleUrls: ['./header-admin.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderAdminComponent implements OnInit {

  constructor(
    public _GlobalService: GlobalService,
    private _UtilisateurService: UtilisateurService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.chargeAdmin();
  }

  public deconnexion() {
    if (this.router.url.toLowerCase().includes("admin") || this._GlobalService.utilisateur?.role == "vétérinaire") {
      this._UtilisateurService.DeconnexionAdmin()
    } else {
      this._UtilisateurService.Deconnexion()
    }

  }

  public GoToMonCompte(): string {
    if (this._GlobalService.isAdmin()) {
      return "/admin/moncompte"
    } else {
      return "/moncompte"
    }
  }
  private async chargeAdmin() {
    this._GlobalService.utilisateur = (await this._UtilisateurService.GetInfosUser()).objet;
  }
}
