import { Injectable } from "@angular/core";
import { MessageService } from "@progress/kendo-angular-l10n";

//TODO : Ajouter ici si traduction manquantes.
const data: any = {
    fr: {
        rtl: false,
        messages: {
            "kendo.datepicker.toggle": "basculer",
            "kendo.datepicker.today": "Aujourd'hui",
            "kendo.combobox.noDataText": "Aucune donnée",
            "kendo.combobox.clearTitle": "Effacer",
            "kendo.autocomplete.noDataText": "Aucune donnée",
            "kendo.autocomplete.clearTitle": "Effacer",
            "kendo.dialog.closeTitle": "Fermer",
            "kendo.grid.groupCollapse": "Réduire",
            "kendo.grid.groupExpand": "Développer",
            "kendo.grid.groupPanelEmpty": "Faites glisser un en-tête de colonne et déposer ici pour grouper par cette colonne.",
            "kendo.grid.noRecords": "Aucun enregistrement disponible.",
            "kendo.grid.pagerFirstPage": "Aller à la première page",
            "kendo.grid.pagerPreviousPage": "Aller à la page précédente",
            "kendo.grid.pagerNextPage": "Aller à la page suivante",
            "kendo.grid.pagerLastPage": "Aller à la dernière page",
            "kendo.grid.pagerPage": "Page",
            "kendo.grid.pagerOf": "sur",
            "kendo.grid.pagerItems": "Articles",
            "kendo.grid.pagerPageNumberInputTitle": "Numéro de page",
            "kendo.grid.pagerItemsPerPage": "Articles par page",
            "kendo.grid.filter": "Filtrer",
            "kendo.grid.filterEqOperator": "Est égal à",
            "kendo.grid.filterNotEqOperator": "N'est pas égal à",
            "kendo.grid.filterIsNullOperator": "Est null",
            "kendo.grid.filterIsNotNullOperator": "N'est pas null",
            "kendo.grid.filterIsEmptyOperator": "Est vide",
            "kendo.grid.filterIsNotEmptyOperator": "N'est pas vide",
            "kendo.grid.filterStartsWithOperator": "Commence par",
            "kendo.grid.filterContainsOperator": "Contient",
            "kendo.grid.filterNotContainsOperator": "Ne contient pas",
            "kendo.grid.filterEndsWithOperator": "Se termine par",
            "kendo.grid.filterGteOperator": "Est supérieur ou égal à",
            "kendo.grid.filterGtOperator": "Est supérieur à",
            "kendo.grid.filterLteOperator": "Est inférieur ou égal à",
            "kendo.grid.filterLtOperator": "Est inférieur à",
            "kendo.grid.filterIsTrue": "Est vrai",
            "kendo.grid.filterIsFalse": "Est faux",
            "kendo.grid.filterBooleanAll": "(Tout)",
            "kendo.grid.filterAfterOrEqualOperator": "Est après ou égal au",
            "kendo.grid.filterAfterOperator": "Est après le",
            "kendo.grid.filterBeforeOperator": "Est avant le",
            "kendo.grid.filterBeforeOrEqualOperator": "Est avant ou égal au",
            "kendo.grid.filterFilterButton": "Filtrer",
            "kendo.grid.filterClearButton": "Effacer",
            "kendo.grid.filterAndLogic": "Et",
            "kendo.grid.filterOrLogic": "Ou",
            "kendo.grid.loading": "Chargement",
            "kendo.grid.gridLabel": "Table de données",
            "kendo.grid.columns": "Colonnes",
            "kendo.grid.lock": "Verrouiller",
            "kendo.grid.unlock": "Déverrouiller",
            "kendo.grid.setColumnPosition": "Définir la position de la colonne",
            "kendo.grid.stick": "Fixer",
            "kendo.grid.unstick": "Libérer",
            "kendo.grid.sortable": "Triable",
            "kendo.grid.sortAscending": "Tri par ordre croissant",
            "kendo.grid.sortDescending": "Tri par ordre décroissant",
            "kendo.grid.sortedAscending": "Trié par ordre croissant",
            "kendo.grid.sortedDescending": "Trié par ordre décroissant",
            "kendo.grid.sortedDefault": "Non trié",
            "kendo.grid.columnsApply": "Appliquer",
            "kendo.grid.columnsReset": "Reset",
            "kendo.grid.detailExpand": "Développer détail",
            "kendo.grid.detailCollapse": "Réduire détail",
            "kendo.grid.filterDateToday": "Aujourd'hui",
            "kendo.grid.filterDateToggle": "Ouvrir le calendrier",
            "kendo.grid.filterNumericDecrement": "Décrémenter",
            "kendo.grid.filterNumericIncrement": "Incrémenter",
            "kendo.grid.selectionCheckboxLabel": "Sélectionner ligne",
            "kendo.grid.selectAllCheckboxLabel": "Sélectionner toutes les lignes",
            "kendo.dropdownlist.noDataText": "AUCUNE DONNEE",
            "kendo.datepicker.prevButtonTitle": "Naviguer vers la vue précédente",
            "kendo.datepicker.nextButtonTitle": "Naviguer vers la vue suivante",
            "kendo.dateinput.increment": "Incrémenter la valeur",
            "kendo.dateinput.decrement": "Décrémenter la valeur",
            "kendo.grid.pagerLabel": "{{ 'Page, page {currentPage} sur {totalPages}' }}",
            "kendo.grid.filterInputLabel": "{{ '{columnName} Filtre' }}",
            "kendo.grid.filterMenuTitle": "{{ '{columnName} Menu filtre' }}",
            "kendo.grid.filterMenuOperatorsDropDownLabel": "{{ '{columnName} Opérateurs filtre' }}",
            "kendo.grid.filterMenuLogicDropDownLabel": "{{ '{columnName} Logique filtre' }}",
            "kendo.grid.columnMenu": "{{ '{columnName} Menu Colonne' }}",
            "kendo.floatinglabel.optional": "Optionnel",
            "kendo.textbox.clear": "Effacer",
            "kendo.numerictextbox.increment": "Incrémenter",
            "kendo.numerictextbox.decrement": "Décrémenter",
            "kendo.label.optional": "Optionnel",
            "kendo.tabstrip.closeTitle": "Fermer",
            "kendo.treelist.noRecords": "Aucun enregristrement",
            "kendo.treelist.pagerFirstPage": "Aller à la première page",
            "kendo.treelist.pagerPreviousPage": "Aller à la page précédente",
            "kendo.treelist.pagerNextPage": "Aller à la page suivante",
            "kendo.treelist.pagerLastPage": "Aller à la dernière page",
            "kendo.treelist.pagerPage": "Page",
            "kendo.treelist.pagerOf": "sur",
            "kendo.treelist.pagerPageNumberInputTitle": "Numéro de page",
            "kendo.treelist.pagerItemsPerPage": "articles par page",
            "kendo.treelist.pagerItemsTotal": "articles",
            "kendo.treelist.filter": "Filtrer",
            "kendo.treelist.filterEqOperator": "Est égal à",
            "kendo.treelist.filterNotEqOperator": "N'est pas égal à",
            "kendo.treelist.filterIsNullOperator": "Est null",
            "kendo.treelist.filterIsNotNullOperator": "N'est pas null",
            "kendo.treelist.filterIsEmptyOperator": "Est vide",
            "kendo.treelist.filterIsNotEmptyOperator": "N'est pas vide",
            "kendo.treelist.filterStartsWithOperator": "Commence par",
            "kendo.treelist.filterContainsOperator": "Contient",
            "kendo.treelist.filterNotContainsOperator": "Ne contient pas",
            "kendo.treelist.filterEndsWithOperator": "Se termine par",
            "kendo.treelist.filterGteOperator": "Est supérieur ou égal à",
            "kendo.treelist.filterGtOperator": "Est supérieur à",
            "kendo.treelist.filterLteOperator": "Est inférieur ou égal à",
            "kendo.treelist.filterLtOperator": "Est inférieur à",
            "kendo.treelist.filterIsTrue": "Est vrai",
            "kendo.treelist.filterIsFalse": "Est faux",
            "kendo.treelist.filterBooleanAll": "(Tout)",
            "kendo.treelist.filterAfterOrEqualOperator": "Est après ou égal au",
            "kendo.treelist.filterAfterOperator": "Est après le",
            "kendo.treelist.filterBeforeOperator": "Est avant le",
            "kendo.treelist.filterBeforeOrEqualOperator": "Est avant ou égal au",
            "kendo.treelist.filterFilterButton": "Filtrer",
            "kendo.treelist.filterClearButton": "Effacer",
            "kendo.treelist.filterAndLogic": "Et",
            "kendo.treelist.filterOrLogic": "Ou",
            "kendo.treelist.loading": "Chargement",
            "kendo.treelist.columnMenu": "Menu Colonne",
            "kendo.treelist.columns": "Colonnes",
            "kendo.treelist.lock": "Verrouiller",
            "kendo.treelist.unlock": "Déverrouiller",
            "kendo.treelist.sortable": "Triable",
            "kendo.treelist.sortAscending": "Tri par ordre croissant",
            "kendo.treelist.sortDescending": "Tri par ordre décroissant",
            "kendo.treelist.sortedAscending": "Trié par ordre croissant",
            "kendo.treelist.sortedDescending": "Trié par ordre décroissant",
            "kendo.treelist.sortedDefault": "Non trié",
            "kendo.treelist.columnsApply": "Appliquer",
            "kendo.treelist.columnsReset": "Reset",
            "kendo.timepicker.now": "Maintenant",
            "kendo.timepicker.cancel": "Annuler",
            "kendo.fileselect.cancel": "Annuler",
            "kendo.timepicker.accept": "Valider",
            "kendo.dropdownlist.selectButtonText": "Sélection",
            "kendo.fileselect.select": "Choisir un fichier",
            "kendo.fileselect.clearSelectedFiles": "Supprimer",
            "kendo.fileselect.dropFilesHere": "Sélectionnez un fichier ou faites le glisser",
            "kendo.fileselect.uploadSelectedFiles": "Importer l'image",
            "kendo.fileselect.invalidFileExtension": "Type de fichier non valide. Format attendu : .jpg, .jpeg, .png ",

        }
    },
};

@Injectable()
export class TradTelerikService extends MessageService {
    public set language(value: string | any) {
        const lang = data[value];
        if (lang) {
            this.localeId = value;
            this.notify(lang.rtl);
        }
    }

    public get language(): string {
        return this.localeId;
    }

    private localeId: any = "fr";
    private get messages(): any {
        const lang = data[this.localeId];

        if (lang) {
            return lang.messages;
        }
    }

    public override get(key: string): string {

        // if (this.messages[key] == null) {
        //     console.log("Traduction manquante :" + key)
        // }
        return this.messages[key];
    }
}