import { Component, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { Commande } from '../../_models/Commande';
import { ClientService } from '../../_services/ClientService';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { State, process } from '@progress/kendo-data-query';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { CommandeArticleViewComponent } from '../../Admin/_Views/commande-article-view/commande-article-view.component';
import { CommandeViewComponent } from '../../Admin/_Views/commande-view/commande-view.component';
import { CommandeClientViewComponent } from '../_Views/commande-client-view/commande-client-view.component';
import { ProduitService } from '../../_services/ProduitService';
import { Produit } from '../../_models/Produit';
import { Router } from '@angular/router';
import { CommandeService } from '../../_services/CommandeService';
import { ElementRef } from '@angular/core';
import { PanierService } from '../../_services/PanierService';

@Component({
  selector: 'app-accueil-client',
  templateUrl: './accueil-client.component.html',
  styleUrls: ['./accueil-client.component.scss']
})
export class AccueilClientComponent implements OnInit {

  Allproduits: Produit[] = []
  produits: Produit[] = []
  commande: Commande = new Commande()

  loading: boolean = false;
  @ViewChild('ContainerSuivi1') ContainerSuivi1!: ElementRef<HTMLDivElement>;
  @ViewChild('ContainerSuivi2') ContainerSuivi2!: ElementRef<HTMLDivElement>;
  @ViewChild('ContainerSuivi3') ContainerSuivi3!: ElementRef<HTMLDivElement>;
  @ViewChild('ContainerSuivi1') ContainerSuivi4!: ElementRef<HTMLDivElement>;
  //@ViewChild('ContainerSuivi5') ContainerSuivi5!: ElementRef<HTMLDivElement>;

  constructor(private _ClientService: ClientService, private _PanierService: PanierService, private _produitService: ProduitService, private _router: Router) { }

  GoToBoutique() {
    location.href = "/client/boutique/produits"
  }

  async ngOnInit(): Promise<void> {
    this.loading = true
    //Venir charger la liste des produits
    this.Allproduits = (await this._produitService.GetAllProduits()).objet

    //Limite le nombre de produits à 4
    this.produits = this.Allproduits.slice(0, 4)

    //Aller chercher la commande en cours du client
    this.commande = (await this._ClientService.GetCommandeClient()).objet

    //Si elle n'est pas en cours alors aller chercher la dernière
    if (this.commande == undefined) {
      this.commande = (await this._ClientService.GetLatestCommande()).objet
    }

    //Si la commande est archivée ou que la commande est incinéré alors ne pas accéder à cette interface
    if (this.commande == undefined) {
      //Rediriger vers l'accueil
      this._router.navigate(["/Connexion"])
    } else {
      //Cacher les créamtions selont la commande actuelle
      switch (this.commande.detailCremation.typeCremationFinalId) {
        case 1:
          //Dans le cas d'une crémation collective alors proposer individuelle, indivuelle ++ et colombarium
          break
        case 2:
          //Dans le cas d'une crémation indivuelle cacher individuelle et collective et ne laisser que le colombarium et la crémation ++
          document.getElementById("Individuelle")!.style.display = "none"
          break
        case 3:
          //Dans le cas d'une crémation indivuelle ++ tout cacher sauf le colombarium
          document.getElementById("ChangementOffre")!.style.display = "none"
          break
      }

      switch (this.commande.asso_StatutCommande_Commande[this.commande.asso_StatutCommande_Commande.length - 1].statutCommandeId) {
        case 1:
          // Changer la classe du container 1
          this.ContainerSuivi1.nativeElement.id = "ContainerActif";
          break;
        case 2:
          // Changer la classe du container 1
          this.ContainerSuivi2.nativeElement.id = "ContainerActif";
          break;
        case 3:
          // Changer la classe du container 1
          this.ContainerSuivi3.nativeElement.id = "ContainerActif";
          break;
        case 4:
          // Changer la classe du container 1
          this.ContainerSuivi4.nativeElement.id = "ContainerActif";
          break;
        // case 5:
        //   // Changer la classe du container 1
        //   this.ContainerSuivi5.nativeElement.id = "ContainerActif";
        //   break;

      }
    }

    this.loading = false

  }

  AjouterProduit(idproduit: number) {
    //Aller chercher le produit
    let produit = this.Allproduits.find(x => x.id == idproduit)

    //Si le panier ne contient pas le produit alors l'ajouter
    if (this._PanierService.Panier != null && !this._PanierService.Panier.find(x => x.idProduit == idproduit)) {
      this._PanierService.AjouterProduitPanier(produit!)
    }

    //Rediriger vers le panier
    this._router.navigate(["/client/panier"])
  }

  GoToSite(url:string){
    let a = document.createElement("a")
    a.href = url
    a.target = "_blank"
    a.click()
    document.removeChild(a)
  }

  GoToSiteCremationIndividuelleCeremonie(){
    let a = document.createElement("a")

    a.href = ""
    a.target = "_blank"
    a.click()
    document.removeChild(a)
  }

}