<div id="card" [ngStyle]="{'background-color': this.getAnimauxRecupere(tournee) ==0 ? '#ECF2C6' : 'white'}"
    (click)="DetailTourneeCabinet(tournee)" *ngFor="let tournee of Tournees">

    <img *ngIf="this.getAnimauxRecupere(tournee) ==0" src="../../../../assets/TechnicienChauffeur/Check.svg" alt="Check"
        id="LogoCheck">

    <b id="NomCabinet">Cabinet {{tournee.siteCabinetVeterinaire.cabinetVeterinaire.nom}}</b>
    <br>
    <p id="VilleCabinet">{{tournee.siteCabinetVeterinaire.ville}}</p>

    <div id="DivAnimauxRecupere">
        <p id="AnimauxRecupere">Animaux à récupérer </p>
        <span id="NbAnimaux">{{this.getAnimauxRecupere(tournee)}}</span>
    </div>
    <div id="DivAnimauxRecupere">
        <p id="AnimauxRecupere">Consommation des sacs :</p>
    </div>
    <div id="DivAnimauxRecupere">
        <p class="TxtTaille" id="AnimauxRecupere"><b>S</b> :{{this.getNbSacsConsomes(tournee, 'S')}} <b>M</b> :
            {{this.getNbSacsConsomes(tournee, 'M')}} <b>L</b> : {{this.getNbSacsConsomes(tournee, 'L')}}</p>

    </div>
    <br>
</div>