<app-header-admin></app-header-admin>
<app-loader *ngIf="loading"></app-loader>
<div id="habilitations">

    <div class="container">
        <div class="row">
            <div class="col-md-2">
                <app-menu-admin></app-menu-admin>
            </div>
            <div class="col-md-10 p-4">
                <h1>Mon compte</h1>
                <kendo-tabstrip>
                    <kendo-tabstrip-tab title="Mot de passe" [selected]="true">
                        <ng-template kendoTabContent>
                            <div class="row">
                                <div class="col-6">
                                    <div class="alert alert-danger" *ngIf="messageErreur!=''">{{messageErreur}}</div>
                                    <div class="alert alert-success" *ngIf="messageSucces!='' && messageErreur == ''">
                                        {{messageSucces}}</div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-6 mb-4">
                                    <kendo-label class="k-form" text="Ancien mot de passe :">
                                        <kendo-textbox placeholder="Ancien mot de passe :" #ancienMdpBox name="Ancien"
                                            [(ngModel)]="this.ancienMDP" [clearButton]="true" required>
                                            <ng-template kendoTextBoxSuffixTemplate>
                                                <kendo-icon name="eye" (click)="ShowPassword(1)"></kendo-icon>
                                            </ng-template>
                                        </kendo-textbox>

                                    </kendo-label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6 mb-4">
                                    <kendo-label class="k-form" text="Nouveau mot de passe :">
                                        <kendo-textbox placeholder="Nouveau mot de passe :" #nouveauMdpBox
                                            name="nouveauMdp" [(ngModel)]="this.nouveauMdp" [clearButton]="true"
                                            (valueChange)="onChangeMdp($event)" required>
                                            <ng-template kendoTextBoxSuffixTemplate>
                                                <kendo-icon name="eye" (click)="ShowPassword(2)"></kendo-icon>
                                            </ng-template>
                                        </kendo-textbox>
                                        <span *ngIf="!this.nouveauMdpValide" style="color:#f31700">Le mot de passe doit
                                            contenir au
                                            moins 8 caractères, 1 majuscule, 1 minuscule, 1 chiffre(0-9)</span>
                                        <span *ngIf="this.nouveauMdpValide" style="color:#37b400">Mot de passe
                                            correct</span>
                                    </kendo-label>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-6 mb-4">
                                    <kendo-label class="k-form" text="Confirmation du mot de passe :">
                                        <kendo-textbox placeholder="Confirmation du mot de passe :" #confirmationMdpBox
                                            name="confirmationMdp" [(ngModel)]="this.confirmationMdp"
                                            [clearButton]="true" required>
                                            <ng-template kendoTextBoxSuffixTemplate>
                                                <kendo-icon name="eye" (click)="ShowPassword(3)"></kendo-icon>
                                            </ng-template>
                                        </kendo-textbox>
                                    </kendo-label>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-6 mb-4 row justify-content-center">
                                    <button style="width: 20vw;" kendoButton themeColor="primary"
                                        (click)="modifieAdmin()" class="mb-4">Enregistrer les
                                        modifications</button>
                                </div>
                            </div>
                        </ng-template>

                    </kendo-tabstrip-tab>
                    <kendo-tabstrip-tab title="Double authentification" [selected]="false">
                        <ng-template kendoTabContent>
                            <div class="row">
                                <div class="col-6 mb-4">
                                    <div class="alert alert-danger" *ngIf="messageErreur2FA!=''">
                                        {{messageErreur2FA}}</div>
                                    <div class="alert alert-success"
                                        *ngIf="messageSucces2FA!='' && messageErreur2FA == ''">
                                        {{messageSucces2FA}}</div>
                                </div>
                            </div>
                            <div class="col-6 mb-4">
                                <kendo-label id="LblDark" text="Double authentification">
                                    <kendo-switch
                                        [(ngModel)]="this._GlobalService.utilisateur!.twoFactorEnabled"></kendo-switch>
                                </kendo-label>

                                <div class="ContainerQr" *ngIf="this._GlobalService.utilisateur!.twoFactorEnabled">

                                    <p>Pour obtenir le code de double authentification, veuillez scanner ce qrcode
                                        avec
                                        l'application de double authentification de votre choix (Espace Secure,
                                        Google
                                        Authenticator ...)
                                    </p>
                                    <img [src]="LienAuthentification" alt="Qrcode" />
                                    <p class="code">{{codeManuel}}</p>

                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div style="margin-top: 3vh;" class="col-6 mb-4 row justify-content-center">
                                    <button kendoButton style="width: 20vw;" themeColor="primary"
                                        (click)="ModifieDoubleAuthentificationAdmin()" class="mb-4">Enregistrer les
                                        modifications</button>
                                </div>
                            </div>
                        </ng-template>
                    </kendo-tabstrip-tab>
                </kendo-tabstrip>
            </div>
        </div>
    </div>
</div>