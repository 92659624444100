import { Component, ViewChild, ElementRef, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import {
  ScannerQRCodeResult,
  NgxScannerQrcodeComponent,
  ScannerQRCodeSelectedFiles,
} from 'ngx-scanner-qrcode';
import { StatutCommande } from '../../../_models/StatutCommande';
import { StatutCommandeService } from '../../../_services/StatutCommandeService';
import { Location } from '@angular/common';
import { GlobalService } from '../../../_services/GlobalService';
import { SacService } from '../../../_services/SacService';
import { TypeSacCremation } from '../../../_models/TypeSacCremation';
import { DataModelUpdateStatutCommande } from '../../../_models/DataModelController/DataModelUpdateStatutCommande';
import { trashIcon } from '@progress/kendo-svg-icons';


@Component({
  selector: 'app-qr-code-multi',
  templateUrl: './qr-code-multi.component.html',
  styleUrls: ['./qr-code-multi.component.scss']
})
export class QrCodeMultiComponent implements OnInit, OnDestroy, AfterViewInit {

  public resultEtiquetteTypeSac: DataModelUpdateStatutCommande[] = [];
  public qrCodeResult: ScannerQRCodeSelectedFiles[] = [];
  public qrCodeResult2: ScannerQRCodeSelectedFiles[] = [];
  public StatutCommandes: StatutCommande[] = [];
  public TypesSacCremation: TypeSacCremation[] = [];
  public StatutCommandeSelect: StatutCommande = new StatutCommande();
  TxtLstEtiquettes: string = ""
  messageErreurInformation: string = ""
  messageSuccesInformation: string = ""
  TxtAjoutEtiquetteManuelle: string = ""
  icon: any = trashIcon;
  @ViewChild('ajoutEtiquetteTextBox', { read: ElementRef }) ajoutEtiquetteTextBox!: ElementRef;


  @ViewChild('audioPlayer')
  audioPlayer!: ElementRef;
  @ViewChild('audioPlayerErreur')
  audioPlayerErreur!: ElementRef;
  @ViewChild('action') action!: NgxScannerQrcodeComponent;

  public constructor(public _GlobalService: GlobalService, private _location: Location, private _StatutCommandeService: StatutCommandeService, private _SacService: SacService) {

  }

  async ngOnInit() {
    this.StatutCommandes = (await this._StatutCommandeService.GetAllStatuts()).objet

    this.StatutCommandeSelect = this.isChauffeur() ? this.StatutCommandes[1] : this.StatutCommandes[2]

    //Aller chercher la liste des tailles de sac
    this.TypesSacCremation = (await this._SacService.GetAllTaillesSac()).objet

    if (!this.isChauffeur()) {
      this.StatutCommandes = this.StatutCommandes.slice(2)

      //Supprimer le type "En crémation"
      this.StatutCommandes = this.StatutCommandes.filter(s => s.nom != "En crémation")
    }
  }

  isChauffeur() {
    return localStorage.getItem('Role')?.toLowerCase() == 'chauffeur'
  }

  ngAfterViewInit(): void {
    const inputElement = this.ajoutEtiquetteTextBox.nativeElement.querySelector('input');
    if (inputElement) {
      inputElement.setAttribute('inputmode', 'numeric');
      inputElement.setAttribute('pattern', '[0-9]*');
    }
    this.DemarerScan();
  }

  ngOnDestroy(): void {
    this.StopScan();
  }

  public DemarerScan(): void {
    this.handle(this.action, 'start');
  }
  public StopScan(): void {
    this.handle(this.action, 'stop');
  }

  public onEvent(e: ScannerQRCodeResult[], action?: any): void {
    if (e && e.length > 0) {
      this.AjouterEtiquette(e[0].value)

    }
  }

  public handle(action: any, fn: string): void {
    const playDeviceFacingBack = (devices: any[]) => {
      const device = devices.find(f => (/(back)/gi.test(f.label)));
      action.playDevice(device ? device.deviceId : devices[0].deviceId);
    }

    if (fn === 'start') {
      action[fn](playDeviceFacingBack).subscribe(

        (error: any) => {
          if (error instanceof DOMException && error.name === 'NotAllowedError') {
            alert('Veuillez autoriser l\'accès à la caméra pour utiliser cette fonctionnalité.');
          } else {

          }
        }
      );
    } else {
      action[fn]().subscribe(

        (error: any) => {

        }
      );
    }
  }

  AjouterEtiquette(etiquette: string) {
    if (etiquette != "" && parseInt(etiquette) > 0
      && this.resultEtiquetteTypeSac.map(x => x.etiquette).filter(x => x == Number(etiquette)).length == 0) {

      //Ajouter à la liste
      this.resultEtiquetteTypeSac.push({ etiquette: Number(etiquette), typeSac: new TypeSacCremation(), statuCommandeId: this.StatutCommandeSelect.id })

      // Jouer le son
      this.audioPlayer.nativeElement.play();

      this.TxtAjoutEtiquetteManuelle = ""
    }
  }

  AjouterEtiquetteManuellement() {
    this.AjouterEtiquette(this.TxtAjoutEtiquetteManuelle)
  }

  async ValiderScan() {
    this.messageErreurInformation = ""

    //Vérifier si la liste n'est pas vide
    if (this.resultEtiquetteTypeSac.length > 0) {

      //Si tous les éléments de la liste possède un type de sac
      if ((this.isChauffeur() && this.resultEtiquetteTypeSac.filter(x => x.typeSac.id == undefined).length == 0) || !this.isChauffeur()) {

        //Affecter le statut commande sélectionné à tous les éléments de la liste
        this.resultEtiquetteTypeSac.forEach(x => {
          x.statuCommandeId = this.StatutCommandeSelect.id
        })

        //Faire la requête
        let reponse = await this._StatutCommandeService.UpdateStatutCommande(this.resultEtiquetteTypeSac)

        if (reponse.statusCode == 200) {
          this.messageSuccesInformation = reponse.message
          this.resultEtiquetteTypeSac = []
          this.TxtLstEtiquettes = ""
          this._location.back();
        } else {
          this.messageErreurInformation = reponse.messageException
        }
      } else {
        this.messageErreurInformation = "Veuillez sélectionner un type de sac pour chaque étiquette"
      }
    } else {
      this.messageErreurInformation = "Veuillez scanner une ou plusieurs étiquettes"
    }
  }

  SupprimerLstEtiquettes(dataItem: any) {
    this.resultEtiquetteTypeSac = this.resultEtiquetteTypeSac.filter(x => x.etiquette != dataItem.etiquette)
  }
}
