import { Sac } from "./Sac";
import { SiteCabinetVeterinaire } from "./SiteCabinetVeterinaire";

export class DemandeSac {
    id: number = 0;
    dateApprovisionnement: Date = new Date();
    numeroSacInitial: number = 0;
    numeroSacFinal: number = 0;
    siteCabinetVeterinaireId: number = 0;
    siteCabinetVeterinaire: SiteCabinetVeterinaire = new SiteCabinetVeterinaire();
}