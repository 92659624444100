<app-header-technicien-chauffeur [Titre]="'Liste des animaux à récupérer'"></app-header-technicien-chauffeur>

<div class="Container">
    <div class="ContainerFournee">
        <div id="ContainerScan">
            <button kendoButton id="btnScan" (click)="this.OuvrirQrCodeMulti()">SCAN MULTIPLE <img src="../../../../assets/TechnicienChauffeur/ScanMultiple.svg" alt=""></button>
        </div>
        <div class="ContainerTxtCabinet">
            <p id="TextNomCabinet"> Cabinet {{SiteCabinet.cabinetVeterinaire.nom}} <br>{{SiteCabinet.ville}}</p>
            <br>
        </div>
        <div class="ContainerTxtAnimauxRecupere">
            <p id="TextAnimauxRecupere">Animaux récupérés</p>
            <p id="NbAnimauxDroite"> {{GetNbAnimauxRecupere()}} / {{nbAnimauxARecupere}} </p>
        </div>
        <app-carte-cabinet-commandes [Commandes]="SiteCabinet.commandes"></app-carte-cabinet-commandes>
    </div>
</div>