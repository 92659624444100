import { AfterViewChecked, AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TypeCremation } from '../../../_models/TypeCremation';
import { TypeCremationService } from '../../../_services/TypeCremationService';
import { Commande } from '../../../_models/Commande';
import { CommandeService } from '../../../_services/CommandeService';
import { GlobalService } from '../../../_services/GlobalService';
import { Router } from '@angular/router';
import { NgxScannerQrcodeComponent, ScannerQRCodeResult } from 'ngx-scanner-qrcode';

@Component({
  selector: 'app-technicien-fournee',
  templateUrl: './technicien-fournee.component.html',
  styleUrls: ['./technicien-fournee.component.scss']
})
export class TechnicienFourneeComponent implements OnInit, OnDestroy, AfterViewInit {

  loading: boolean = false
  TypesCremation: TypeCremation[] = new Array()
  Commandes: Commande[] = new Array()
  AllCommandes: Commande[] = new Array()
  TypeCremationSelect: TypeCremation = new TypeCremation()
  messageErreurInformation: string = ''
  messageSuccesInformation: string = ''
  TxtAjoutEtiquetteManuelle: string = ''

  @ViewChild('audioPlayer')
  audioPlayer!: ElementRef;

  @ViewChild('audioPlayerWrong')
  audioPlayerWrong!: ElementRef;

  @ViewChild('action') action!: NgxScannerQrcodeComponent;

  public constructor(private _router: Router, private _TypeCremationService: TypeCremationService, private _CommandeService: CommandeService, public _GlobalService: GlobalService) {

  }
  ngOnDestroy(): void {
    this.StopScan();
  }

  async ngOnInit(): Promise<void> {
    this.loading = true

    this.TypesCremation = (await this._TypeCremationService.getAllTypes()).objet
    this.TypeCremationSelect = this.TypesCremation[0]

    await this.ChargerCommande()

    this.loading = false
  }

  ngAfterViewInit(): void {
    this.DemarerScan();
  }

  public DemarerScan(): void {
    this.handle(this.action, 'start');
  }

  public StopScan(): void {
    this.handle(this.action, 'stop');
  }


  AjouterEtiquetteManuellement() {
    this.AddCommandeInFournee(Number(this.TxtAjoutEtiquetteManuelle));
  }

  async AddCommandeInFournee(etiquette: number) {
    //Aller chercher la commande associée à cette étiquette
    let reponse = await this._CommandeService.GetCommandeBySacId(etiquette)

    if (reponse.statusCode == 200) {

      let commande: Commande = reponse.objet

      if (commande.detailCremation.typeCremationFinal.id == this.TypeCremationSelect.id && commande.asso_StatutCommande_Commande.length == 3) {

        //Aller chercher la commande dans la liste
        let commandeInListe = this.Commandes.find(x => x.id == commande.id)

        if (commandeInListe != undefined) {
          commandeInListe!.isInFournee = true;

          // Jouer le son vrai
          this.audioPlayer.nativeElement.play();

          //Reset le champ
          this.TxtAjoutEtiquetteManuelle = ''
        } else {
          //Jouer le son erreur
          this.audioPlayerWrong.nativeElement.play();
        }
      } else {
        //Jouer le son erreur
        this.audioPlayerWrong.nativeElement.play();
      }
    } else {
      //Jouer le son erreur
      this.audioPlayerWrong.nativeElement.play();
    }
  }


  public async onEvent(e: ScannerQRCodeResult[], action?: any): Promise<void> {
    if (e && e.length > 0) {
      const newValue = parseInt(e[0].value);

      //Si le sac associé à la commande n'est pas déjà dans la fournée
      if (this.GetCommandeInFournee().filter(x => x.detailCremation.sacId == newValue).length == 0) {
        this.AddCommandeInFournee(newValue)
      }
    }
  }

  GetCommandeInFournee() {
    return this.Commandes.filter(x => x.isInFournee)
  }

  GetCommandeNotInFournee() {
    return this.Commandes.filter(x => !x.isInFournee)
  }

  public handle(action: any, fn: string): void {
    const playDeviceFacingBack = (devices: any[]) => {
      const device = devices.find(f => (/(back)/gi.test(f.label)));
      action.playDevice(device ? device.deviceId : devices[0].deviceId);
    }

    if (fn === 'start') {
      action[fn](playDeviceFacingBack).subscribe(
        (r: any) => console.log(fn, r),
        (error: any) => {
          if (error instanceof DOMException && error.name === 'NotAllowedError') {
            alert('Veuillez autoriser l\'accès à la caméra pour utiliser cette fonctionnalité.');
          } else {
            alert('Une erreur est survenue lors du démarrage du scanner.');
          }
        }
      );
    } else {
      action[fn]().subscribe(
        (r: any) => console.log(fn, r),
        (error: any) => {
          console.error('Error stopping scanner:', error);
          alert('Une erreur est survenue lors de l\'arrêt du scanner.');
        }
      );
    }
  }

  async ChargerCommande() {
    this.Commandes = (await this._CommandeService.GetCommandeByTypeCremation(1)).objet

    this.Commandes = this.Commandes.map((commande) => {
      return {
        ...commande,
        animal: {
          ...commande.animal,
          dateArrivee: new Date(commande.asso_StatutCommande_Commande.filter(x => x.statutCommandeId == 3)[0].date)
        }
      }
    })

    this.Commandes.sort((a, b) => new Date(a.animal.dateArrivee).getTime() - new Date(b.animal.dateArrivee).getTime())

    this.AllCommandes = this.Commandes

  }

  public async CreationFournee() {
    let reponse = await this._CommandeService.CreationFournee(this.GetCommandeInFournee().map(commande => commande.id))

    if (reponse.statusCode == 200) {
      this.messageSuccesInformation = reponse.message
      this._router.navigateByUrl("/Technicien/Accueil")

    } else {
      this.messageErreurInformation = reponse.messageException
    }
  }

  async GetCommandeByTypeCremation(event: TypeCremation): Promise<void> {
    this.loading = true

    if (event.id != this.TypeCremationSelect.id) {
      //Aller chercher les commandes par type de crémation
      let reponse = await this._CommandeService.GetCommandeByTypeCremation(event.id)

      if (reponse.statusCode == 200) {
        this.Commandes = reponse.objet
      } else {
        this.messageErreurInformation = reponse.messageException
      }

      //Vider la liste des commandes dans la fournées
      this.ClearCommandeTournee()
    }

    this.loading = false
  }

  public ClearCommandeTournee() {
    this.AllCommandes = this.Commandes
    this.AllCommandes.forEach(x => x.isInFournee = false)
    this.Commandes = this.AllCommandes
  }
}
