import { Component, ElementRef, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { TabStripComponent } from '@progress/kendo-angular-layout';
import { State, process } from '@progress/kendo-data-query';
import { UtilisateurService } from '../../_services/UtilisateurService';
import { GlobalService } from '../../_services/GlobalService';
import { CommandeService } from '../../_services/CommandeService';
import { Commande } from '../../_models/Commande';
import { CommandeViewComponent } from '../_Views/commande-view/commande-view.component';
import { EnumStatutCommande } from '../../_models/Filter/FilterCommande';
import { ExcelExportData } from "@progress/kendo-angular-excel-export";
import { CommandeArticleViewComponent } from '../_Views/commande-article-view/commande-article-view.component';
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import { HttpClient } from "@angular/common/http";
import { firstValueFrom, lastValueFrom } from 'rxjs';
@Component({
  selector: 'app-commandes',
  templateUrl: './commandes.component.html',
  styleUrls: ['./commandes.component.scss']
})
export class CommandesComponent {
  @ViewChild('dialogActions') dialogActions!: TemplateRef<unknown>;
  public dialog !: DialogRef;

  @ViewChild('dialogActionsCommandeArticle') dialogActionsCommandeArticle!: TemplateRef<unknown>;

  @ViewChild('content', { static: false }) content!: ElementRef;

  @ViewChild("detailCompteContainer", { read: ViewContainerRef })
  public detailCompteContainer!: ViewContainerRef;
  @ViewChild('tabstrip') ts!: TabStripComponent;
  public gridData!: GridDataResult;
  public gridState: State = {
    skip: 0,
    take: 25,
  };
  loading: boolean = false;
  afficherCommandeArchiver: boolean = false;
  Commandes: Commande[] = new Array()
  CommandesArchivees: Commande[] = new Array()
  valeursFiltre: string[] = new Array()

  public constructor(private _CommandeService: CommandeService,
    public _GlobalService: GlobalService,
    private _dialogService: DialogService,
    private _http: HttpClient,
  ) { }

  async ngOnInit(): Promise<void> {
    //charge le tableau des utilisateurs
    this.listeCommandesNonArchivees();
  }

  public async listeCommandesNonArchivees() {
    this.loading = true;

    this.Commandes = (await this._CommandeService.GetAllCommandeRaccourci(EnumStatutCommande.NonArchive)).objet;

    this.ProcessCommande()
  }

  public async listeCommandesArchivees() {
    this.loading = true;

    this.Commandes = (await this._CommandeService.GetAllCommandes(EnumStatutCommande.Archive)).objet;

    this.ProcessCommande()
  }

  ProcessCommande() {
    this.Commandes = this.Commandes.map(commande => ({
      ...commande,
      PrenomNom: `${commande.client.prenom} ${commande.client.nom}`,
      dateSouscription: new Date(commande.dateSouscription),
      lblCommandeProduits: commande.asso_Commande_Produits.map(x => `${x.produit.id}-${x.produit.nom}`).join('\n'),
    }));

    //Faire les commandes de type crémation
    this.Commandes
      .filter(x => x.typeCommandeId == 2)
      .forEach(commande => {
        commande.siteCabinet.CabinetVille = commande.siteCabinet.cabinetVeterinaire.nom + " - " + commande.siteCabinet.ville,
          commande.txtAsso_StatutCommande_Commande = commande.asso_StatutCommande_Commande
            .sort((a, b) => {
              return - (a.statutCommandeId - b.statutCommandeId)
            })[0].statutCommande.nom
      }
      );

    //Faire les commandes de type article
    this.Commandes
      .filter(x => x.typeCommandeId == 1)
      .forEach(commande => {
        commande.txtAsso_StatutCommande_Commande = commande.isPayer ? "Payé" : "En attente de paiement"
      }
      );

    this.Commandes.sort((a, b) => new Date(b.dateSouscription).getTime() - new Date(a.dateSouscription).getTime())

    this.dataStateChange(this.gridState);
    this.loading = false;
  }

  getDefaultTextFilter() {
    return { operator: 'contains', value: this.valeursFiltre };
  }

  public async dataStateChange(state: State) {
    this.gridState = state;
    await this.ChargerTableau();
  }

  ///charge les utilisateurs dans le tableau
  public async ChargerTableau() {
    // Init des données pour la grille
    this.gridData = process(this.Commandes, this.gridState);
  }

  public ouvreDetailCommande(commande: Commande, nouveau: boolean, isCommandeArticle: boolean) {
    this.dialog = this._dialogService.open({
      title: "Fiche convention",
      width: '90%',
      height: '90%',
      //actions: [cancelAction, saveAction],
      actions: isCommandeArticle ? this.dialogActions : this.dialogActionsCommandeArticle,
      appendTo: this.detailCompteContainer,
      closeTitle: "Fermer",
      content: isCommandeArticle ? CommandeViewComponent : CommandeArticleViewComponent,
    });

    let DetailClient = this.dialog.content.instance;
    DetailClient.commande = commande;
    DetailClient.nouveau = nouveau;
  }

  //ajouter un nouveau client
  public AjouterCommande() {
    this.ouvreDetailCommande(new Commande(), true, true);
  }

  //ferme la boite de dialogue
  public closeClient(): void {
    if (this.dialog) {
      this.dialog.close();
    }
  }

  async modifieCompte(dataItem: Commande) {
    this.loading = true;

    //Aller chercher toutes les informations de la commande

    if (dataItem.typeCommandeId == 2) {
      dataItem = await (await this._CommandeService.GetCommandeBySacId(dataItem.detailCremation.sacId)).objet
    } else {
      let IdCommande: number[] = [dataItem.id]
      dataItem = await (await this._CommandeService.GetCommandeByIds(IdCommande)).objet[0]
    }

    this.loading = false;
    dataItem.typeCommandeId == 1 ? this.ouvreDetailCommande(dataItem, false, false) : this.ouvreDetailCommande(dataItem, false, true)
  }

  SuivantCommande() {
    (this.dialog.content.instance as CommandeViewComponent).SuivantCommande();
  }

  public async enregistreClient() {

    let DetailClient: CommandeViewComponent = this.dialog.content.instance;

    if (DetailClient.validate() && await DetailClient.EnregistrerCommande()) {

      this.loading = true;

      await this.listeCommandesNonArchivees();

      this.closeClient();

      this.loading = false;
    }
  }

  AfficherCommandeArchiver(): void {
    this.afficherCommandeArchiver = !this.afficherCommandeArchiver

    if (this.afficherCommandeArchiver) {
      //Aller chercher les commandes archivées
      this.listeCommandesArchivees();
    } else {
      this.listeCommandesNonArchivees();
    }

    //afficher les commandes qui ne sont pas archivées
    this.dataStateChange(this.gridState);

  }

  async ImprimerFicheConvention(dataItem: any) {

    this.loading = true;

    let commande = dataItem as Commande;

    //aller chercher en détail les informations de la commande
    commande = (await this._CommandeService.GetCommandeBySacId(commande.detailCremation.sacId)).objet;

    //Test
    let reponse = await this._CommandeService.ImprimerFicheConvention(commande.id)

    if (reponse.statusCode == 200) {
      //Télécharger le base64 en retour
      this.downloadFile(reponse.objet, commande)
    }

    this.loading = false;
  }

  downloadFile(base64string: string, commande: Commande) {
    const byteCharacters = atob(base64string);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'Convention-' + commande.client.prenom + "-" + commande.client.nom + '.docx';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }

  RefreshConvention(){
    this.listeCommandesNonArchivees()
  }

}
