import { Component, Input, OnInit } from '@angular/core';
import { Commande } from '../../../_models/Commande';
import { StatutCommande } from '../../../_models/StatutCommande';
import { StatutCommandeService } from '../../../_services/StatutCommandeService';
import { RaceAnimal } from '../../../_models/RaceAimal';
import { RaceAnimalService } from '../../../_services/RaceAnimalService';
import { CategorieAnimalService } from '../../../_services/CategorieAnimalService';
import { CategorieAnimal } from '../../../_models/CategorieAnimal';
import { CommandeService } from '../../../_services/CommandeService';
import { DataModelUpdateStatutCommande } from '../../../_models/DataModelController/DataModelUpdateStatutCommande';
import { Location } from '@angular/common';
import { Sac } from '../../../_models/Sac';
import { SacService } from '../../../_services/SacService';
import { DemandeSac } from '../../../_models/DemandeSac';

@Component({
  selector: 'app-fiche-animal-view',
  templateUrl: './fiche-animal-view.component.html',
  styleUrls: ['./fiche-animal-view.component.scss']
})
export class FicheAnimalViewComponent implements OnInit {

  @Input() commande: Commande = new Commande()
  public Sacs: Sac[] = new Array()
  StatutsCommandes: StatutCommande[] = []
  StatutCommande: StatutCommande = new StatutCommande()
  RaceAnimals: RaceAnimal[] = new Array()
  AllRacesAnimal: RaceAnimal[] = new Array()
  CategorieAnimals: CategorieAnimal[] = new Array()
  AllCategorieAnimals: CategorieAnimal[] = new Array()
  messageSuccesInformation = ""
  messageErreurInformation = ""
  sacId: number = 0

  constructor(private _SacService: SacService, private _StatutCommandeService: StatutCommandeService, private _RaceAnimalService: RaceAnimalService,
    private _CategorieAnimalService: CategorieAnimalService, private _CommandeService: CommandeService, private _location: Location) { }

  async ngOnInit(): Promise<void> {
    this.sacId = this.commande.detailCremation.sac.id
    this.StatutsCommandes = (await this._StatutCommandeService.GetAllStatuts()).objet
    this.StatutCommande = this.StatutsCommandes.filter(x => x.id == 1)[0]
    this.RaceAnimals = (await this._RaceAnimalService.getAllRaces()).objet
    this.CategorieAnimals = (await this._CategorieAnimalService.getAllCategories()).objet
    this.Sacs = (await this._SacService.GetAllSacsByEtablissementId(this.commande.siteCabinetId)).objet

    this.AllRacesAnimal = this.RaceAnimals
    this.AllCategorieAnimals = this.CategorieAnimals
  }

  //#region Filter
  handleSelectionCategorieAnimal(value: CategorieAnimal) {
    this.RaceAnimals = this.AllRacesAnimal.filter(
      race =>
        race.categorieAnimalId == value.id
    );
    this.commande.animal.raceAnimal = this.RaceAnimals[0]
  }

  handleFilterRaceAnimal(value: any) {
    this.RaceAnimals = this.AllRacesAnimal.filter(
      race =>
        race.nom
          .toLowerCase()
          .indexOf(value.toLowerCase()) !== -1
    );
  }

  handleFilterCategorieAnimal(value: any) {
    this.CategorieAnimals = this.AllCategorieAnimals.filter(
      categorie =>
        categorie.nom
          .toLowerCase()
          .indexOf(value.toLowerCase()) !== -1
    );
  }
  //#endregion

  public validate(): boolean {

    if (this.commande.animal.raceAnimal && this.commande.animal.nom && this.commande.animal.poids) {
      return true
    } else {
      return false
    }

  }

  async ModifierAnimal(): Promise<boolean> {

    this.commande.client.role = "Client"
    this.commande.detailCremation.sac.demandeSac = new DemandeSac()

    if (this.sacId != this.commande.detailCremation.sac.id) {

      if (confirm("Voulez-vous vraiment changer le numéro d'étiquette de cette commande ?")) {


      } else {
        this.commande.detailCremation.sac.id = this.sacId
        return false
      }
    }

    let reponse = (await this._CommandeService.UpdateFicheAnimal(this.commande))

    if (reponse.statusCode == 200) {
      return true
    } else {
      return false
    }

  }
  isChauffeur() {
    return localStorage.getItem('Role')?.toLowerCase() == 'chauffeur'
  }

  async SetStatutCommande(statut: string) {

    //Aller chercher le statut
    this.StatutCommande = this.StatutsCommandes.filter(x => x.nom == statut)[0]

    //Réinitialiser le message
    this.messageErreurInformation = ""

    //S'assurer que le statut est bien 
    if (this.StatutCommande != undefined) {

      //prendre le dernier statut de la commmande
      let lastStatut = this.commande.asso_StatutCommande_Commande[this.commande.asso_StatutCommande_Commande.length - 1]

      //Si l'id du dernier statut est bien en dessous de l'id du statut à modifier
      if (lastStatut.statutCommandeId == this.StatutCommande.id - 1) {

        //Créer l'objet de la requête
        let data: DataModelUpdateStatutCommande = new DataModelUpdateStatutCommande()
        data.etiquette = this.commande.detailCremation.sacId
        data.statuCommandeId = this.StatutCommande.id

        let resultEtiquetteTypeSac: DataModelUpdateStatutCommande[] = [];
        resultEtiquetteTypeSac.push(data)

        let reponse = await this._StatutCommandeService.UpdateStatutCommande(resultEtiquetteTypeSac)

        if (reponse.statusCode == 200) {
          this.messageSuccesInformation = reponse.message
          //this.resultEtiquetteTypeSac = []
          //this.TxtLstEtiquettes = ""
          this._location.back();
        } else {
          this.messageErreurInformation = reponse.messageException
        }
      } else {
        this.messageErreurInformation = "Veuillez affecter un statut disponible pour cette commande"
      }
    }


  }

  isValidStatutCommande(id: number) {

    //prendre le dernier statut de la commmande
    let lastStatut = this.commande.asso_StatutCommande_Commande[this.commande.asso_StatutCommande_Commande.length - 1]

    //Retourner vrai si un des status commandes comparé à l'id
    return lastStatut.statutCommandeId == id-1
  }

}
