import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService } from '../../_services/GlobalService';
import { UtilisateurService } from '../../_services/UtilisateurService';
import { PanelBarStateChangeEvent, PanelBarItemModel } from '@progress/kendo-angular-layout';

@Component({
  selector: 'app-menu-client',
  templateUrl: './menu-client.component.html',
  styleUrls: ['./menu-client.component.scss']
})
export class MenuClientComponent implements OnInit {

  public menu: any[] = [
    { label: "Accueil", route: "/client/accueil" },
    { label: "Mémorial", route: "/client/Mémorial" },
    { label: "Mon compte", route: "/client/mon-compte" },
  ];

  public selectedMenu = "";
  public router: Router;

  constructor(router: Router,
    public _GlobalService: GlobalService,
    public _UtilisateurService: UtilisateurService) {
    this.router = router
  }

  async ngOnInit() {
    this._GlobalService.client = (await this._UtilisateurService.GetInfosUser()).objet;

    this._GlobalService.client!.PrenomNom = this._GlobalService.client?.prenom + " " + this._GlobalService.client?.nom

    this.selectedMenu = this.router.url;
  }

  //choix menu
  public stateChange(data: PanelBarStateChangeEvent) {
    const focusedEvent: PanelBarItemModel = data.items.filter(
      (item) => item.focused === true
    )[0];

    let route = this.menu.filter(
      (item) => item.label === focusedEvent.title
    )

    this.router.navigate([route[0].route]);
  }

}
