<div [ngClass]="role == 'Technicien' ? 'ContainerHeadertechnicien' : 'ContainerHeaderChauffeur'">
    <div>
        <img (click)="this.Retour()" src="../../../assets/TechnicienChauffeur/FlecheRetour.svg" id="FlecheRetour">
    </div>
    <div class="ContainerTitre">
        <p id="TitreAccueil">{{Titre}}</p>
        <img src="../../../assets/TechnicienChauffeur/ChatChien.svg" id="ChatChien">
    </div>
    <div class="ContainerScan" *ngIf="EnableScanIcon">
        <img alt="scan" (click)="ChoisirQrCode()" src="../../../assets/TechnicienChauffeur/scan.svg" id="Scan">
    </div>
    <br>

</div>