import { Asso_Commande_Produit } from "./Asso_Commande_Produit ";

export class Produit {
    id: number = 0;
    nom: string = "";
    uRL: string = ""; 
    nomCategorie: string = "";
    description: string = "";
    Taille: string = "";
    prix: number = 0;
    Quantite: number = 0;
    imageUrl: number = 0;
    asso_Commande_Produits: Asso_Commande_Produit[] = new Array<Asso_Commande_Produit>();
}