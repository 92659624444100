import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { TabStripComponent } from '@progress/kendo-angular-layout';
import { Commande } from '../../../_models/Commande';
import { CategorieAnimal } from '../../../_models/CategorieAnimal';
import { RaceAnimal } from '../../../_models/RaceAimal';
import { CategorieAnimalService } from '../../../_services/CategorieAnimalService';
import { ClientService } from '../../../_services/ClientService';
import { GlobalService } from '../../../_services/GlobalService';
import { RaceAnimalService } from '../../../_services/RaceAnimalService';
import { TypeCremation } from '../../../_models/TypeCremation';
import { TypeCremationService } from '../../../_services/TypeCremationService';
import { ReponseAPI } from '../../../_models/ReponseAPI';

@Component({
  selector: 'app-commande-client-view',
  templateUrl: './commande-client-view.component.html',
  styleUrls: ['./commande-client-view.component.scss']
})
export class CommandeClientViewComponent implements OnInit {
  

  messageErreurInformation: string = '';
  @ViewChild('tabstrip') tabstrip!: TabStripComponent;
  IndexTabStrip:number= 0
  @Input() public commande!: Commande;
  @Input() public nouveau!: Boolean;
  CategoriesAnimal: CategorieAnimal[] = new Array();
  AllCategoriesAnimal: CategorieAnimal[] = new Array();
  RacesAnimal: RaceAnimal[] = new Array();
  AllRacesAnimal: RaceAnimal[] = new Array();
  public Sexes: string[] = ["Mâle", "Femelle"]
  public loading: boolean = false;
  public verifFormInformation: boolean = false;
  TypesCremation: TypeCremation[] = new Array();

  constructor(public _GlobalService: GlobalService,
    private _ClientService: ClientService,
    private _CategorieAnimalService: CategorieAnimalService,
    private _RaceAnimalService: RaceAnimalService,
    private _TypeCremationService: TypeCremationService) { }

  onSelect($event: any) {
    this.tabstrip.selectTab($event.index);
    this.IndexTabStrip = $event.index
  }

  SuivantCommande() {
    if (this.tabstrip) {
      this.IndexTabStrip += 1;
      this.tabstrip.selectTab(this.IndexTabStrip);
    }
  }

  async ngOnInit(): Promise<void> {
    this.CategoriesAnimal = (await this._CategorieAnimalService.getAllCategories()).objet
    this.RacesAnimal = (await this._RaceAnimalService.getAllRaces()).objet
    this.TypesCremation = (await this._TypeCremationService.getAllTypes()).objet

    this.commande.animal.dateNaissance = new Date(this.commande.animal.dateNaissance)
    this.commande.animal.dateDeces = new Date(this.commande.animal.dateDeces)

  }

  //#region  Selection
  handleSelectionCategorieAnimal(value: CategorieAnimal) {
    this.RacesAnimal = this.AllRacesAnimal.filter(
      race =>
        race.categorieAnimalId == value.id
    );
    this.commande.animal.raceAnimal = this.RacesAnimal[0]
  }

  //#endregion

  //#region Filter
  handleFilterCategorieAnimal(value: any) {
    this.CategoriesAnimal = this.AllCategoriesAnimal.filter(
      categorie =>
        categorie.nom
          .toLowerCase()
          .indexOf(value.toLowerCase()) !== -1
    );
  }
  handleFilterRaceAnimal(value: any) {
    this.RacesAnimal = this.AllRacesAnimal.filter(
      race =>
        race.nom
          .toLowerCase()
          .indexOf(value.toLowerCase()) !== -1
    );
  }
  //#endregion

  async EnregistrerCommande() {
    this.loading = true
    let retour = true;

    this.commande.siteCabinet.utilisateurSiteCabinets = null
    this.commande.siteCabinet.commandes = []
    this.commande.client.commandes = []
    this.commande.siteCabinetId = this.commande.siteCabinet.id

    this.commande.client.role = ""
    this.commande.asso_Commande_Produits = []
    this.commande.asso_StatutCommande_Commande = []


    let reponse = await this._ClientService.UpdateCommandeClient(this.commande);

    if (reponse.statusCode == 200) {
      this.verifFormInformation = true;
      retour = true;
    } else {
      this.verifFormInformation = false;
      this.messageErreurInformation = reponse.messageException;
      retour = false;
    }
    this.loading = false
    return retour
  }

  validate() {
    let retour = false;

    if (this.commande.animal.nom == "" ||
      this.commande.animal.raceAnimal.id == 0 ||
      this.commande.animal.poids == 0 ||
      this.commande.animal.sexe == "") {
      retour = false;
    }
    else {
      retour = true;
    }
    return retour;
  }

}
