import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { GlobalService } from "./GlobalService";
import { ReponseAPI } from "../_models/ReponseAPI";
import { DemandeSac } from "../_models/DemandeSac";
import { DataModelGetSacAffecter } from "../_models/DataModelController/DataModelGetSacAffecter";

@Injectable({
    providedIn: 'root'
})
export class SacService {
    public constructor(private http: HttpClient, private router: Router, private _GlobalService: GlobalService) { }

    public async GetAllSacs(): Promise<ReponseAPI> {
        return await this._GlobalService.SendGETRequest("Sac/Demandes")
    }

    public async GetAllSacsByEtablissementId(idEtablissement:number): Promise<ReponseAPI> {
        return await this._GlobalService.SendGETRequest("Sac/Etablissement/"+ idEtablissement + "/Sacs")
    }
    public async GetAllTaillesSac(): Promise<ReponseAPI> {
        return await this._GlobalService.SendGETRequest("Sac/Tailles")
    }
    public async GetSacAffecter(data:DataModelGetSacAffecter): Promise<ReponseAPI> {
        return await this._GlobalService.SendPOSTRequest("Sac/Affecter",data)
    }

    public async AddSac(sac: DemandeSac): Promise<ReponseAPI> {
        return await this._GlobalService.SendPOSTRequest("Sac/Demande", sac)
    }

    public async UpdateSac(sac: DemandeSac): Promise<ReponseAPI> {
        return await this._GlobalService.SendPUTRequest("Sac/Demande", sac)
    }
}