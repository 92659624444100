<app-header-technicien-chauffeur Titre="Scanner un lot d'étiquettes"
    [EnableScanIcon]="false"></app-header-technicien-chauffeur>
<br>
<div *ngIf="this.messageSuccesInformation != ''" class="alert alert-success">
    {{messageSuccesInformation}}
</div>

<div id="ContainerLogoScan">
    <p id="txtScan">Scan</p>
    <img src="../../../../assets/TechnicienChauffeur/LogoScan.svg" alt="LogoScan">
</div>

<div class="ContainerFournee">

    <div class="ContainerScan">
        <!-- ngx-scanner-qrcode -->
        <ngx-scanner-qrcode class="Scanner" #action="scanner" [config]="this._GlobalService.config"
            (event)="onEvent($event, action)"></ngx-scanner-qrcode>
        <!-- loading -->
        <p *ngIf="action.isLoading">⌛ Chargement...</p>

    </div>

    <div class="Etiquettes" *ngIf="this.isChauffeur()">
        <br>
        <div id="AjoutEtiquetteManuellement">
            <h3>Ajouter une étiquette manuellement</h3>
            <kendo-numerictextbox #ajoutEtiquetteTextBox [(ngModel)]="TxtAjoutEtiquetteManuelle" [min]="1" [step]="1"
                [decimals]="0" format="n0">
            </kendo-numerictextbox>

            <kendo-button id="btnAjoutEtiquetteManuellent" (click)="AjouterEtiquetteManuellement()">
                Ajouter
            </kendo-button>
        </div>

        <br>
        <h2>Liste des étiquettes</h2>
        <br>

        <kendo-grid [data]="resultEtiquetteTypeSac">
            <kendo-grid-column field="etiquette" title="Numéro"></kendo-grid-column>
            <kendo-grid-column *ngIf="this.isChauffeur()" field="nom" title="Taille du sac">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <kendo-dropdownlist [(ngModel)]="dataItem.typeSac" [data]="this.TypesSacCremation"
                        [textField]="'nom'" [valueField]="'id'"></kendo-dropdownlist>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column id="ColonneRetirer" title="Action">
                <ng-template let-dataItem kendoGridCellTemplate>
                    <kendo-svg-icon (click)="SupprimerLstEtiquettes(dataItem)" size="xxlarge"
                        [icon]="icon"></kendo-svg-icon>
                </ng-template>
            </kendo-grid-column>
        </kendo-grid>
    </div>
    <br>
    <div class="ContainerValiderScan" *ngIf="this.isChauffeur()">
        <kendo-button (click)="this.ValiderScan()" id="EnregistrerScan">Enregistrer</kendo-button>
        <br><br>
    </div>
</div>
<audio #audioPlayer style="display: none;">
    <source src="../../../../assets/Sound/ScanQrCode.mp3" type="audio/mpeg">
</audio>
<audio #audioPlayerErreur style="display: none;">
    <source src="../../../../assets/Sound/ErrorScanQrCode.mp3" type="audio/mpeg">
</audio>


<kendo-dialog (close)="messageErreurInformation = ''" title="Erreur" *ngIf="messageErreurInformation !=''">
    <p style="text-align: center; color: black;">
        {{messageErreurInformation}}
    </p>
</kendo-dialog>