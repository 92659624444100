<h2>Informations sur le site de la société {{cabinet.nom}}</h2>
<br><br>
<form #infoForm="ngForm" name="Form">
    <div *ngIf="this.messageErreurInformation != ''" class="alert alert-danger">
        {{messageErreurInformation}}
    </div>
    <div class="row">
        <div class="col-md-4">
            <kendo-label class="k-form" text="Adresse *">
                <kendo-textbox placeholder="Adresse" name="Adresse"
                    [(ngModel)]="this.cabinet.siteCabinets[this.cabinet.siteCabinets.length-1].adresse"
                    [clearButton]="true" required>
                    <ng-template kendoTextBoxSuffixTemplate>
                          <kendo-icon name="pencil"></kendo-icon>
                    </ng-template>
                </kendo-textbox>
            </kendo-label>
        </div>
        <div class="col-md-4">
            <kendo-label class="k-form" text="Code postal *">
                <kendo-textbox placeholder="Code postal" name="codePostal"
                    [(ngModel)]="this.cabinet.siteCabinets[this.cabinet.siteCabinets.length-1].codePostal"
                    [clearButton]="true" required>
                    <ng-template kendoTextBoxSuffixTemplate>
                          <kendo-icon name="pencil"></kendo-icon>
                    </ng-template>
                </kendo-textbox>
            </kendo-label>
        </div>
        <div class="col-md-4">
            <kendo-label class="k-form" text="Ville *">
                <kendo-textbox placeholder="Ville" name="Ville"
                    [(ngModel)]="this.cabinet.siteCabinets[this.cabinet.siteCabinets.length-1].ville"
                    [clearButton]="true" required>
                    <ng-template kendoTextBoxSuffixTemplate>
                          <kendo-icon name="pencil"></kendo-icon>
                    </ng-template>
                </kendo-textbox>
            </kendo-label>
        </div>

    </div>
    <br>
    <div class="row">
        <div class="col-md-4">
            <kendo-label class="k-form" text="Téléphone *">
                <kendo-textbox placeholder="Téléphone" name="telephone"
                    [(ngModel)]="this.cabinet.siteCabinets[this.cabinet.siteCabinets.length-1].telephone"
                    [clearButton]="true" required>
                    <ng-template kendoTextBoxSuffixTemplate>
                          <kendo-icon name="pencil"></kendo-icon>
                    </ng-template>
                </kendo-textbox>
            </kendo-label>
        </div>
        <div class="col-md-4">
            <kendo-label class="k-form" text="Adresse email *">
                <kendo-textbox placeholder="Adresse email" name="email"
                    [(ngModel)]="this.cabinet.siteCabinets[this.cabinet.siteCabinets.length-1].email"
                    [clearButton]="true" required>
                    <ng-template kendoTextBoxSuffixTemplate>
                          <kendo-icon name="pencil"></kendo-icon>
                    </ng-template>
                </kendo-textbox>
            </kendo-label>
        </div>
        <div class="col-md-4">
            <kendo-label class="k-form" text="N° Siret *">
                <kendo-textbox placeholder="N° Siret" name="siret"
                    [(ngModel)]="this.cabinet.siteCabinets[this.cabinet.siteCabinets.length-1].siret"
                    [clearButton]="true" required>
                    <ng-template kendoTextBoxSuffixTemplate>
                          <kendo-icon name="pencil"></kendo-icon>
                    </ng-template>
                </kendo-textbox>
            </kendo-label>
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col-md-3">
            <kendo-label class="k-form" text="Horraire d'ouverture  le matin *">
                <kendo-timepicker name="HorraireDebutMatin"
                    [(ngModel)]="this.cabinet.siteCabinets[this.cabinet.siteCabinets.length-1].horraireDebutMatin"></kendo-timepicker>
            </kendo-label>
        </div>
        <div class="col-md-3">
            <kendo-label class="k-form" text="Horraire de fermeture le matin *">
                <kendo-timepicker name="HorraireFinMatin"
                    [(ngModel)]="this.cabinet.siteCabinets[this.cabinet.siteCabinets.length-1].horraireFinMatin"></kendo-timepicker>
            </kendo-label>
        </div>
        <div class="col-md-3">
            <kendo-label class="k-form" text="Horraire d'ouverture l'après-midi *">
                <kendo-timepicker name="HorraireDebutApresMidi"
                    [(ngModel)]="this.cabinet.siteCabinets[this.cabinet.siteCabinets.length-1].horraireDebutApresMidi"></kendo-timepicker>
            </kendo-label>
        </div>
        <div class="col-md-3">
            <kendo-label class="k-form" text="Horraire de fermeture l'après-midi *">
                <kendo-timepicker name="horraireFinApresMidi"
                    [(ngModel)]="this.cabinet.siteCabinets[this.cabinet.siteCabinets.length-1].horraireFinApresMidi"></kendo-timepicker>
            </kendo-label>
        </div>

    </div>
    <br>
    <div class="row">
        <div class="col-md-12">
            <kendo-label class="k-form" text="Commentaire interne :">
                <kendo-textarea [(ngModel)]="this.cabinet.siteCabinets[this.cabinet.siteCabinets.length-1].commentaire"
                    [rows]="5" name="commentaire">
                </kendo-textarea>
            </kendo-label>
        </div>
    </div>
</form>