import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { TokenService } from "../_services/token.service";

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(private tokenService: TokenService,
        private router: Router,
    ) { }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        var isAuthorized = false;
        const allowedRoles = route.data["allowedRoles"];
        var decodeToken = this.tokenService.getDecodedTokenStorage();
        //Contrôler que le token existe
        if (decodeToken) {
            //Si la date d'expiration du token n'est pas dépassé, on accorde l'accès
            if (this.tokenService.isValidTokenStorage()) {
                let role: string = decodeToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];

                if (role.toLowerCase() != "administrateur") {
                    isAuthorized = true;
                }
            }
        }

        if (!isAuthorized) {
            this.router.navigate(['Connexion']);
        }
        return isAuthorized;
    }
}


//TODO faire le contrôle pour accès au tbd, penser à ajouter le guard dans app.routing