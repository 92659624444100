<app-header-client-boutique></app-header-client-boutique>
<div *ngIf="this.messageErreurInformation != ''" class="alert alert-danger">
    {{messageErreurInformation}}
</div>

<div id="ContainerDetailProduit">
    <h3><span style="cursor: pointer;" (click)="GoToAccueil()">Accueil</span> | <span style="cursor: pointer;"
            (click)="GoToCategorie(produit.nomCategorie)">{{produit.nomCategorie}}</span> | {{produit.nom}}</h3>
    <p id="TxtProduit">PRODUIT</p>
    <p id="TxtNomProduit">{{produit.nom}}</p>
    <div id="ContainerDrpDown" *ngIf="!this.produit.nom.includes('Crémation') && !this.produit.nom.includes('Colombarium')">
        <label [for]="taille" class="TxtDropdown">Taille</label>
        <kendo-dropdownlist #taille rounded="full" id="DrpTaille" [data]="TaillesProduit"
            [(ngModel)]="TaillesProduitSelect"></kendo-dropdownlist>
    </div>
    <div id="ContainerDrpDown">
        <label [for]="quantite" text="Quantité">Quantité</label>
        <kendo-dropdownlist #quantite rounded="full" id="DrpQuantite" [data]="Quantite"
            [(ngModel)]="QuantiteSelect"></kendo-dropdownlist>
    </div>
    <div id="ContainerPrixPanier">
        <span id="TxtPrix">{{produit.prix}} €</span>

        <button class="addtocart" (click)="AjouterPanier()">
            <div class="pretext">
                <i class="fas fa-cart-plus"></i> AJOUTER AU PANIER
            </div>

            <div class="pretext done">
                <div class="posttext"><i class="fas fa-check"></i> AJOUTÉ</div>
            </div>
        </button>
    </div>
</div>

<div id="ContainerImageProduit">
    <img id="ImageProduit" [src]="produit.imageUrl" alt="Image du produit">
</div>

<div id="ContainerDescriptionProduit">
    <p id="TxtDescription">Description du produit</p>
    <p id="TxtDescriptionProduit" [innerHTML]="produit.description"></p>
</div>
<br>
<p id="TxtCompleterCommande">Complétez votre commande</p>

<div id="ContainerProduits">
    <app-fiche-produit-template [Produits]="produits"></app-fiche-produit-template>
</div>
<br>
<button id="BtnRetourAccueil" (click)="GoToAccueil()">RETOURNER À L'ACCUEIL &#10142;</button>