import { Component, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { CabinetVeterinaire } from '../../_models/CabinetVeterinaire';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { State, process } from '@progress/kendo-data-query';
import { GlobalService } from '../../_services/GlobalService';
import { UtilisateurService } from '../../_services/UtilisateurService';
import { SocieteService } from '../../_services/SocieteService';
import { SocieteViewComponent } from '../_Views/societe-view/societe-view.component';

@Component({
  selector: 'app-societe-admin',
  templateUrl: './societe-admin.component.html',
  styleUrls: ['./societe-admin.component.scss']
})
export class SocieteAdminComponent implements OnInit {
  @ViewChild('dialogActions') dialogActions!: TemplateRef<unknown>;
  public dialog !: DialogRef;

  @ViewChild("detailCompteContainer", { read: ViewContainerRef })
  public detailCompteContainer!: ViewContainerRef;
  loading: boolean = false;
  public gridData!: GridDataResult;
  public gridState: State = {
    skip: 0,
    take: 25,
  };
  Cabinets: CabinetVeterinaire[] = []
  public constructor(private _CabinetService: SocieteService,
    public _GlobalService: GlobalService,
    private _dialogService: DialogService) { }
  ngOnInit(): void {
    //charge le tableau des cabinets
    this.listeCabinets();
  }

  public async listeCabinets() {
    this.loading = true;

    let reponse = await this._CabinetService.getAllCabinets();

    this.Cabinets = reponse.objet;
    this.dataStateChange(this.gridState);
    this.loading = false;
  }

  public async dataStateChange(state: State) {
    this.gridState = state;
    await this.ChargerTableau();
  }
  ///charge les utilisateurs dans le tableau
  public async ChargerTableau() {
    // Init des données pour la grille
    this.gridData = process(this.Cabinets, this.gridState);
  }

  public ouvreDetailCabinet(Cabinet: CabinetVeterinaire, nouveau: boolean) {
    this.dialog = this._dialogService.open({
      title: "Fiche du cabinet",
      width: '90%',
      height: '90%',
      //actions: [cancelAction, saveAction],
      actions: this.dialogActions,

      appendTo: this.detailCompteContainer,
      closeTitle: "Fermer",
      content: SocieteViewComponent,
    });

    let DetailCabinet = this.dialog.content.instance;
    DetailCabinet.cabinet = Cabinet;
    DetailCabinet.nouveau = nouveau;
  }
  public AjouterCabinet() {
    this.ouvreDetailCabinet(new CabinetVeterinaire(), true);
  }

  public closeCabinet(): void {
    if (this.dialog) {
      this.dialog.close();
    }
  }

  modifierCabinet({ dataItem }: any) {
    this.ouvreDetailCabinet(dataItem as CabinetVeterinaire, false);
  }

  public async enregistrerCabinet() {

    let DetailCabinet = this.dialog.content.instance;

    if (DetailCabinet.validate() && await DetailCabinet.EnregistrerSiteCabinet()) {

      this.loading = true;

      await this.listeCabinets();

      this.closeCabinet();

      this.loading = false;
    }

  }

  SuivantCabinet() {
    (this.dialog.content.instance as SocieteViewComponent).SuivantSociete();
  }

}
