import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ClientService } from '../../../_services/ClientService';
import { CommandeService } from '../../../_services/CommandeService';
import { Client } from '../../../_models/Client';
import { Commande } from '../../../_models/Commande';
import { FileRestrictions, SelectEvent } from '@progress/kendo-angular-upload';
import { MemorialService } from '../../../_services/MemorialService';
import { isNullOrEmptyString } from '@progress/kendo-angular-grid/utils';
import { CategorieAnimal } from '../../../_models/CategorieAnimal';
import { RaceAnimalService } from '../../../_services/RaceAnimalService';
import { RaceAnimal } from '../../../_models/RaceAimal';
import { CategorieAnimalService } from '../../../_services/CategorieAnimalService';

@Component({
  selector: 'app-fiche-animal-proprietaire',
  templateUrl: './fiche-animal-proprietaire.component.html',
  styleUrls: ['./fiche-animal-proprietaire.component.scss']
})
export class FicheAnimalProprietaireComponent implements OnInit {

  IdCommande: number
  Client: Client = new Client();
  Commande: Commande = new Commande();
  loading: boolean = false;

  public Photo1: File = new File([""], "filename");
  public Photo2: File = new File([""], "filename");
  public Photo3: File = new File([""], "filename");

  public RacesAnimal: RaceAnimal[] = []
  public AllRacesAnimal: RaceAnimal[] = []

  public CategoriesAnimal: RaceAnimal[] = []
  public AllCategoriesAnimal: RaceAnimal[] = []

  public Restrictions: FileRestrictions = {
    allowedExtensions: ["jpg", "jpeg", "png"],
  };

  public Sexes: string[] = ["Mâle", "Femelle"]

  messageSucces = ""
  messageErreurInformation = ""

  constructor(private _CategorieAnimalService: CategorieAnimalService, private _RaceAnimalService: RaceAnimalService, private actroute: ActivatedRoute, private _ClientService: ClientService, private _CommandeService: CommandeService, private _MemorialService: MemorialService) {
    this.IdCommande = this.actroute.snapshot.params['Id'];
  }

  async ngOnInit(): Promise<void> {

    this.loading = true

    //Aller chercher les informations du Client
    this.Client = (await this._ClientService.GetInformations()).objet

    //Aller chercher les informations de la commande
    this.Commande = (await this._CommandeService.GetCommandeByIds([this.IdCommande])).objet[0]

    //Uniformiser les dates
    this.Commande.animal.dateNaissance = new Date(this.Commande.animal.dateNaissance)

    //Aller chercher les  races animal
    this.AllRacesAnimal = (await this._RaceAnimalService.getAllRaces()).objet

    //Aller  chercher  les  catégories
    this.AllCategoriesAnimal = (await this._CategorieAnimalService.getAllCategories()).objet

    this.RacesAnimal = this.AllRacesAnimal
    this.CategoriesAnimal = this.AllCategoriesAnimal

    //S'assurer que le client qui modifie la fiche soit bien celui qui a passé la commande
    if (this.Commande.client.id != this.Client.id) {
      window.location.href = "/client/memorial"
    } else {

      if (this.Commande.animal.photo1 !== "") {
        const base64String = this.Commande.animal.photo1;
        const filename = 'nom_fichier.png';

        // Convertir la chaîne Base64 en objet File
        this.Photo1 = this.convertBase64ToFile(base64String, filename);

      }

      this.Commande.animal.dateNaissance = new Date(this.Commande.animal.dateNaissance)
      this.Commande.animal.dateDeces = new Date(this.Commande.animal.dateDeces)
    }

    this.loading = false
  }

  convertBase64ToFile(base64String: string, filename: string): File {
    this.loading = true
    // Convertir la chaîne Base64 en Blob
    const byteCharacters = atob(base64String.split(',')[1]);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'image/png' });

    this.loading = false

    // Convertir Blob en objet File
    return new File([blob], filename, { type: 'image/png' });

  }

  public select(e: any): void {
    const that = this;

    //Prendre le fichier
    let file = e.target.files[0];

    if (!file.validationErrors) {
      const reader = new FileReader();

      reader.onload = function (ev) {
        that.Commande.animal.photo1 = ev.target?.result as string;
      };

      reader.readAsDataURL(file!);
    }
  };


  SupprimerFichier() {
    this.Commande.animal.photo1 = ""
  }

  async EnregistrerFicheAnimal() {
    this.loading = true

    this.messageErreurInformation = ""
    this.messageSucces = ""

    //Vérfier les informations
    if (this.Commande.animal.nom == ""
      || this.Commande.animal.dateNaissance == new Date()
      || this.Commande.animal.raceAnimal.nom == ""
      || this.Commande.animal.raceAnimal.categorieAnimal.nom == ""
    ) {
      this.messageErreurInformation = "Veuillez remplir tous les champs"
    } else {

      this.Commande.animal.isOkMemorial = this.Commande.isOkMemorial

      let reponse = await this._MemorialService.UpdateFicheAnimalProprietaire(this.Commande.animal)

      if (reponse.statusCode == 200) {
        this.messageSucces = "La fiche de l'animal a été mise à jour"
      } else {
        this.messageErreurInformation = reponse.messageException
      }
    }

    this.loading = false
  }

  AfficherChoixImage() {
    const fileInput = document.getElementById('fileInput');
    if (fileInput) {
      fileInput.click();
    }
  }

  //#region  Selection
  handleSelectionCategorieAnimal(value: CategorieAnimal) {
    this.RacesAnimal = this.AllRacesAnimal.filter(
      race =>
        race.categorieAnimalId == value.id
    );
    this.Commande.animal.raceAnimal = this.RacesAnimal[0]
  }

  //#endregion

  //#region Filter

  handleFilterCategorieAnimal(value: any) {
    this.CategoriesAnimal = this.AllCategoriesAnimal.filter(
      categorie =>
        categorie.nom
          .toLowerCase()
          .indexOf(value.toLowerCase()) !== -1
    );
  }
  handleFilterRaceAnimal(value: any) {
    this.RacesAnimal = this.AllRacesAnimal.filter(
      race =>
        race.nom
          .toLowerCase()
          .indexOf(value.toLowerCase()) !== -1
    );
  }

  //#endregion

}