<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <form #infoForm="ngForm" name="Form">
                <div *ngIf="this.messageErreurInformation != ''" class="alert alert-danger">
                    {{messageErreurInformation}}
                </div>
                <kendo-tabstrip (tabSelect)="onSelect($event)" #tabstrip>
                    <kendo-tabstrip-tab title="Client" [selected]="true" #tabInformation>
                        <ng-template kendoTabContent>
                            <div>
                                <div *ngIf="!this.nouveau" class="ContainerArchiveCommande">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <kendo-label class="k-checkbox-label" [for]="archiver"
                                                text="Commande archivée"></kendo-label>
                                            <input type="checkbox" name="archiver" [(ngModel)]="this.commande.archiver"
                                                #archiver kendoCheckBox />
                                        </div>
                                    </div>
                                </div>
                                <br>
                                <h2>Informations du client</h2>
                                <br>
                                <div class="row">
                                    <div class="col-md-6">
                                        <kendo-label class="k-form" text="Nom *">
                                            <kendo-textbox placeholder="Nom" name="nom"
                                                [(ngModel)]="this.commande.client.nom" [clearButton]="true" required>
                                                <ng-template kendoTextBoxSuffixTemplate>
                                                      <kendo-icon name="pencil"></kendo-icon>
                                                </ng-template>
                                            </kendo-textbox>
                                        </kendo-label>
                                    </div>
                                    <div class="col-md-6">
                                        <kendo-label class="k-form" text="Prénom *">
                                            <kendo-textbox placeholder="Prénom" name="prenom"
                                                [(ngModel)]="this.commande.client.prenom" [clearButton]="true" required>
                                                <ng-template kendoTextBoxSuffixTemplate>
                                                      <kendo-icon name="pencil"></kendo-icon>
                                                </ng-template>
                                            </kendo-textbox>
                                        </kendo-label>
                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-md-6">
                                        <kendo-label class="k-form" text="Email *">
                                            <kendo-textbox placeholder="Email" name="email"
                                                [(ngModel)]="this.commande.client.email" [clearButton]="true" required>
                                                <ng-template kendoTextBoxSuffixTemplate>
                                                      <kendo-icon name="pencil"></kendo-icon>
                                                </ng-template>
                                            </kendo-textbox>
                                        </kendo-label>
                                    </div>
                                    <div class="col-md-6">
                                        <kendo-label class="k-form" text="Téléphone *">
                                            <kendo-textbox placeholder="Téléphone" name="telephone"
                                                [(ngModel)]="this.commande.client.telephone" [clearButton]="true"
                                                required>
                                                <ng-template kendoTextBoxSuffixTemplate>
                                                      <kendo-icon name="pencil"></kendo-icon>
                                                </ng-template>
                                            </kendo-textbox>
                                        </kendo-label>
                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-md-4">
                                        <kendo-label class="k-form" text="Adresse *">
                                            <kendo-textbox placeholder="Adresse" name="Adresse"
                                                [(ngModel)]="this.commande.client.adresse" [clearButton]="true"
                                                required>
                                                <ng-template kendoTextBoxSuffixTemplate>
                                                      <kendo-icon name="pencil"></kendo-icon>
                                                </ng-template>
                                            </kendo-textbox>
                                        </kendo-label>
                                    </div>
                                    <div class="col-md-4">
                                        <kendo-label class="k-form" text="Code postal *">
                                            <kendo-textbox placeholder="Code postal" name="codePostal"
                                                [(ngModel)]="this.commande.client.codePostal" [clearButton]="true"
                                                required>
                                                <ng-template kendoTextBoxSuffixTemplate>
                                                      <kendo-icon name="pencil"></kendo-icon>
                                                </ng-template>
                                            </kendo-textbox>
                                        </kendo-label>
                                    </div>
                                    <div class="col-md-4">
                                        <kendo-label class="k-form" text="Ville *">
                                            <kendo-textbox placeholder="Ville" name="Ville"
                                                [(ngModel)]="this.commande.client.ville" [clearButton]="true" required>
                                                <ng-template kendoTextBoxSuffixTemplate>
                                                      <kendo-icon name="pencil"></kendo-icon>
                                                </ng-template>
                                            </kendo-textbox>
                                        </kendo-label>
                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-md-12">
                                        <kendo-label class="k-form" text="Commentaire interne :">
                                            <kendo-textarea placeholder="Commentaire"
                                                [(ngModel)]="this.commande.client.commentaire" [rows]="5"
                                                name="commentaire">
                                            </kendo-textarea>
                                        </kendo-label>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </kendo-tabstrip-tab>
                </kendo-tabstrip>
            </form>
        </div>
    </div>
</div>
<app-loader *ngIf="loading"></app-loader>