import { DemandeSac } from "./DemandeSac";
import { TypeSacCremation } from "./TypeSacCremation";

export class Sac {
    id: number = 0;
    typeSacCremationId: number = 0;
    typeSacCremation: TypeSacCremation = new TypeSacCremation();
    demandeSacId: number = 0;
    demandeSac: DemandeSac = new DemandeSac();
    NbEtiquettesAffectes: number = 0
}