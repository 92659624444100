import { Component, Input, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { SacService } from '../../../_services/SacService';
import { NgForm } from '@angular/forms';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { Sac } from '../../../_models/Sac';
import { GlobalService } from '../../../_services/GlobalService';
import { SiteCabinetVeterinaire } from '../../../_models/SiteCabinetVeterinaire';
import { SocieteService } from '../../../_services/SocieteService';
import { DemandeSac } from '../../../_models/DemandeSac';
import { DataModelGetSites } from '../../../_models/DataModelController/DataModelGetSites';

@Component({
  selector: 'app-sac-view',
  templateUrl: './sac-view.component.html',
  styleUrls: ['./sac-view.component.scss']
})
export class SacViewComponent  {
  
}
